import { infoPageText } from "../../datas/data";
import useScreenSize from "../../hooks/useScreenSize";
import Content from "../common/Content";
import { theme } from "../../utils/palette";
import HistoryTable from "./HistoryTable";
import { useLanguage } from "../../context/LanguageContext";


const History = () => {

    const { isSmallScreen } = useScreenSize();
    
    const { language } = useLanguage();
    const currentText = infoPageText[language];
    const title = isSmallScreen ? currentText.history.title.m : currentText.history.title.pc;
    const subCopy = isSmallScreen? currentText.history.subCopy.m : currentText.history.subCopy.pc;

    const color = theme.colors.gray4;


    return (
        <section className={`bg-white ${isSmallScreen ? 'py-80' : 'pt-200'}`}>
            <Content 
                title={title}
                subCopy={subCopy}
                color={color}
                isSmallScreen={isSmallScreen}
            />
            <HistoryTable isSmallScreen={isSmallScreen} />
        </section>
    );

}

export default History;