import React from "react";
import styled from "styled-components";
import usePreventScroll from "../../hooks/usePreventScroll";
import { visibilityStyles } from "../../styled/common";
import { useLanguage } from "../../context/LanguageContext";


const Modal = ({ onClose, isSmallScreen, isModalOpen, message }) => {

    const { language } = useLanguage();

    usePreventScroll(isModalOpen);

    const text = 
        message === "submit" ? 
            language==='kor' ? '정상적으로 전송되었습니다.' : 'The transfer has been completed.' :
        message === "not allowed" ? 
            language==='kor' ? `파일 형식이 맞지 않습니다.\n명시된 파일 형식들만 업로드 해주세요.` : 'The file type is not correct.\nPlease upload the specified file formats.' : '';

    const message2 = message === 'not allowed' ? 
        `*.ppt *.pptx *.xls *.xlsx *.pdf *.doc *.docx 
        *.hwp *.jpeg *.jpg *.png *.webp *.gif *.zip` : '';


    return (
        <Overlay onClick={onClose} isVisible={isModalOpen}>
            <ModalContainer 
                onClick={(e) => e.stopPropagation()} 
                isSmallScreen={isSmallScreen}
                >
                <Message isSmallScreen={isSmallScreen}>
                    { text }
                </Message>
                <p className={`text-14 text-gray8 mb-20 whitespace-pre-line`}>
                    { message2 }
                </p>
                <Button 
                    onClick={onClose} 
                    isSmallScreen={isSmallScreen}
                    >
                        { language==='kor' ? '확인' : 'Confirm' }
                </Button>
            </ModalContainer>
        </Overlay>
    );
};

export default Modal;


const Overlay = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 100;
    background-color: rgba(0, 0, 0, 0.3);
    display: flex;
    justify-content: center;
    align-items: center;

    ${visibilityStyles};
    visibility: ${({ isVisible }) => (isVisible ? 'visible' : 'hidden')};
`;

const ModalContainer = styled.div`
    background-color: white;
    padding: ${({ isSmallScreen }) => isSmallScreen ? '15px 20px 10px 20px' : '20px 36px 15px 36px'};
    border-radius: 8px;
    text-align: center;
`;

const Message = styled.p`
    font-size: 18px;
    margin-bottom: 8px;
    white-space: pre-line;
`;

const Button = styled.button`
    background-color: ${({ theme }) => theme.colors.main1};
    color: white;
    padding: 4px 16px;
    border: none;
    border-radius: 4px;

    &:hover {
        opacity: 0.8;
    }
`;
