import styled from 'styled-components';
import bgImage from '../../img/solution_main_bg.png';
import saltiWhite from '../../img/SALTi_white.svg';
import { Section } from '../../styled/common.js';
import useScreenSize from '../../hooks/useScreenSize';
import { chipDataSolution } from '../../datas/data.js';
import { theme } from '../../utils/palette.js';
import Chips from '../common/Chips.js';
import { scrollToSection } from '../../utils/functions.js';
import ScrollYButton from '../common/ScrollYButton.js';
import { useLanguage } from '../../context/LanguageContext.js';
import { useEffect } from 'react';


const SolutionMainBg = ({ height, nextSectionRef, headerHeight }) => {

    const { isSmallScreen, isXLargeScreen } = useScreenSize();
    const { language } = useLanguage();
    const mainText = language==='kor' ? 
                    `고객을 이해하는 가장 쉬운 방법\n스몰데이터 솔루션` : 
                    `The Easiest Way to Understand Customers:\nSmall Data Solution`;
    const chipData = chipDataSolution[language];

    const handleScroll = () => { scrollToSection(nextSectionRef, headerHeight); };

    const bgWidth = '100%';
    const bgHeight = isSmallScreen ? height : isXLargeScreen ? '100%' : '600px';

    const chipsWidth = isSmallScreen ? '335px' : '750px';
    const chipsFontSize = isSmallScreen ? '8px' : '18px';
    const chipPadding = isSmallScreen ? '4px 6px' : '';
    const rowGap = isSmallScreen ? '8px' : '16px';
    const columnGap = isSmallScreen ? '6.4px' : '16px';
    const chipsBgColor = theme.colors.white;
    const chipsColor = theme.colors.black;
    const top = '0';
    
    
    return (
        <Section>
            <BgCrop height={bgHeight}>
                <img src={bgImage} className="object-cover"/>
                <Background 
                    width={bgWidth}
                    height={bgHeight}
                />
                <ContentBox>
                    <Logo isSmallScreen={isSmallScreen}>
                        <img src={saltiWhite} className="block w-full h-full"/>
                    </Logo>
                    <H1 isSmallScreen={isSmallScreen}>
                        { mainText }
                    </H1>
                    <Chips
                        chipsData={chipData}
                        width={chipsWidth}
                        bgColor={chipsBgColor}
                        color={chipsColor}
                        fontSize={chipsFontSize}
                        top={top}
                        isSmallScreen={isSmallScreen}
                        rowGap={rowGap}
                        columnGap={columnGap}
                        padding={chipPadding}
                    />
                </ContentBox>
                { isSmallScreen && <ScrollYButton onClick={handleScroll} /> }
            </BgCrop>
        </Section>
    );

}

export default SolutionMainBg;


const H1 = styled.h1`
    font-size: ${({ isSmallScreen }) => isSmallScreen ? '24px' : '40px'};
    font-weight: 700;
    margin-bottom: ${({ isSmallScreen }) => isSmallScreen ? '16px' : '32px'};
    white-space: pre-line;
`;

const BgCrop = styled.div`
    width: 100%;
    height: ${({ height }) => height};

    display: flex;
    justify-content: center;
    
    position: relative;
    overflow: hidden;
`;

const Background = styled.section`
    width: ${({ width }) => width};
    height: ${({ height }) => height};
    opacity: 0.7;
    background: linear-gradient(107deg, rgba(27,47,163,1), rgba(0,129,218,1), rgba(0,180,168,1));
    background-size: cover;
    box-sizing: border-box;

    position: absolute;
    top: 0;
    left: 0;
`;

const ContentBox = styled.div`
    color: ${({ theme }) => theme.colors.white};
    text-align: center;
    white-space: nowrap;

    align-self: center;
    
    position: absolute;
`;

const Logo = styled.div`
    width: ${({ isSmallScreen }) => isSmallScreen ? '80px' : '120px'};
    height: ${({ isSmallScreen }) => isSmallScreen ? '24px' : '36px'};
    margin: ${({ isSmallScreen }) => isSmallScreen ? '0 auto 24px auto' : '0 auto 16px auto'};
`;