import styled from "styled-components";
import useScreenSize from "../../hooks/useScreenSize";
import Card from "./Card";
import { pressReleaseData } from "../../datas/pressReleaseData";
import { useRef, useState } from "react";
import useScrollVisible from "../../hooks/useScrollVisible";
import { OpacityAnimation, refloat, RefloatAnimation } from "../../styled/common";
import { useLanguage } from "../../context/LanguageContext";


const PressRelease = () => {

    const { isSmallScreen } = useScreenSize();
    const { language } = useLanguage();
    const pressRelease = language==='kor' ? '보도자료' : 'Press Release';
    const [ pressReleaseDatas ] = useState(pressReleaseData);


    return (
        <Section isSmallScreen={isSmallScreen}>
            <H1 isSmallScreen={isSmallScreen}>{ pressRelease }</H1>
            <CardList isSmallScreen={isSmallScreen}>
            {
                pressReleaseDatas.map((pressRelease, index) => 
                    <Card 
                        pressRelease={pressRelease}
                        isSmallScreen={isSmallScreen} 
                        key={index}
                    />
                )
            }
            </CardList>
        </Section>
    );

}

export default PressRelease;


const Section = styled.section`
    background-color: ${({ theme }) => theme.colors.main3};
    padding: ${({ isSmallScreen }) => isSmallScreen ? '40px 0' : '80px 0'};
`;

const H1 = styled.h1`
    margin-bottom: ${({ isSmallScreen }) => isSmallScreen ? '16px' : '36px'};
    font-size: ${({ isSmallScreen }) => isSmallScreen ? '20px' : '28px'};
    font-weight: 700;
    color: ${({ theme }) => theme.colors.gray4};
    text-align: center;
    line-height: 140%;
`;

const CardList = styled.div`
    padding: ${({ isSmallScreen }) => isSmallScreen ? '0 25px' : '0 50px'};
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
    gap: ${({ isSmallScreen }) => isSmallScreen ? '10px' : '20px'};
    white-space: nowrap;

    &::-webkit-scrollbar {
        display: ${({ isSmallScreen }) => isSmallScreen && 'none'};
    }
`;