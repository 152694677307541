import styled from "styled-components";
import { theme } from "../../utils/palette.js";
import { useEffect, useRef, useState } from "react";
import { characteristicData } from '../../datas/data.js';
import { useLanguage } from "../../context/LanguageContext.js";


const CustomerInsightTable = ({ isVisible, isSmallScreen }) => {

    const { language } = useLanguage();

    const [ characteristics, setCharacteristic ] = useState(Object.values(characteristicData));
    const [ isTbodyOpen, setIsContentOpen ] = useState(true);
    const [ tbodyHeight, setTbodyHeight ] = useState('0px');

    const tbodyRef = useRef(null);

    const handleToggle = () => {
        setIsContentOpen(!isTbodyOpen);
    }

    useEffect(() => {
        if (tbodyRef.current) {
            if (isSmallScreen) {
                setTbodyHeight(isTbodyOpen ? '322px' : '0px');
            } else {
                setTbodyHeight(isTbodyOpen ? `${tbodyRef.current.scrollHeight}px` : '0px');
            }
        }
    }, [isTbodyOpen, characteristics, isSmallScreen]);

    const borderColor = theme.colors.accent1;


    return (
        <Table color={theme.colors.gray3} borderColor={borderColor} isSmallScreen={isSmallScreen}>
            <Row 
                className="bg-main3 hover:bg-accent2 cursor-pointer"
                style={{
                    borderBottom : isTbodyOpen ? `1px solid ${borderColor}` : 'transparent',
                    fontSize : isSmallScreen ? '11px' : '13px'
                }}
                borderColor={borderColor} 
                isSmallScreen={isSmallScreen}
                onClick={handleToggle} 
            >
                <Column className={`${isSmallScreen ? 'w-60' : 'w-80'} h-full justify-center`} borderColor={borderColor}>
                    { language === 'kor' ? `지표` : `Metrics` }
                </Column>
                <Column className={`${isSmallScreen ? 'w-310' : 'w-340'} h-full justify-center`}>
                    { language === 'kor' ? `정의` : `Definitions` }
                </Column>
            </Row>
            <Tbody ref={tbodyRef} height={tbodyHeight}>
                {
                    characteristics.map(({ category, definition}, i) => (
                        <Row 
                            className="bg-white" 
                            style={{fontSize : isSmallScreen ? '10px' : '12px'}}
                            borderColor={borderColor} 
                            isSmallScreen={isSmallScreen}
                            key={i} 
                        >
                            <Column className={`${isSmallScreen ? 'w-60' : 'w-80'} h-full justify-center`} borderColor={borderColor}>
                                { category }
                            </Column>
                            <Column className={`${isSmallScreen ? 'w-310 px-4' : 'w-340 px-8'} h-full justify-start`}>
                                { definition[language] }
                            </Column>
                        </Row>
                    ))
                }
            </Tbody>
        </Table>
    );
}

export default CustomerInsightTable;


const Table = styled.section`
    width: ${({ isSmallScreen }) => isSmallScreen ? '320px' : '420px' };
    margin: 0 auto;
    color: ${({ color }) => color };
    border: 1px solid ${({ borderColor }) => borderColor };
    border-radius: 8px;
    overflow: hidden;
`;

const Row = styled.div`
    display: flex;
    align-items: center;
    height: ${ ({ isSmallScreen }) => isSmallScreen ? '20px' : '24px' };
    &:not(:last-child) {
        border-bottom: 1px solid ${ ({ borderColor }) => borderColor };
    }
`;

const Column = styled.span`
    display: inline-flex;
    align-items: center;
    
    &:not(:last-child) {
        border-right: 1px solid ${ ({ borderColor }) => borderColor };
    }
`;

const Tbody = styled.div`
    height: ${ ({ height }) => height };
    overflow: hidden;
    transition: height 0.3s ease;
`;
