import detailedInfoImg from '../../src/img/detailed_info.svg';
import personalDataImg from '../../src/img/personal_data.svg';
import customerBehaviorImg from '../../src/img/customer_behavior.svg';
import accuracySpeedImg from '../../src/img/accuracy_speed.svg';
import seongnam from '../../src/img/enterprise/Seongnam_Youth.png';
import dif from '../../src/img/enterprise/DIF.png';
import openId from '../../src/img/enterprise/OpenID.png';
import obdia from '../../src/img/enterprise/OBDIA.png';
import costartup from '../../src/img/enterprise/Co_Startup.png';
import peopleLife from '../../src/img/enterprise/people_life.png';
import kodc from '../../src/img/enterprise/KDOC.png';
import wellips from '../../src/img/enterprise/Well_ips.png';
import dsolve from '../../src/img/enterprise/DSOLVE.png';
import ycn from '../../src/img/enterprise/YeCheNeung.png';
import dpland from '../../src/img/enterprise/DPLAND.png';
import big from '../../src/img/enterprise/oBigshare.png';
import tria from '../../src/img/enterprise/TRIA.png';
import gaworld from '../../src/img/enterprise/GAWORLD.png';
import insaeng from '../../src/img/enterprise/insaeng.png';
import ffox from '../../src/img/enterprise/FFOX.png';

import valuableImg from '../img/valuable.svg';
import ownershipImg from '../img/ownership.svg';
import safetyImg from '../img/safety.svg';

import wfaLarge from '../img/wfa_large.png';
import wfaSmall from '../img/wfa_small.png';
import selfDirectedLarge from '../img/self_directed_large.png';
import selfDirectedSmall from '../img/self_directed_small.png';
import workationLarge from '../img/workation_large.png';
import workationSmall from '../img/workation_small.png';
import selfDevelopLarge from '../img/self_development_large.png';
import selfDevelopSmall from '../img/self_development_small.png';

import hanamLarge from '../img/icon/Hanam_large.png';
import hanamSmall from '../img/icon/Hanam_small.png';
import seoulLarge from '../img/icon/Seoul_large.png';
import seoulSmall from '../img/icon/Seoul_small.png';
import chungnamLarge from '../img/icon/ChungNam_large.png';
import chungnamSmall from '../img/icon/ChungNam_small.png';

import hanamLargeEng from '../img/icon/Hanam_large_eng.png';
import hanamSmallEng from '../img/icon/Hanam_small_eng.png';
import seoulLargeEng from '../img/icon/Seoul_large_eng.png';
import seoulSmallEng from '../img/icon/Seoul_small_eng.png';
import chungnamLargeEng from '../img/icon/ChungNam_large_eng.png';
import chungnamSmallEng from '../img/icon/ChungNam_small_eng.png';



export const headerText = {

    solution : {
        kor : 'SALTi 솔루션',
        eng : 'Our Solution'
    },
    info : {
        kor : '회사소개',
        eng : 'About Us'
    },
    inquiry : {
        kor : '문의하기',
        eng : 'Contact Us'
    },
    case : {
        kor : '고객 사례',
        eng : 'Client Cases'
    }

};

export const footerText = {

    kor : {
        company : '주식회사 소금광산',
        ceo : '대표이사ㅣ',
        name : '유창현',
        regNo : {
            subject : '사업자 등록번호ㅣ',
            number : '873-86-01911'
        },
        headquarters : {
            subject : '본사 주소ㅣ',
            address : [ '(12902) 경기도 하남시 미사강변한강로 177, ', '3층 하남스타트업캠퍼스 7호' ]
        },
        lab : {
            subject : '연구소 주소ㅣ',
            address : [ '(31145) 충남 천안시 서북구 천안천4길 32, ', '그린스타트업타운 501호' ]
        },
        contact : {
            subject : '연락처ㅣ',
            number : '070-7799-7410'
        }
    },
    eng : {
        company : 'SALTMiNE Ltd.',
        ceo : 'CEOㅣ',
        name : 'Chang Hyun Yoo',
        regNo : {
            subject : 'Reg No.ㅣ',
            number : '873-86-01911'
        },
        headquarters : {
            subject : 'Headquartersㅣ',
            address : [['(12902) 177, Misagangbyeonhangang-ro'], ['Hanam-si, Gyeonggi-do, Republic of Korea']]
        },
        lab : {
            subject : 'Labㅣ',
            address : [['(31145) 32, Cheonancheon 4-gil'], ['Seobuk-gu, Cheonan-si, Chungcheongnam-do, Republic of Korea']]
        },
        contact : {
            subject : 'Contactㅣ',
            number : '070-7799-7410'
        }
    }

};


export const partnerLogo = [

    [
        seongnam,
        dif,
        openId,
        obdia,
        costartup,
        peopleLife,
        kodc,
        wellips,
    ],
    [
        dsolve,
        ycn,
        dpland,
        big,
        tria,
        gaworld,
        insaeng,
        ffox,
    ]

]



export const characteristicData = {

    Cg : {
        category: 'Cg',
        definition: {
            kor: '타인에게 영향을 주는 것을 중요하게 생각하는 나',
            eng: 'Me, who values influencing others'
        }
    },
    Ct : {
        category: 'Ct',
        definition: {
            kor: '타인으로부터 영향을 받는 것을 중요하게 생각하는 나',
            eng: 'Me, who values being influenced by others'
        }
    },
    As : {
        category: 'As',
        definition: {
            kor: '스스로에게 관대한 나',
            eng: 'Me, who is lenient with myself'
        }
    },
    Ah : {
        category: 'Ah',
        definition: {
            kor: '스스로에게 엄격한 나',
            eng: 'Me, who is strict with myself'
        }
    },
    Id : {
        category: 'Id',
        definition: {
            kor: '현실과 직접적인 관계 안에서 상상하는 나',
            eng: 'Me, who imagines within a direct relationship to reality'
        }
    },
    Ii : {
        category: 'Ii',
        definition: {
            kor: '현실과 별로 상관없이 상상하는 나',
            eng: 'Me, who imagines with little regard to reality'
        }
        
    },
    Ep : {
        category: 'Ep',
        definition: {
            kor: '직접 경험한 것을 가장 중요하게 생각하는 나',
            eng: 'Me, who values personal experience the mos'
        }
    },
    Et : {
        category: 'Et',
        definition: {
            kor: '직접 경험한 것과 간접 경험한 것을 종합해서 생각하는 나',
            eng: 'Me, who considers both direct and indirect experiences'
        }
    },
    Lp : {
        category: 'Lp',
        definition: {
            kor: '과정을 중요하게 생각하고 합리적으로 의사결정하는 나',
            eng: 'Me, who values the process and makes rational decisions'
        }
    },
    Lr : {
        category: 'Lr',
        definition: {
            kor: '결과를 중요하게 생각하고 합리적으로 의사결정하는 나',
            eng: 'Me, who values the outcome and makes rational decisions'
        }
    },
    Sm : {
        category: 'Sm',
        definition: {
            kor: '자신의 감정을 중심으로 의사결정 하는 나',
            eng: 'Me, who makes decisions based on my own emotions'
        }
    },
    Sy : {
        category: 'Sy',
        definition: {
            kor: '타인의 감정을 중심으로 의사결정 하는 나',
            eng: `Me, who makes decisions based on others' emotions`
        }
    },
    Pi : {
        category: 'Pi',
        definition: {
            kor: '나만의 원칙에 따라 살아가는 나',
            eng: 'Me, who lives by my own principles'
        }
    },
    Pg : {
        category: 'Pg',
        definition: {
            kor: '공동체의 원칙에 따라 살아가는 나',
            eng: 'Me, who lives by community principles'
        }
    },
    Fa : {
        category: 'Fa',
        definition: {
            kor: '주어진 상황을 있는 그대로 받아들이는 나',
            eng: 'Me, who accepts situations as they are'
        }
    },
    Fu : {
        category: 'Fu',
        definition: {
            kor: '주어진 상황을 받아 들이면서 개선해 나가는 나',
            eng: 'Me, who accepts and works to improve situations'
        }
    },
    
}


export const chipDataMain = [

    'CgIdLpPi',
    'CgIiLpPi',
    'CgIdLpFu',
    'CgIiLrFa',
    'CgIdSyPg',
    'CgIdSmFa',
    'CgIiSmFu',
    'CgIdSyFa',
    'CgIiSmPi',
    'CgIiLrPi',
    'AhIdLpPg',
    'AhIiLrPi',
    'AhIdLrFu',
    'AhIdSmFu',
    'AhIiSyFa',
    'AhEtLpPg',
    'AhEpSyFa',
    'AhEtSmFa',
    'AhEtSyFa',
    'AhEtSyFu'

]

export const chipDataSolution = {

    kor : [
        '스몰데이터 수집',
        '개인화 서비스 제공',
        '데이터 커스터마이징',
        '결과 리포트 제공',
        '빅데이터 연동 및 호환',
        '클라우드 기반 서비스',
        '데이터 시각화 솔루션 엑셀 데이터 커스터마이징'
    ],
    eng : [
        'Small Data Collection',
        'Personalized Service Provision',
        'Data Customization',
        'Customized Outcome Report',
        'Big Data Integration and Compatibility',
        'Cloud-Based Service',
        'Data Visualization Solution & Excel Data Customization'
    ]

}

export const mainPageText = {

    kor : {
        smDataBg : {
            title : {
                pc : `스몰데이터로 쓰임 있는 가치를`,
                m : `스몰데이터로 쓰임 있는 가치를`
            },
            subCopy: {
                pc : `소금광산은 스몰데이터 생성 데이터모형 및 솔루션을 개발하여<br/>
                    고객 맞춤 비즈니스 솔루션 SALTi를 제공하고 있습니다.`,
                m : `소금광산은 스몰데이터 생성 데이터모형 및<br/>
                    솔루션을 개발하여 고객 맞춤 비즈니스 솔루션<br/> 
                    SALTi를 제공하고 있습니다.`
            },
            salti: {
                pc : `SALTi 알아보기`,
                m : `SALTi 알아보기`
            }
        },

        smData : {
            title: {
                pc: `스몰데이터`,
                m: `스몰데이터`
            },
            subCopy: {
                pc: `스몰데이터는 개인의 취향과 라이프 스타일 등 사소한 행동 속에서 나오는 개인화된
                    데이터를 의미합니다. 스몰데이터를 통해 개개인의 취향, 소비성향, 기호 등을 세부적
                    으로 파악할 수 있습니다.`,
                m: `스몰데이터는 개인의 취향과 라이프 스타일 등 사소한 행동<br/> 
                    속에서나오는 개인화된 데이터를 의미합니다. 스몰데이터를<br/> 
                    통해 개개인의 취향, 소비성향, 기호 등을 세부적으로 파악할<br/> 수 있습니다.`
            },
            contents: [
                {
                    img: detailedInfoImg,
                    text1: '빅데이터보다',
                    text2: '세세한 수집 정보'
                },
                {
                    img: personalDataImg,
                    text1: '전체 데이터 분석이 아닌',
                    text2: '개인을 알 수 있는 데이터'
                },
                {
                    img: customerBehaviorImg,
                    text1: '유추 가능한',
                    text2: '고객 행동'
                },
                {
                    img: accuracySpeedImg,
                    text1: '상대적으로 높은',
                    text2: '정확성과 속도'
                }
            ]
        },

        solution : {
            title: {
                pc: `SALTi 솔루션으로 쉬운 데이터 활용`,
                m: `SALTi 솔루션으로 쉬운 데이터 활용`
            },
            subCopy: {
                pc: `기업의 고객 행동 분석이나 마케팅을 집행할 때 스몰데이터 모형을 활용할 수 있습니다.<br/>
                    고객은 개인의 성향을 직접적으로 도출하고,<br/>
                    기업은 모여진 데이터를 통해 고객들에게 맞춤형 혜택과 개인화된 서비스를 제공할 수 있습니다.`,
                m: `기업의 고객 행동 분석이나 마케팅을 집행할 때<br/> 
                    스몰데이터 모형을 활용할 수 있습니다.<br/>
                    고객은 개인의 성향을 직접적으로 도출하고,<br/>
                    기업은 모여진 데이터를 통해 고객들에게<br/> 
                    맞춤형 혜택과 개인화된 서비스를 제공할 수 있습니다.`
            }
        },

        insight : {
            title: {
                pc: `세세하게 알 수 있는 고객의 성향`,
                m: `세세하게 알 수 있는 고객의 성향`
            },
            subCopy: {
                pc: `빅데이터가 포착하지 못하는 개인의 세세한 성향을 추출해낼 수 있기 때문에<br/>
                    고객의 니즈를 충족시키는데 유리합니다.`,
                m: `빅데이터가 포착하지 못하는 개인의 세세한 성향을 추출해낼<br/>
                    수 있기 때문에 고객의 니즈를 충족시키는데 유리합니다.`
            }
        },

        satisfactionBg : {
            title: {
                pc: `고객이 만족하는 상품,<br/> 
                    더 친숙하고 정확한 서비스 제공`,
                m: `고객이 만족하는 상품,<br/> 
                    더 친숙하고 정확한 서비스 제공`
            },
            subCopy: {
                pc: `기업은 고객의 차별화된 특성을 파악하여 구체적인 솔루션을 만들어갈 수 있으며,<br/>
                    고객은 이러한 기업의 서비스 및 상품에 대한 만족도가 높아져 지속적인 구매로 이어질 수 있습니다.`,
                m: `기업은 고객의 차별화된 특성을 파악하여<br/> 
                    구체적인 솔루션을 만들어갈 수 있으며,<br/>
                    고객은 이러한 기업의 서비스 및 상품에 대한<br/> 
                    만족도가 높아져 지속적인 구매로 이어질 수 있습니다.`
            }
        },

        partner : {
            title: {
                pc: `Our Partner`,
                m: `Our Partner`
            },
            subCopy: {
                pc: `소금광산의 가치를 공유하는 소중한 파트너를 소개합니다.`,
                m: `소금광산의 가치를 공유하는 소중한 파트너를 소개합니다.`
            }
        }
    },

    eng : {
        smDataBg : {
            title : {
                pc : `Creating Value with Small Data`,
                m : `Creating Value with Small Data`
            },
            subCopy: {
                pc : `At SALTMiNE, we develop data models and solutions to generate small data,<br/>
                    providing a tailored business solution called SALTi for our clients.`,
                m : `At SALTMiNE, we develop data models and solutions<br/>
                    to generate small data, providing a tailored business<br/>
                    solution called SALTi for our clients.`
            },
            salti: {
                pc : `Learn More about SALTi`,
                m : `Learn More about SALTi`
            }
        },
    
        smData : {
            title: {
                pc: `Small Data`,
                m: `Small Data`
            },
            subCopy: {
                pc: `Small data is personalized information from individual actions like preferences and lifestyle choices, offering detailed insights into personal inclinations and consumer behavior.`,
                m: `Small data is personalized information from individual actions like preferences and lifestyle choices, offering detailed insights into personal inclinations and consumer behavior.`
            },
            contents: [
                {
                    img: detailedInfoImg,
                    text1: 'More Detailed',
                    text2: 'than Big Data'
                },
                {
                    img: personalDataImg,
                    text1: 'Data Analysis to Know',
                    text2: 'the “individual”'
                },
                {
                    img: customerBehaviorImg,
                    text1: 'Predictable',
                    text2: 'Customer Behavior'
                },
                {
                    img: accuracySpeedImg,
                    text1: 'Relatively High',
                    text2: 'Accuracy & Speed'
                }
            ]
        },
    
        solution : {
            title: {
                pc: `Our solution SALTi makes for easy data usage`,
                m: `Our solution SALTi makes for easy data usage`
            },
            subCopy: {
                pc: `Businesses can leverage small data models for effective customer analysis and marketing.<br/>
                    Through small data, customers can directly uncover their personal preferences,<br/>
                    while companies use aggregated data to deliver personalized services and benefits.`,
                m: `Businesses can use small data models to analyze customer behavior or execute marketing strategies effectively.
                    Through small data, customers can directly uncover their personal preferences, while companies can use aggregated data to offer tailored benefits and personalized services to their clients.`
            }
        },
    
        insight : {
            title: {
                pc: `Detailed insight into customer preferences`,
                m: `Detailed insight into customer preferences`
            },
            subCopy: {
                pc: `Derives detailed individual preferences often missed by big data<br/>
                    to better satisfy customer needs.`,
                m: `Derives detailed individual preferences often missed by big data to better satisfy customer needs.`
            }
        },
    
        satisfactionBg : {
            title: {
                pc: `Products that Satisfy Customers,<br/>
                    Services that are Friendly and Accurate`,
                m: `Products that Satisfy Customers,<br/>
                    Services that are Friendly and Accurate`
            },
            subCopy: {
                pc: `Businesses can create specific solutions by understanding the unique characteristics of each customer,<br/>
                    leading to higher satisfaction with products and services, and encouraging repeat purchases.`,
                m: `Businesses can create specific solutions by understanding<br/>
                    the unique characteristics of each customer,<br/>
                    leading to higher satisfaction with products and services,<br/>
                    and encouraging repeat purchases.`
            }
        },
    
        partner : {
            title: {
                pc: `Our Partner`,
                m: `Our Partner`
            },
            subCopy: {
                pc: `Introducing Our Valued Partners Who Share SALTMiNE's Values.`,
                m: `Introducing Our Valued Partners Who Share SALTMiNE's Values.`
            }
        }
    }

}


export const solutionLandingText = {

    kor : {
        subject: {
            title: {
                pc: `주제 설정`,
                m: `주제 설정`
            },
            subCopy: {
                pc: `기업의 서비스나 상품에 맞는 주제를 설정합니다.`,
                m: `기업의 서비스나 상품에 맞는 주제를<br/>
                    설정합니다.`
            }
        },

        qna: {
            title: {
                pc: `문답 제작`,
                m: `문답 제작`
            },
            subCopy: {
                pc: `소금광산 스몰데이터모형을 활용하여<br/>
                    주제에 맞는 문답지를 제작합니다.`,
                m: `소금광산 스몰데이터모형을 활용하여<br/>
                    주제에 맞는 문답지를 제작합니다.`
            }
        },

        result: {
            title: {
                pc: `결과 도출`,
                m: `결과 도출`
            },
            subCopy: {
                pc: `고객 성향별 알맞은 키워드와 분석글을 보여줍니다.`,
                m: `고객 성향별 알맞은 키워드와 분석글을<br/>
                    보여줍니다.`
            }
        },

        service: {
            title: {
                pc: `서비스 및 상품 추천`,
                m: `서비스 및 상품 추천`
            },
            subCopy: {
                pc: `고객 성향에 맞는 맞춤형 서비스 및 상품을 추천합니다.`,
                m: `고객 성향에 맞는 맞춤형 서비스 및 상품을<br/>
                    추천합니다.`
            }
        },

        event: {
            title: {
                pc: `추가 이벤트 구성`,
                m: `추가 이벤트 구성`
            },
            subCopy: {
                pc: `SALTi 솔루션을 통해 고객 성향 추출과 더불어<br/>
                    기업의 홍보 마케팅 수단으로 활용할 수 있습니다.`,
                m: `SALTi 솔루션을 통해 고객 성향 추출과<br/>
                    더불어 기업의 홍보 마케팅 수단으로<br/>
                    활용할 수 있습니다.`
            }
        },

        info: {
            title: {
                pc: `고객 정보 수집`,
                m: `고객 정보 수집`
            },
            subCopy: {
                pc: `고객의 성향 정보와 기본정보를 수집합니다.`,
                m: `고객의 성향 정보와 기본정보를 수집합니다.`
            }
        }
    },

    eng : {
        subject: {
            title: {
                pc: `Topic Selection`,
                m: `Topic Selection`
            },
            subCopy: {
                pc: `Set a topic that aligns with the company’s services or products.`,
                m: `Set a topic that aligns with the company’s services or products.`
            }
        },
    
        qna: {
            title: {
                pc: `Questionnaire Creation`,
                m: `Questionnaire Creation`
            },
            subCopy: {
                pc: `Using Salt Mine’s small data model,<br/>
                    create a questionnaire relevant to the topic.`,
                m: `Using Salt Mine’s small data model,<br/>
                    create a questionnaire relevant to the topic.`
            }
        },
    
        result: {
            title: {
                pc: `Result Extraction`,
                m: `Result Extraction`
            },
            subCopy: {
                pc: `Display relevant keywords and analysis based on customer tendencies.`,
                m: `Display relevant keywords and analysis based on customer tendencies.`
            }
        },
    
        service: {
            title: {
                pc: `Service and Product Recommendation`,
                m: `Service and Product Recommendation`
            },
            subCopy: {
                pc: `Recommend customized services and products based on customer preferences.`,
                m: `Recommend customized services and products based on customer preferences.`
            }
        },
    
        event: {
            title: {
                pc: `Additional Event Planning`,
                m: `Additional Event Planning`
            },
            subCopy: {
                pc: `Utilize the SALTi solution to extract customer preferences<br/>
                    and support company marketing and promotions.`,
                m: `Utilize the SALTi solution to extract customer preferences<br/>
                    and support company marketing and promotions.`
            }
        },
    
        info: {
            title: {
                pc: `Customer Data Collection`,
                m: `Customer Data Collection`
            },
            subCopy: {
                pc: `Collect basic information and preference data.`,
                m: `Collect basic information and preference data.`
            }
        }
    }

}


export const solutionAdminText = {
    
    kor : {
        dashboard: {
            title: {
                pc: `대시보드`,
                m: `대시보드`
            },
            subCopy: {
                pc: `성향검사 결과에 대한 참여현황, 고객 유형 분포, 키워드 맵을 확인할 수 있습니다.`,
                m: `성향검사 결과에 대한 참여현황, 고객 유형 분포, 키워드 맵을 확인할 수 있습니다.`
            }
        },

        bigdata: {
            title: {
                pc: `빅데이터 분석`,
                m: `빅데이터 분석`
            },
            subCopy: {
                pc: `구체적 참여현황 및 검사수등을 확인할 수 있습니다.`,
                m: `구체적 참여현황 및 검사수등을 확인할 수 있습니다.`
            }
        },

        smalldata: {
            title: {
                pc: `스몰데이터 분석`,
                m: `스몰데이터 분석`
            },
            subCopy: {
                pc: `유형별 결과 및 구체적인 분석 내용을 확인할 수 있습니다.`,
                m: `유형별 결과 및 구체적인 분석 내용을 확인할 수 있습니다.`
            }
        },

        analysis: {
            title: {
                pc: `유입통계 분석`,
                m: `유입통계 분석`
            },
            subCopy: {
                pc: `PV, UV 및 인구통계 분석 등을 확인할 수 있습니다.`,
                m: `PV, UV 및 인구통계 분석 등을 확인할 수 있습니다.`
            }
        }
    },

    eng : {
        dashboard: {
            title: {
                pc: `Dashboard`,
                m: `Dashboard`
            },
            subCopy: {
                pc: `View participation status, customer type distribution, and keyword maps.`,
                m: `View participation status, customer type distribution, and keyword maps.`
            }
        },
    
        bigdata: {
            title: {
                pc: `Big Data Analysis`,
                m: `Big Data Analysis`
            },
            subCopy: {
                pc: `Access detailed participation and test count metrics.`,
                m: `Access detailed participation and test count metrics.`
            }
        },
    
        smalldata: {
            title: {
                pc: `Small Data Analysis`,
                m: `Small Data Analysis`
            },
            subCopy: {
                pc: `Review type-specific results and in-depth analysis details.`,
                m: `Review type-specific results and in-depth analysis details.`
            }
        },
    
        analysis: {
            title: {
                pc: `Traffic Statistics Analysis`,
                m: `Traffic Statistics Analysis`
            },
            subCopy: {
                pc: `View PV, UV, and demographic analysis.`,
                m: `View PV, UV, and demographic analysis.`
            }
        }
    }

}

export const infoPageText = {

    kor : {
        main: {
            title: {
                pc: `개인과 기업의 데이터 거래를<br/> 
                    만들어 가는 기업, 소금광산`,
                m: `개인과 기업의 데이터 거래를<br/>  
                    만들어 가는 기업, 소금광산`
            },
            subCopy: {
                pc: `소금광산은 개인의 정보를 쉽고 투명하게 거래할 수 있는 데이터 커머스 세상을 만들고 있습니다.<br/>
                    지금까지 한번도 경험해 볼 수 없었던 새로운 세상을 만나보세요!`,
                m: `소금광산은 개인의 정보를 쉽고 투명하게<br/>
                    거래할 수 있는 데이터 커머스 세상을 만들고 있습니다.<br/>
                    지금까지 한번도 경험해 볼 수 없었던<br/>
                    새로운 세상을 만나보세요!`
            }
        },

        mission: {
            title: {
                pc: `OUR MiSSiON`,
                m: `OUR MiSSiON`
            },
            subCopy: {
                pc: `데이터를 가치 있게 만듭니다.<br/>
                    데이터에 대한 주권을 제공합니다.<br/>
                    데이터가 안전하게 저장 및 거래되게 합니다.`,
                m: `데이터를 가치 있게 만듭니다.<br/>
                    데이터에 대한 주권을 제공합니다.<br/>
                    데이터가 안전하게 저장 및 거래되게 합니다.`
            }
        },

        history: {
            title: {
                pc: `HiSTORY`,
                m: `HiSTORY`
            },
            subCopy: {
                pc: `아무도 가본 적 없는 길을 걷고 있는 발자취이기에<br/>
                    소금광산의 역사는 모든 것이 최초이고 혁신입니다.`,
                m: `아무도 가본 적 없는 길을 걷고 있는 발자취이기에<br/>
                    소금광산의 역사는 모든 것이 최초이고 혁신입니다.`
            }
        }
    },

    eng : {
        main: {
            title: {
                pc: `A Company Building Data Transactions<br/>
                    for Individuals and Businesses, SALTMiNE`,
                m: `A Company Building<br/>
                    Data Transactions for<br/>
                    Individuals and Businesses,<br/>
                    SALTMiNE`
            },
            subCopy: {
                pc: `SALTMiNE is creating a world of data commerce where individuals can easily and<br/>
                    transparently trade their information. Discover a new world like you've never experienced before!`,
                m: `SALTMiNE is creating a world of data commerce<br/>
                    where individuals can easily and transparently trade<br/>
                    their information. Discover a new world like<br/>
                    you've never experienced before!`
            }
        },
    
        mission: {
            title: {
                pc: `OUR MiSSiON`,
                m: `OUR MiSSiON`
            },
            subCopy: {
                pc: `We make data valuable.<br/>
                    We grant sovereignty over data.<br/>
                    We ensure data is safely stored and traded.`,
                m: `We make data valuable.<br/>
                    We grant sovereignty over data.<br/>
                    We ensure data is safely stored and traded.`
            }
        },
    
        history: {
            title: {
                pc: `HiSTORY`,
                m: `HiSTORY`
            },
            subCopy: {
                pc: `Each step we take is a new one, as SALTMiNE is walking a path no one has traveled before,<br/>
                    Every part of our history is a first and an innovation.`,
                m: `Each step we take is a new one, as SALTMiNE is walking a path no one has traveled before,<br/>
                    Every part of our history is a first and an innovation.`
            }
        }
    }

}

export const missionsData = [
    {
        img: valuableImg,
        subject: 'Valuable',
        content: {
            kor: '개인의 데이터를 금전적 거래가 가능한 자산의 수준까지 가치 있게 만들어 줍니다.',
            eng: 'We elevate personal data to the level of a monetizable asset.'
        }
    },
    {
        img: ownershipImg,
        subject: 'Ownership',
        content: {
            kor: '개인의 데이터에 대한 소유권을 비롯 모든\n권한을 개인에게 제공해 줍니다.',
            eng: 'We grant individuals full ownership and rights to their data.'
        }
    },
    {
        img: safetyImg,
        subject: 'Safety',
        content: {
            kor: '개인의 데이터가 안전하게 저장되고 투명하게 거래될 수 있도록 보호해 줍니다.',
            eng: 'We secure personal data for safe storage and transparent exchange.'
        }
    }
];


export const cultureData = [

    {
        img : {
            pc : wfaLarge,
            m : wfaSmall
        },
        subject : {
            kor : {
                pc : `WFA`,
                m : `WFA`
            },
            eng : {
                pc : `WFA`,
                m : `WFA`
            }
        },
        sub : 'Work From Anywhere, Anyway',
        content : {
            kor : {
                title : {
                    pc : `소금광산은 언제 어디서나 자신의 업무 스타일에 맞춰서 근무합니다.`,
                    m : `소금광산은 언제 어디서나 자신의<br/>
                        업무 스타일에 맞춰서 근무합니다.`
                },
                pc : [
                    `
                        사무실, 집, 카페 등 최고의 퍼포먼스를 낼 수 있는<br/>
                        최적의 근무 환경의 선택이 가능합니다.<br/>
                    `,
                    `
                        새벽형, 올빼미형 등 자신의 스타일에 맞춰<br/>
                        업무에 집중할 수 있는 최상의 시간을 골라 근무할 수 있습니다.
                    `
                ],
                m : [
                    `
                        사무실, 집, 카페 등 최고의 퍼포먼스를 낼 수 있는<br/>
                        최적의 근무 환경의 선택이 가능합니다.
                    `,
                    `
                        새벽형, 올빼미형 등 자신의 스타일에 맞춰<br/>
                        업무에 집중할 수 있는 최상의 시간을 골라<br/>
                        근무할 수 있습니다.
                    `
                ]
            },
            eng : {
                title : {
                    pc : `At SALTMiNE, you can work wherever best fits your working style—whether in the office, at home, or at a cafe.`,
                    m : `At SALTMiNE, you can work wherever best fits your working style—whether in the office, at home, or at a cafe.`
                },
                pc : [
                    `
                        Choose the optimal working environment that allows for the best performance.<br/>
                    `,
                    `
                        Early bird or night owl, pick the time that helps you focus best on your work.<br/>
                    `
                ],
                m : [
                    `
                        Choose the optimal working environment that allows for the best performance.<br/>
                    `,
                    `
                        Early bird or night owl, pick the time that helps you focus best on your work.<br/>
                    `
                ]
            }
        }
    },
    {
        img : {
            pc : selfDirectedLarge,
            m : selfDirectedSmall
        },
        subject : {
            kor : {
                pc : `자기 주도 업무`,
                m : `자기 주도 업무`
            },
            eng : {
                pc : `Self-Directed Work`,
                m : `Self-Directed Work`
            }
        },
        content : {
            kor : {
                title : {
                    pc : `회사의 성장과 서비스 개발에 기여할 수 있는 부분을 찾아 주도적으로 업무를 진행합니다.`,
                    m : `회사의 성장과 서비스 개발에 기여할 수 있는<br/>
                        부분을 찾아 주도적으로 업무를 진행합니다.`
                },
                pc : [
                    `
                        누군가 시켜서 일을 하는 게 아닌, 스스로 필요한 것을 발견하고 기획하며<br/>
                        동료들과 함께 발전시켜서 나갈 수 있습니다.`,
                    `
                        업무를 진행하면서 어려움이 있으면 언제든지 동료들에게 도움을 요청하고<br/>
                        함께 해결하며 시너지를 낼 수 있는 환경을 만들어 나갈 수 있습니다.
                    `
                ],
                m : [
                    `
                        누군가 시켜서 일을 하는 게 아닌,<br/>
                        스스로 필요한 것을 발견하고 기획하며<br/>
                        동료들과 함께 발전시켜서 나갈 수 있습니다.
                    `,
                    `
                        업무를 진행하면서 어려움이 있으면<br/>
                        언제든지 동료들에게 도움을 요청하고<br/>
                        함께 해결하며 시너지를 낼 수 있는<br/>
                        환경을 만들어 나갈 수 있습니다.
                    `
                ]
            },
            eng : {
                title : {
                    pc : `Find parts that can contribute to the company's growth and service development and take the lead in working.`,
                    m : `Find parts that can contribute to the company's growth and service development and take the lead in working.`
                },
                pc : [
                    `
                        You can discover and plan what you need, and develop it with your colleagues, rather than having someone do it.<br/>
                    `,
                    `
                        If challenges arise during work, they can always request help from peers, creating a synergy-rich environment.<br/>
                    `
                ],
                m : [
                    `
                        You can discover and plan what you need, and develop it with your colleagues, rather than having someone do it.<br/>
                    `,
                    `
                        If challenges arise during work, they can always request help from peers, creating a synergy-rich environment.<br/>
                    `
                ]
            }
            
        }
    },
    {
        img : {
            pc : workationLarge,
            m : workationSmall
        },
        subject : {
            kor : {
                pc : `Workation`,
                m : `Workation`
            },
            eng : {
                pc : `Workation`,
                m : `Workation`
            }
        },
        content : {
            kor : {
                title : {
                    pc : `소금광산의 업무 복지 프로그램인 워케이션을 통해 자아실현에 한 단계 다가갑니다.`,
                    m : `소금광산의 업무 복지 프로그램인 워케이션을<br/>
                        통해 자아실현에 한 단계 다가갑니다.`
                },
                pc :[
                    `
                        워케이션은 휴가와 업무 진행을 병행할 수 있는 업무 복지 제도입니다.
                    `,
                    `
                        회사 및 동료들과의 협의를 통해 워케이션의<br/>
                        진행 여부를 결정하여 진행할 수 있습니다.
                    `
                ],
                m : [
                    `
                        워케이션은 휴가와 업무 진행을 병행할 수 있는<br/>
                        업무 복지 제도입니다.
                    `,
                    `
                        회사 및 동료들과의 협의를 통해 워케이션의<br/>
                        진행 여부를 결정하여 진행할 수 있습니다.
                    `
                ]
            },
            eng : {
                title : {
                    pc : `We take a step closer to self-realization through SALTMiNE's work welfare program, Workation.`,
                    m : `We take a step closer to self-realization through SALTMiNE's work welfare program, Workation.`
                },
                pc :[
                    `
                        The workcation system enables employees to combine work with vacation.
                    `,
                    `
                        With company and peer approval, the workation plan can be arranged and executed.

                    `
                ],
                m : [
                    `
                        The workcation system enables employees to combine work with vacation.
                    `,
                    `
                        With company and peer approval, the workation plan can be arranged and executed.
                    `
                ]
            }
        }
    },
    {
        img : {
            pc : selfDevelopLarge,
            m : selfDevelopSmall
        },
        subject : {
            kor : {
                pc : `자기계발`,
                m : `자기계발`
            },
            eng : {
                pc : `Self-Development`,
                m : `Self-Development`
            },
        },
        content : {
            kor : {
                title : {
                    pc : `자신과 회사의 무한한 발전을 위해 필요한 환경을 제공합니다.`,
                    m : `자신과 회사의 무한한 발전을 위해<br/>
                        필요한 환경을 제공합니다.`
                },
                pc : [
                    `
                        도서 구매, 인터넷 강의 및 강연, 전자책 구독까지<br/>
                        업무에 도움이 되는 자기 계발 비용을 지원받을 수 있습니다.
                    `,
                    `
                    동료들과 함께 자발적으로 스터디 그룹을 만들어<br/>
                    학습을 통한 자기 계발을 해나갈 수 있습니다.
                    `
                ],
                m : [
                    `
                        도서 구매, 인터넷 강의 및 강연, 전자책 구독까지<br/>
                        업무에 도움이 되는 자기 계발 비용을 지원받을 수 있습니다.
                    `,
                    `
                        동료들과 함께 자발적으로 스터디 그룹을 만들어<br/>
                        학습을 통한 자기 계발을 해나갈 수 있습니다.
                    `
                ]
            },
            eng : {
                title : {
                    pc : `SALTMiNE offers an environment that encourages infinite growth for both the individual and the company.`,
                    m : `SALTMiNE offers an environment that encourages infinite growth for both the individual and the company.`
                },
                pc : [
                    `
                        Expenses for book purchases, online classes, lectures, and e-book subscriptions are covered to support self-development beneficial to work.
                    `,
                    `
                        Employees can voluntarily create study groups with peers for personal growth through learning.
                    `
                ],
                m : [
                    `
                        Expenses for book purchases, online classes, lectures, and e-book subscriptions are covered to support self-development beneficial to work.
                    `,
                    `
                        Employees can voluntarily create study groups with peers for personal growth through learning.
                    `
                ]
            }
        }
    }

]


export const locationData = {

    kor : [
        { 
            id : 1,
            name : '본사', 
            address : '경기도 하남시 미사강변한강로 177, 3층 하남스타트업캠퍼스 7호',
            postCode : '12902',
            tel : '070-7799-7410',
            coords : { lat: 37.5740441770805, lng: 127.194268726196 }, 
            icon : { large: hanamLarge, small: hanamSmall } 
        },
        { 
            id : 2,
            name : '서울지사', 
            address : '서울특별시 마포구 광성로 31-1 춘추가원 지하1층',
            postCode : '04094',
            tel : '070-7799-7410',
            coords : { lat: 37.565674, lng: 126.977969 }, 
            icon : { large : seoulLarge, small: seoulSmall } 
        },
        { 
            id : 3,
            name : '충남지사', 
            address : '충남 천안시 서북구 천안천4길 32, 그린스타트업타운 501호',
            postCode : '31145',
            tel : '070-7799-7410',
            coords : { lat: 36.809560162563, lng: 127.144178968144 }, 
            icon : { large: chungnamLarge, small: chungnamSmall } 
        }
    ],
    eng : [
        { 
            id : 1,
            name : 'Headquarters', 
            address : `177, Misagangbyeonhangang-ro\nHanam-si, Gyeonggi-do, Republic of Korea`,
            postCode : '12902',
            tel : '070-7799-7410',
            coords : { lat: 37.5740441770805, lng: 127.194268726196 }, 
            icon : { large: hanamLargeEng, small: hanamSmallEng } 
        },
        { 
            id : 2,
            name : 'Seoul', 
            address : '31-1, Gwangseong-ro\nMapo-gu, Seoul, Republic of Korea',
            postCode : '04094',
            tel : '070-7799-7410',
            coords : { lat: 37.565674, lng: 126.977969 }, 
            icon : { large : seoulLargeEng, small: seoulSmallEng } 
        },
        { 
            id : 3,
            name : 'Chungcheongnam-do', 
            address : '32, Cheonancheon 4-gil\nSeobuk-gu, Cheonan-si, Chungcheongnam-do, Republic of Korea',
            postCode : '31145',
            tel : '070-7799-7410',
            coords : { lat: 36.809560162563, lng: 127.144178968144 }, 
            icon : { large: chungnamLargeEng, small: chungnamSmallEng } 
    }

    ]

}