import ilharuImg from '../../src/img/ilharu.png';
import homeTravelImg from '../../src/img/home_travel.png';
import flasierImg from '../../src/img/flasier.png';
import wooAhYeahImg from '../../src/img/woo_ah_yeah.png';
import ilharuLogo from '../../src/img/ilharu_modal.png';
import homeTravelLogo from '../../src/img/home_travel_modal.png';
import flasierLogo from '../../src/img/flasier_modal.png';
import wooAhYeahLogo from '../../src/img/woo_ah_yeah_modal.png';
import ilharuModalUser1 from '../../src/img/ilharu_modal_user1.svg';
import ilharuModalUser1Small from '../../src/img/ilharu_modal_user1_small.svg';
import ilharuModalUser2 from '../../src/img/ilharu_modal_user2.svg';
import ilharuModalUser2Small from '../../src/img/ilharu_modal_user2_small.svg';
import ilharuModalUser3 from '../../src/img/ilharu_modal_user3.svg';
import ilharuModalAdmin1 from '../../src/img/ilharu_modal_admin1.svg';
import ilharuModalAdmin2 from '../../src/img/ilharu_modal_admin2.svg';
import ilharuModalAdmin3 from '../../src/img/ilharu_modal_admin3.svg';
import ilharuModalAdmin3Small from '../../src/img/ilharu_modal_admin3_small.svg';
import ilharuModalAdmin4 from '../../src/img/ilharu_modal_admin4.svg';
import ilharuModalAdmin5 from '../../src/img/ilharu_modal_admin5.svg';
import ilharuModalAdmin6 from '../../src/img/ilharu_modal_admin6.svg';
import ilharuModalAdmin6Small from '../../src/img/ilharu_modal_admin6_small.svg';
import homeTravelModalUser1 from '../../src/img/home_travel_modal_user1.svg';
import homeTravelModalUser1Small from '../../src/img/home_travel_modal_user1_small.svg';
import homeTravelModalUser2 from '../../src/img/home_travel_modal_user2.svg';
import homeTravelModalUser2Small from '../../src/img/home_travel_modal_user2_small.svg';
import homeTravelModalUser3 from '../../src/img/home_travel_modal_user3.svg';
import homeTravelModalAdmin1 from '../../src/img/home_travel_modal_admin1.svg';
import homeTravelModalAdmin2 from '../../src/img/home_travel_modal_admin2.svg';
import homeTravelModalAdmin2Small from '../../src/img/home_travel_modal_admin2_small.svg';
import homeTravelModalAdmin3 from '../../src/img/home_travel_modal_admin3.svg';
import homeTravelModalAdmin3Small from '../../src/img/home_travel_modal_admin3_small.svg';
import falsierModalUser1 from '../../src/img/flasier_modal_user1.svg';
import falsierModalUser1Samll from '../../src/img/flasier_modal_user1_small.svg';
import falsierModalUser2 from '../../src/img/flasier_modal_user2.svg';
import falsierModalUser2Small from '../../src/img/flasier_modal_user2_small.svg';
import falsierModalUser3 from '../../src/img/flasier_modal_user3.svg';
import falsierModalUser3Small from '../../src/img/flasier_modal_user3_small.svg';
import falsierModalAdmin1 from '../../src/img/flasier_modal_admin1.svg';
import falsierModalAdmin2 from '../../src/img/flasier_modal_admin2.svg';
import falsierModalAdmin3 from '../../src/img/flasier_modal_admin3.svg';
import falsierModalAdmin3Small from '../../src/img/flasier_modal_admin3_small.svg';
import wooAhYeahModalUser1 from '../../src/img/woo_ah_yeah_modal_user1.svg';
import wooAhYeahModalUser1Small from '../../src/img/woo_ah_yeah_modal_user1_small.svg';
import wooAhYeahModalUser3 from '../../src/img/woo_ah_yeah_modal_user3.svg';
import wooAhYeahModalUser3Small from '../../src/img/woo_ah_yeah_modal_user3_small.svg';
import wooAhYeahModalAdmin1 from '../../src/img/woo_ah_yeah_modal_admin1.svg';
import wooAhYeahModalAdmin2 from '../../src/img/woo_ah_yeah_modal_admin2.svg';
import wooAhYeahModalAdmin2Small from '../../src/img/woo_ah_yeah_modal_admin2_small.svg';
import wooAhYeahModalAdmin3 from '../../src/img/woo_ah_yeah_modal_admin3.svg';
import wooAhYeahModalAdmin3Small from '../../src/img/woo_ah_yeah_modal_admin3_small.svg';


export const customerCasesData = {

    kor : [
        {
            title: '일하루',
            subCopy: '청소년들의 일자리 성향을 알 수 있는 솔루션 제공',
            img: ilharuImg,
            firstChip: '교육',
            secondChip: '일자리 성향 분석'
        }, 
        {
            title: '홈트래블',
            subCopy: '고객의 스트레스 해소 성향을 알 수 있는 솔루션 제공',
            img: homeTravelImg,
            firstChip: '이커머스',
            secondChip: '스트레스 성향 분석'
        }, 
        {
            title: '플레지어',
            subCopy: '고객의 가구 구매성향을 알 수 있는 솔루션 제공',
            img: flasierImg,
            firstChip: '이커머스',
            secondChip: '가구 구매 성향 분석'
        }, 
        {
            title: '우아예',
            subCopy: '아이의 놀이 성향을 알 수 있는 솔루션 제공',
            img: wooAhYeahImg,
            firstChip: '교육',
            secondChip: '아이 놀이 성향 분석'
        }
    ],
    eng : [
        {
            title: 'Ilharu',
            subCopy: 'Youth job preference analysis solution',
            img: ilharuImg,
            firstChip: 'education',
            secondChip: 'job preference'
        }, 
        {
            title: 'Home Travel',
            subCopy: 'Customer stress-relief analysis solution',
            img: homeTravelImg,
            firstChip: 'E-commerce',
            secondChip: 'stress preference'
        }, 
        {
            title: 'Flasier',
            subCopy: 'Customer furniture purchase analysis solution',
            img: flasierImg,
            firstChip: 'E-commerce',
            secondChip: 'furniture preference'
        }, 
        {
            title: 'Woo Ah Yeah',
            subCopy: 'Child play preference analysis solution',
            img: wooAhYeahImg,
            firstChip: 'education',
            secondChip: 'child play preference'
        }
    ]

};


export const modalCasesData = {

    kor : [
        {
            logo : ilharuLogo,
            logoSizeLarge : { width: '239px', height: '71px' },
            logoSizeSmall : { width: '146px', height: '31px' },
            title : `일자리 성향 알아보기`,
            company : `성남시 꿈드림 일하루`,
            project : `일자리 성향 알아보기`,
            period : `23.08.10. ~ 23.11.30.`,
            user : [
                {
                    title : `Over view`,
                    subCopy : `SMTi-일자리 성향검사는 직업의 특성과 고객의 유형을 고려하여 고객의 선호도, 취향, 성격 특성 등의 다양한 측면을 직업에 적합하게 탐색하여 보유 고객의 유형을 바탕으로 선호 직업을 파악 할 수 있도록 설계되었습니다.
                            <br/>
                            <br/>
                            문답을 통하여 도출된 유형은 고객의 성향을 나타내며, 일자리 선택에 중요한 영향을 미치는 요소를 포함시키고 있습니다. 또한 유형에 따라 고객에게 적합한 일자리를 추천하는 과정에서 유형을 고려하고 있습니다. 
                            <br/>
                            <br/>
                            이를 통해 고객들은 자신에게 맞는 일자리를 더욱 정확하게 제안 받을 수 있으며, 개인적인 성향과 스타일을 반영한 업무를 알아 볼 수 있습니다. 결론적으로 SMTi 유형 검사를 통해서 고객들은 보다 개인화된 일자리 선택 경험을 할 수 있습니다.`,
                    img : {
                        pc : ilharuModalUser1,
                        m : ilharuModalUser1Small
                    } 
                },
                {
                    title : `질의 응답`,
                    subCopy : `SMTi-일자리 성향검사의 질의 응답은 16문답을 고객에게 제시하여 자신의 일자리 성향을 알 수 있도록 돕고 있습니다.
                            <br/>
                            <br/>
                            문답은 일자리에 대한 참여자의 개성과 특징을 섬세하게 파악하기 위해 정교한 요소를 고려하여 지문으로 제시하고 있습니다. 
                            <br/>
                            <br/>
                            질문들은 참여자의 내적/외적 유형을 탐구하여 자연스럽게 일자리에 대해서 공감할 수 있는 모습을 발견하는 데 도움을 줍니다.
                            <br/>
                            <br/>
                            결과적으로 고객의 응답을 바탕으로 일자리 성향 유형은 256가지로 다양하게 표현되며, 
                            <br/>
                            참여자는 자신의 유형에 적합하며 경험과 가치관이 반영된 일자리에 대한 정보를 추천받을 수 있습니다.`,
                    img : {
                        pc : ilharuModalUser2,
                        m : ilharuModalUser2Small
                    },
                    imgWidth : {
                        pc : '',
                        m : '100%'
                    }
                },
                {
                    title : `결과 페이지`,
                    subCopy : `고객은 결과 페이지를 통하여 자신의 일자리 성향을 더욱 깊이 이해하고 일자리를 선택하기에 앞서 자신의 취향을 발견할 수 있도록 기업에서 제공하는 맞춤 직업을 추천받습니다.
                            <br/>
                            <br/>
                            유형에게 적합한 일자리는 직업별로 총 4가지로 추천받게되며, 직업을 선택하기에 앞서 고객과 적합한 유형을 추천하게 해주어 효과적으로 일자리를  찾을 수 있게 도움을 줍니다.
                            <br/>
                            <br/>
                            결과페이지는 고객이 자신의 유형을 이해하고 직업을 추천받으며 새로운 분야에 가능성을 제공하는 장점을 가진 모델입니다. 고객들은 자아를 실현하고 더불어 원하는 일자리를 구할 수 있는 긍정적인 효과도 기대할 수 있습니다.`,
                    img : {
                        pc : ilharuModalUser3,
                        m : ilharuModalUser3
                    },
                    bgColor : '#E3EEFF',
                    flexDirection: 'row',
                    alignItems: 'flex-start',
                    textAlign: 'left',
                    paddingTop : {
                        pc : '85px',
                        m : '37px'
                    },
                    margin: {
                        pc : '0 8%',
                        m : ''
                    }
                }
            ],
            admin : [
                { 
                    title : `사용자 관리 - 학생/선생님`,
                    subCopy : `SMTi-일자리 프로젝트의 관리자 페이지의 주 사용자는 선생님그룹과 학생그룹이며 기본적인 인적자원관리 기능으로 학생을 등록하고 멘토그룹을 지정하고 점수를 부여하는 기능을 제공하고 있습니다. 학생들은 자신의 아이디와 별칭과 가입일자, 최근 접속일 등을 통해서 익명성과 보안성을 보장받을 수 있습니다. 
                            <br/>
                            <br/>
                            선생님(관리자)은 학생들의 활동레벨과 완료일 점수 및 유형검사를 통해서 행태 데이터를 수집하며 자신이 관리하는 학생에 대해서 깊이 있는 정보를 제공받게 됩니다. 선생님은 상기 데이터를 바탕으로 학생들과 소통적 측면의 데이터와  관리적 측면의 데이터로 활용할 수 있습니다.`,
                    img : {
                        pc : ilharuModalAdmin1,
                        m : ilharuModalAdmin1
                    },
                    width : {
                        pc : '85%',
                        m : ''
                    },
                    alignItems : 'flex-start',
                    textAlign : 'left'
                },
                { 
                    title : `일정관리 - 캘린더/내역`,
                    subCopy : `일정관리 - 캘린더/내역 기능은 선생님그룹이 학생그룹을 관리하기 위한 일정 및 내역 구현을 목적으로 합니다.
                            <br/>
                            <br/>
                            캘린더 기능을 통해 선생님은 학생들의 일정을 추가, 수정, 삭제할 수 있습니다. 일정은 날짜, 시간, 제목, 설명 등의 정보를 포함하며, 선생님그룹과 학생그룹의 일정을 구분하여 표시하며 월간 형태로 일정을 확인할 수 있습니다.
                            <br/>
                            <br/>
                            내역 기능은 학생들의 활동 내역을 기록하고 관리하는 기능입니다. 선생님은 카테고리를 바탕으로 일정 타이틀을 지정하며 진행상태 및 종료일시를 파악할 수 있습니다. 이를 통해 선생님은 학생들의 참여 현황 데이터를 수집하고 분석할 수 있습니다. 학생들의 상세 정보와 활동 내역은 지속적인 관리에 활용할 수 있습니다.`,
                    img : {
                        pc : ilharuModalAdmin2,
                        m : ilharuModalAdmin2
                    },
                    width : {
                        pc : '85%',
                        m : ''
                    },
                    alignItems : 'flex-start',
                    textAlign : 'left'
                },
                { 
                    title : `자립계획서/활동일지 관리`,
                    subCopy : `자립계획서는 학생들이 스스로의 활동을 위해 자신이 설정한 목표와 계획을 관리하고 추진한 내용을 확인할 수 있는 문서입니다. 이를 통해 선생님은 학생들의 자립을 위해 설정한 목표와 그에 따른 구체적인 조치를 기록하고 관리할 수 있습니다.
                            <br/>
                            <br/>
                            구체적으로 자립 계획서를 파악한 선생님은 학생들이 원하는 희망서비스를 파악할 수 있고 자립계획서를 바탕으로 코멘트를 설정하며 지속적인 소통의 도구로 활용할 수 있는게 특징입니다.
                            <br/>
                            <br/>
                            활동일지에는 각 활동에 대한 일정 타이틀을 입력하며 활동이 진행된 날짜와 시간을 기록합니다. 이렇게 함으로써 해당 활동의 일자와 시간을 명확하게 파악할 수 있습니다. 학생들은 각 활동에 대한 내용을 자세히 작성합니다. 이는 해당 활동의 주요 내용이나 수행한 작업을 요약하여 기록할 수 있습니다. 
                            <br/>
                            <br/>
                            관리자나 선생님은 사용자의 활동에 대한 피드백이나 코멘트를 작성하며 이를 통해  학생들과 소통하며 활동에 대한 평가, 조언, 개선 사항 등을 확인할 수 있습니다. 이를 통해서 학생들의 일정 관리와 성과 평가, 개선 사항 도출에 도움을 주며 선생님의 코멘트를 통해 피드백을 받고 학생들은 성장의 확장성을 키우게 됩니다.`,
                    img : {
                        pc : ilharuModalAdmin3,
                        m : ilharuModalAdmin3Small
                    },
                    reverse: true,
                    flexDirection: 'row',
                    alignItems: 'flex-start',
                    textAlign: 'left',
                    imgWidth: {
                        pc : '45%',
                        m : '85%'
                    },
                    paddingTop : {
                        pc : '25px',
                        m : ''
                    },
                    margin: {
                        pc : '0 80px 0 0',
                        m : ''
                    }
                },
                { 
                    title : `가상공간(메타버스) 콘텐츠 관리`,
                    subCopy : `일정관리 - 캘린더/내역 기능은 선생님그룹이 학생그룹을 관리하기 위한 일정 및 내역 구현을 목적으로 합니다.
                            <br/>
                            <br/>
                            캘린더 기능을 통해 선생님은 학생들의 일정을 추가, 수정, 삭제할 수 있습니다. 일정은 날짜, 시간, 제목, 설명 등의 정보를 포함하며, 선생님그룹과 학생그룹의 일정을 구분하여 표시하며 월간 형태로 일정을 확인할 수 있습니다.
                            <br/>
                            <br/>
                            내역 기능은 학생들의 활동 내역을 기록하고 관리하는 기능입니다. 선생님은 카테고리를 바탕으로 일정 타이틀을 지정하며 진행상태 및 종료일시를 파악할 수 있습니다. 이를 통해 선생님은 학생들의 참여 현황 데이터를 수집하고 분석할 수 있습니다. 학생들의 상세 정보와 활동 내역은 지속적인 관리에 활용할 수 있습니다.`,
                    img : {
                        pc : ilharuModalAdmin4,
                        m : ilharuModalAdmin4
                    },
                    flexDirection: 'row',
                    alignItems: 'flex-start',
                    textAlign: 'left',
                    imgWidth: {
                        pc : '80%',
                        m : ''
                    },
                    paddingTop : {
                        pc : '57px',
                        m : ''
                    },
                    margin: {
                        pc : '0 20px 0 80px',
                        m : ''
                    }
                },
                { 
                    title : `자립계획서/활동일지 관리`,
                    subCopy : `자립계획서는 학생들이 스스로의 활동을 위해 자신이 설정한 목표와 계획을 관리하고 추진한 내용을 확인할 수 있는 문서입니다. 이를 통해 선생님은 학생들의 자립을 위해 설정한 목표와 그에 따른 구체적인 조치를 기록하고 관리할 수 있습니다.
                            <br/>
                            <br/>
                            구체적으로 자립 계획서를 파악한 선생님은 학생들이 원하는 희망서비스를 파악할 수 있고 자립계획서를 바탕으로 코멘트를 설정하며 지속적인 소통의 도구로 활용할 수 있는게 특징입니다.
                            <br/>
                            <br/>
                            활동일지에는 각 활동에 대한 일정 타이틀을 입력하며 활동이 진행된 날짜와 시간을 기록합니다. 이렇게 함으로써 해당 활동의 일자와 시간을 명확하게 파악할 수 있습니다. 학생들은 각 활동에 대한 내용을 자세히 작성합니다. 이는 해당 활동의 주요 내용이나 수행한 작업을 요약하여 기록할 수 있습니다. 
                            <br/>
                            <br/>
                            관리자나 선생님은 사용자의 활동에 대한 피드백이나 코멘트를 작성하며 이를 통해  학생들과 소통하며 활동에 대한 평가, 조언, 개선 사항 등을 확인할 수 있습니다. 이는 통해서 학생들의 일정 관리와 성과 평가, 개선 사항 도출에 도움을 주며 선생님의 코멘트를 통해 피드백을 받고 학생들은 성장의 확장성을 키우게 됩니다.`,
                    img : {
                        pc : ilharuModalAdmin5,
                        m : ilharuModalAdmin5
                    },
                    alignItems: 'flex-start',
                    textAlign: 'left'
                },
                { 
                    title : `유입통계`,
                    subCopy : `소금광산의 관리자 페이지는 정확한 데이터 현황을 파악하는 데 중점을 두고 있습니다. 이를 위해 유입통계는 방문횟수와 최초 방문 고객의 수, 페이지 체류 시간, 페이지 방문 수 등을 통하여 고객을 이해하고 데이터를 확인하는 데 도움을 줍니다.
                            <br/>
                            <br/>
                            세부적으로는 보유 고객의 분포율을 기반으로 16개의 문답을 통해 수집된 유형의 분포 비율을 분석하여 일자리를 바라보는 보유 고객의 유형 분포도와, 타겟 고객의 유형을 직관적으로 파악할 수 있는 데이터를 제공합니다.
                            <br/>
                            <br/>
                            이를 통해 대시보드는 고객 유형의 분포를 분석하고, 각 유형의 증감 추이를 통해 타겟 고객에 대한 효과적인 분석 결과를 제공합니다. 전체적인 통계 데이터를 시각적으로 표현하여 사용자 데이터를 실시간으로 제공하며, 이를 기반으로 서비스의 성과를 모니터링할 수 있습니다.`,
                    img : {
                        pc : ilharuModalAdmin6,
                        m : ilharuModalAdmin6Small
                    },
                    alignItems: 'flex-start',
                    textAlign: 'left'
                }
            ]
            
        },
        {
            logo: homeTravelLogo,
            logoSizeLarge : { width: '115px', height: '93px' },
            logoSizeSmall : { width: '46px', height: '38px' },
            title : `스트레스 해소 성향 알아보기`,
            company : `성남시 꿈드림 일하루`,
            project : `스트레스 해소 성향 알아보기`,
            period : `23.08.10. ~ 23.11.30.`,
            user : [
                {
                    title : `Over view`,
                    subCopy : `SMTi-스트레스 해소 성향 검사는 스트레스를 풀기에 앞서 고객의 특성 및 선호도, 취향, 성격 등의 다양한 측면을 유형에 적합하게 탐색하여 보유 고객의 유형을 바탕으로 효과적인 스트레스 해소 방법을 파악 할 수 있도록 설계되었습니다.
                            <br/>
                            <br/>
                            문답을 통하여 도출된 유형은 고객의 성향을 나타내며, 스트레스 해소에 영향을 미치는 요소를 포함시키고 있습니다. 또한 유형에 따라 고객에게 적합한 스트레스 해소 방법을 추천하게 되는 과정에서 유형을 고려하여 스트레스 해소 제품을 추천하게 됩니다. 
                            <br/>
                            <br/>
                            이를 통해 고객들은 자신에게 맞는 상품을 더욱 정확하게 제안 받을 수 있으며, 개인적인 성향과 스타일을 반영한 스트레스 해소 경험을 할 수 있습니다. 결론적으로 SMTi 유형 검사를 통해서 고객들은 보다 개인화된 스트레스 해소 경험을 할 수 있습니다.`,
                    img : {
                        pc : homeTravelModalUser1,
                        m : homeTravelModalUser1Small
                    } 
                },
                {
                    title : `질의 응답`,
                    subCopy : `SMTi-스트레스 해소 성향 검사의 질의 응답은 16문답을 고객에게 제시하여 자신의 스트레스 성향을 알 수 있도록 돕고 있습니다. 
                            <br/>
                            <br/>
                            문답은 스트레스에 대한 참여자의 개성과 특징을 섬세하게 파악하기 위해 정교한 요소를 고려하여 지문으로 제시하고 있습니다. 
                            <br/>
                            <br/>
                            질문들은 참여자의 내적/외적 유형을 탐구하여 자연스럽게 스트레스 해소에 대해 공감할 수 있는 모습을 발견하는 데 도움을 줍니다.
                            <br/>
                            <br/>
                            결과적으로 고객의 응답을 바탕으로 사용자의 스트레스 해소 유형은 256가지로 다양하게 표현되며,
                            <br/>
                            참여자는 자신의 유형에 적합하며 경험과 가치관이 반영된 스트레스 해소 방법을 추천받을 수 있습니다.`,
                    img : {
                        pc : homeTravelModalUser2,
                        m : homeTravelModalUser2Small
                    },
                    imgWidth : {
                        pc : '',
                        m : '100%'
                    }
                },
                {
                    title : `결과 페이지`,
                    subCopy : `고객은 결과 페이지를 통하여 자신의  스트레스 해소 성향을 더욱 깊이 이해하고, 스트레스 해소 취향을 발견할 수 있도록 기업에서 제공하는 맞춤 상품을 추천받습니다.
                            <br/>
                            <br/>
                            활성 빈도가 높은 사용자에게는 데이터 누적 빈도 및 횟수를 기반으로 타겟 마케팅이 제공됩니다. 이를 바탕으로 SNS 공유 이벤트 참여 기회와 리워드를 제공하게 됩니다.
                            <br/>
                            <br/>
                            결과 페이지에서는 고객 맞춤형 자사 제품을 제공하여 고객 만족도를 높이고, 이를 통해 고객 유지력을 강화합니다. 더불어 제공된 제품군을 통해 구매 전환율을 높이는 긍정적인 효과도 기대할 수 있습니다.`,
                    img : {
                        pc : homeTravelModalUser3,
                        m : homeTravelModalUser3
                    },
                    bgColor : '#F5D4FF',
                    flexDirection: 'row',
                    alignItems: 'flex-start',
                    textAlign: 'left',
                    paddingTop : {
                        pc : '85px',
                        m : '37px'
                    },
                    margin: {
                        pc : '0 8%',
                        m : ''
                    }
                }
            ],
            admin : [
                { 
                    title : `유입통계 분석`,
                    subCopy : `콘텐츠 분석 카테고리는 Small Data를 파악하기 위한 콘텐츠를 제공하는데 초점을 맞추고 있습니다. 유입통계의 영역은 보유 고객의 유형을 집중적으로 분석하여 다양한 정보를 제공합니다.
                            <br/>
                            <br/>
                            C/A 유형별 분포도를 통하여 문답에 참여한 고객의 유형을 비율로 제공하며, 각 유형의 특징을 세부적이고 직관적으로 파악할 수 있도록 표현하고 있습니다. 이를 통해 고객들의 스트레스 해소 선호도와 패턴을 더욱 정확하게 이해할 수 있습니다.
                            <br/>
                            <br/>
                            또한, 제품 유입 랭킹을 통해 서비스를 이용하는 고객의 타겟 키워드를 파악할 수 있으며, 이를 바탕으로 핵심적인 콘텐츠 제작과 마케팅 전략 수립에 도움을 줄 수 있습니다.`,
                    img : {
                        pc : homeTravelModalAdmin1,
                        m : homeTravelModalAdmin1
                    },
                    width : {
                        pc : '85%',
                        m : ''
                    },
                    alignItems: 'flex-start',
                    textAlign: 'left'
                },
                { 
                    title : `대시보드`,
                    subCopy : `소금광산의 관리자 페이지는 정확한 데이터 현황을 파악하는 데 중점을 두고 있습니다. 이를 위해 대시보드는 사용자의 전체 참여 수를 기반으로 일일 참여자, 신규 참여자, 공유 참여자의 등락폭을 분석하여 사용자의 참여 활성화 비율 및 유의미한 사용자 비율을 확인하는 데 도움을 줍니다.
                            <br/>
                            <br/>
                            세부적으로는 보유 고객의 분포율을 기반으로 16개의 문답을 통해 수집된 유형의 분포 비율을 분석하여 스트레스를 바라보는 보유 고객의 유형 분포도와, 타겟 고객의 유형을 직관적으로 파악할 수 있는 데이터를 제공합니다.
                            <br/>
                            <br/>
                            이를 통해 대시보드는 고객 유형의 분포를 분석하고, 각 유형의 증감 추이를 통해 타겟 고객에 대한 효과적인 분석 결과를 제공합니다. 전체적인 통계 데이터를 시각적으로 표현하여 사용자 데이터를 실시간으로 제공하며, 이를 기반으로 서비스의 성과를 모니터링할 수 있습니다.`,
                    img : {
                        pc : homeTravelModalAdmin2,
                        m : homeTravelModalAdmin2Small
                    },
                    width : {
                        pc : '85%',
                        m : ''
                    },
                    alignItems: 'flex-start',
                    textAlign: 'left'
                },
                { 
                    title : `사용자 데이터 분석`,
                    subCopy : `수집된 데이터는 사용자 참여 현황을 분석하여 전체 참여자 수, 일일 참여자 수, 신규 및 공유 참여자 수 등의 핵심 데이터 변동률을 파악합니다. 이를 바탕으로 빅데이터의 관리 측면에서의 결점을 정확하게 파악하고 대응할 수 있으며, 참여율, 참여 비율, 전주 대비 유저 증감률 등을 통해 활동 고객의 행태 데이터를 파악합니다. 
                            <br/>
                            <br/>
                            상기의 분석을 통해 도출된 스몰 데이터는 기업과 고객의 관계를 긍정적으로 개선하며, 개선된 서비스를 제공하기 위해서 효율적인 정보를 제공하며, 고객과의 더 깊은 소통을 통해 지속적인 성장에 점진적으로 개선되는 지표를 제공하는 것이 소금광산의 목표입니다.`,
                    img : {
                        pc : homeTravelModalAdmin3,
                        m : homeTravelModalAdmin3Small
                    },
                    width : {
                        pc : '85%',
                        m : ''
                    },
                    bgColor : '##8FDF9',
                    alignItems: 'flex-start',
                    textAlign: 'left'
                }
            ]
        },
        {
            logo : flasierLogo,
            logoSizeLarge : { width: '162px', height: '58px' },
            logoSizeSmall : { width: '83px', height: '30px' },
            title : `내 가구 구매 성향 알아보기`,
            company : `주식회사 오빅쉐어`,
            project : `내 가구 구매 성향 알아보기`,
            period : `23.05. ~ 23.07.`,
            user : [
                {
                    title : `Over view`,
                    subCopy : `SMTi - 가구 성향 검사는 칼 융의 인지심리학을 바탕으로 개발되었습니다.<br/>
                            고객은 가구 구매 성향 검사에서 자신의 선호도와 스타일에 맞는 가구를 추천 받을 수 있습니다.<br/>
                            데이터 모형 및 심리학적 연구를 기반으로 고객의 취향을 파악하고,<br/>
                            고객의 요구를 바탕으로 유형에 적합한 가구 데이터를 제공합니다.`,
                    img : {
                        pc : falsierModalUser1,
                        m : falsierModalUser1Samll
                    } 
                },
                {
                    title : `질의 응답`,
                    subCopy : `문답은 총 16가지의 정교한 질문으로 사용자의 가구 개성과 특징을 섬세하게 파악하는 도구이며, 질문은 각기 다른 측면을 탐구하여<br/>
                            사용자가 자연스럽게 공감가능한 모습을 발견하는 데 도움을 줍니다.<br/>
                            소금광산은 특정 유형에 국한되지 않고, 고객의 다양성과 독특성을 중시합니다. 저희의 유형 문답의 목표는<br/>
                            “고객이 스스로의 문답을 통한 새로운 가능성을 발견하게 돕는 것”입니다.<br/>
                            결과적으로 사용자의 유형은 256가지로 다양하게 표현되어, 각 개인의 경험과 가치관을 반영함으로써<br/>
                            개개인의 특성을 더 잘 이해할 수 있도록 도도와줍니다.`,
                    img : {
                        pc : falsierModalUser2,
                        m : falsierModalUser2Small
                    },
                    imgWidth : {
                        pc : '',
                        m : '100%'
                    }
                },
                {
                    title : `결과 페이지`,
                    subCopy : `고객은 결과 페이지를 통하여 자신의 가구 구매 성향을 더욱 깊이 이해하고, 가구 취향을 발견할 수 있도록 기업에서 제공하는 맞춤 상품을 추천받습니다.<br/>
                            활성 빈도가 높은 사용자에게는 데이터 누적 빈도 및 횟수를 기반으로 타겟 마케팅이 제공됩니다. 이를 바탕으로 SNS 공유 이벤트 참여 기회와 리워드를 제공하게 됩니다.<br/>
                            결과 페이지에서는 고객 맞춤형 자사 제품을 제공하여 고객 만족도를 높이고, 이를 통해 고객 유지력을 강화합니다. 더불어 제공된 제품군을 통해 구매 전환율을 높이는 긍정적인 효과도 기대할 수 있습니다.`,
                    img : {
                        pc : falsierModalUser3,
                        m : falsierModalUser3Small
                    },
                    bgColor : '#E7F4F2',
                    flexDirection: 'row',
                    alignItems: 'flex-start',
                    textAlign: 'left',
                    imgWidth : {
                        pc : '',
                        m : '100%'
                    },
                    paddingTop : {
                        pc : '85px',
                        m : '37px'
                    },
                    margin: {
                        pc : '0 8%',
                        m : ''
                    }
                }
            ],
            admin: [
                {
                    title : `대시보드`,
                    subCopy : `소금광산의 관리자 페이지는 정확한 고객 분석에 중점을 두고 있습니다. 16문답을 통하여 수집된 데이터를 활용하여 
                            고객의 선호도와 행동 패턴을 효과적으로 파악합니다.<br/>
                            대시보드는 고객 유형의 분포를 분석하고, 각 유형의 증감폭을 통해 타겟 고객에 대한 효과적인 분석을 제공합소금광산의 관리자 페이지는 정확한 데이터 현황을 파악하는 데 중점을 두고 있습니다. 이를 위해 대시보드는 사용자의 전체 참여 수를 기반으로 일일 참여자, 신규 참여자, 공유 참여자의 등락폭을 분석하여 사용자의 참여 활성화 비율 및 유의미한 사용자 비율을 확인하는 데 도움을 줍니다.
                            <br/>
                            <br/>
                            세부적으로는 보유 고객의 분포율을 기반으로 16개의 문답을 통해 수집된 유형의 분포 비율을 분석하여 가구를 바라보는 보유 고객의 유형 분포도와, 타겟 고객의 유형을 직관적으로 파악할 수 있는 데이터를 제공합니다.
                            <br/>
                            <br/>
                            이를 통해 대시보드는 고객 유형의 분포를 분석하고, 각 유형의 증감 추이를 통해 타겟 고객에 대한 효과적인 분석 결과를 제공합니다. 전체적인 통계 데이터를 시각적으로 표현하여 사용자 데이터를 실시간으로 제공하며, 이를 기반으로 서비스의 성과를 모니터링할 수 있습니다.니다. 전체적인 통계 데이터를 활용해 사용자 데이터를 시각적으로 제공하며, 이를 바탕으로 서비스 성과를 실시간으로 모니터링할 수 있습니다.`,
                    img : {
                        pc : falsierModalAdmin1,
                        m : falsierModalAdmin1
                    },
                    width : {
                        pc : '85%',
                        m : ''
                    },
                    alignItems: 'flex-start',
                    textAlign: 'left'
                },
                {
                    title : `사용자 데이터 분석`,
                    subCopy : `수집된 데이터는 사용자 참여 현황을 분석하여 전체 참여자 수, 일일 참여자 수, 신규 및 공유 참여자 수 등의 핵심 데이터 변동률을 파악합니다. 이를 바탕으로 빅데이터의 관리 측면에서의 결점을 정확하게 파악하고 대응할 수 있으며, 참여율, 참여 비율, 전주 대비 유저 증감률 등을 통해 활동 고객의 행태 데이터를 파악합니다. 
                            <br/>
                            <br/>
                            상기의 분석을 통해 도출된 스몰 데이터는 기업과 고객의 관계를 긍정적으로 개선하며, 개선된 서비스를 제공하기 위해서 효율적인 정보를 제공하며, 고객과의 더 깊은 소통을 통해 지속적인 성장에 점진적으로 개선되는 지표를 제공하는 것이 소금광산의 목표입니다.`,
                    img : {
                        pc : falsierModalAdmin2,
                        m : falsierModalAdmin2
                    },
                    width : {
                        pc : '85%',
                        m : ''
                    },
                    alignItems: 'flex-start',
                    textAlign: 'left'
                },
                {
                    title : `콘텐츠 데이터 분석`,
                    subCopy : `콘텐츠 분석 카테고리는 Small Data를 파악하기 위한 콘텐츠를 제공하는데 초점을 맞추고 있습니다. 해당 페이지에서는 보유 고객의 유형을 집중적으로 분석하여 다양한 정보를 제공합니다.
                            <br/>
                            <br/>
                            우선 문답에 참여한 고객의 유형을 분포 비율로 제공하며, 각 유형의 특징을 세부적이고 직관적으로 파악할 수 있도록 표현하고 있습니다. 이를 통해 고객들의 가구 선호도와 행동 패턴을 더욱 정확하게 이해할 수 있습니다.
                            <br/>
                            <br/>
                            또한, 키워드 맵을 통해 서비스를 이용하는 고객의 타겟 키워드를 파악할 수 있으며, 이를 바탕으로 핵심적인 콘텐츠 제작과 마케팅 전략 수립에 도움을 줄 수 있습니다.`,
                    img : {
                        pc : falsierModalAdmin3,
                        m : falsierModalAdmin3Small
                    },
                    width : {
                        pc : '85%',
                        m : ''
                    },
                    alignItems: 'flex-start',
                    textAlign: 'left'
                },
            ]
        },
        {
            logo : wooAhYeahLogo,
            logoSizeLarge : { width: '214px', height: '72px' },
            logoSizeSmall : { width: '126px', height: '42px' },
            title : `우리 아이 놀이 성향 검사`,
            company : `주식회사 신세계 - 우아예`,
            project : `우리 아이 놀이 성향 검사`,
            period : `23.03. ~ 23.06.`,
            user : [
                {
                    title : `Over view`,
                    subCopy : `SMTi-우리 아이 놀이 성향 검사는 아이의 특성과 성향을 고려하여 선호도, 취향, 성격 특성 등의 다양한 측면을 유형에 적합하게 적용하여 아이들의 유형을 바탕으로 선호 놀이 성향을 파악 할 수 있도록 설계되었습니다.
                            <br/>
                            <br/>
                            문답을 통하여 도출된 유형은 아이들의 성향을 나타내며, 놀이에 영향을 미치는 요소를 포함시키고 있습니다. 또한 유형에 따라 아이들에게 적합한 놀이를 추천하게 되는 과정에서 유형을 고려하여 놀이를 추천하게 됩니다. 
                            <br/>
                            <br/>
                            이를 통해 아이들은 자신에게 맞는 놀이를 더욱 정확하게 제안 받을 수 있으며, 개인적인 성향과 스타일을 반영한 놀이 경험을 할 수 있습니다. 결론적으로 SMTi 유형 검사를 통해서 아이들은 보다 개인화된 놀이 경험을 할 수 있습니다.`,
                    img : {
                        pc : wooAhYeahModalUser1,
                        m : wooAhYeahModalUser1Small
                    },
                    flexDirection: 'row',
                    alignItems: 'flex-start',
                    textAlign: 'left',
                    margin: {
                        pc : '0 4%',
                        m : ''
                    }
                },
                {
                    title : `질의 응답`,
                    subCopy : `SMTi-스트레스 해소 성향 검사는 16문답을 고객에게 제시하여 자신의 스트레스 해소 유형을 알 수 있도록 돕고 있습니다. 
                            <br/>
                            <br/>
                            문답은 스트레스 해소에 대한 개성과 특징을 섬세하게 파악하기 위 해 정교한 요소를 고려하여 제시되고 있습니다. 질문들은 고객의 내적/외적 유형을 탐구하여 고객이 자연스럽게 스트레스 해소에 대해서 공감할 수 있는 모습을 발견하는 데 도움을 줍니다.
                            <br/>
                            <br/>
                            결과적으로 고객의 응답을 바탕으로 사용자의 스트레스 해소 유형은 256가지로 다양하게 표현되며, 각 개인은 자신의 유형에 적합하며 경험과 가치관이 반영된 스트레스 해소 방법을 추천받을 수 있습니다.`,

                },
                {
                    title : `결과 페이지`,
                    subCopy : `아이들은 결과 페이지를 통하여 자신의 놀이 성향을 더욱 깊이 이해하고, 놀이 취향을 발견할 수 있도록 기업에서 제공하는 맞춤 놀이들을 추천받습니다.
                            <br/>
                            <br/>
                            활성 빈도가 높은 사용자에게는 데이터 누적 빈도 및 횟수를 기반으로 타겟 놀이들이 제공됩니다. 
                            결과 페이지에서는 아이 맞춤형 놀이들을 제공하여 고객 만족도를 높이고, 이를 통해 고객 유지력을 강화합니다. 더불어 제공된 놀이들을 통해 실제 오프라인 수업으로의 전환율을 높이는 긍정적인 효과도 기대할 수 있습니다.`,
                    img : {
                        pc : wooAhYeahModalUser3,
                        m : wooAhYeahModalUser3Small
                    },
                    bgColor : '#E8FDF9',
                    imgWidth : {
                        pc : '',
                        m : '100%'
                    },
                    paddingTop : {
                        pc : '72px',
                        m : '25px'
                    }
                }
            ],
            admin : [
                { 
                    title : `대시보드`,
                    subCopy : `소금광산의 관리자 페이지는 정확한 데이터 현황을 파악하는 데 중점을 두고 있습니다. 이를 위해 대시보드는 사용자의 전체 참여 수를 기반으로 일일 참여자, 신규 참여자, 공유 참여자의 등락폭을 분석하여 사용자의 참여 활성화 비율 및 유의미한 아이들 비율을 확인하는 데 도움을 줍니다.
                            <br/>
                            <br/>
                            세부적으로는 아이들의 분포율을 기반으로 16개의 문답을 통해 수집된 유형의 분포 비율을 분석하여 놀이를 바라보는 아이들의 유형 분포도와, 시장의 분포도가 높은 아이들의 유형을 직관적으로 파악할 수 있는 데이터를 제공합니다.
                            <br/>
                            <br/>
                            이를 통해 대시보드는 아이들의 유형의 분포를 분석하고, 각 유형의 증감 추이를 통해 타겟 아이들에 대한 효과적인 분석 결과를 제공합니다. 전체적인 통계 데이터를 시각적으로 표현하여 사용자 데이터를 실시간으로 제공하며, 이를 기반으로 서비스의 성과를 모니터링할 수 있습니다.`,
                    img : {
                        pc : wooAhYeahModalAdmin1,
                        m : wooAhYeahModalAdmin1
                    },
                    width : {
                        pc : '85%',
                        m : ''
                    },
                    alignItems: 'flex-start',
                    textAlign: 'left'
                },
                { 
                    title : `사용자 데이터 분석`,
                    subCopy : `수집된 데이터는 사용자 참여 현황을 분석하여 전체 참여자 수, 일일 참여자 수, 신규 및 공유 참여자 수 등의 핵심 데이터 변동률을 파악합니다. 이를 바탕으로 빅데이터의 관리 측면에서의 결점을 정확하게 파악하고 대응할 수 있으며, 참여율, 참여 비율, 전주 대비 유저 증감률 등을 통해 아이들의 행태 데이터를 파악합니다. 
                            <br/>
                            <br/>
                            상기의 분석을 통해 도출된 스몰 데이터는 선생님 혹은 학부모와 아이들의 관계를 긍정적으로 개선하며, 개선된 서비스를 제공하기 위해서 효율적인 정보를 제공하며, 아이들과의 더 깊은 소통을 통해 지속적인 성장에 점진적으로 개선되는 지표를 제공하는 것이 소금광산의 목표입니다.`,
                    img : {
                        pc : wooAhYeahModalAdmin2,
                        m : wooAhYeahModalAdmin2Small
                    },
                    width : {
                        pc : '85%',
                        m : ''
                    },
                    alignItems: 'flex-start',
                    textAlign: 'left'
                },
                { 
                    title : `콘텐츠 데이터 분석`,
                    subCopy : `콘텐츠 분석 카테고리는 Small Data를 파악하기 위한 콘텐츠를 제공하는데 초점을 맞추고 있습니다. 해당 페이지에서는 아이들의 유형을 집중적으로 분석하여 다양한 정보를 제공합니다.
                            <br/>
                            <br/>
                            우선 문답에 참여한 아이들의 유형을 분포 비율로 제공하며, 각 유형의 특징을 세부적이고 직관적으로 파악할 수 있도록 표현하고 있습니다. 이를 통해 아이들의 놀이 선호도와 행동 패턴을 더욱 정확하게 이해할 수 있습니다.
                            <br/>
                            <br/>
                            또한, 랭킹 분포를 통해 서비스를 이용하는 아이들의  타겟 키워드를 파악할 수 있으며, 이를 바탕으로 핵심적인 콘텐츠 제작과 마케팅 전략 수립에 도움을 줄 수 있습니다.`,
                    img : {
                        pc : wooAhYeahModalAdmin3,
                        m : wooAhYeahModalAdmin3Small
                    },
                    width : {
                        pc : '85%',
                        m : ''
                    },
                    alignItems: 'flex-start',
                    textAlign: 'left'
                }
            ]
        }

    ],
    
    eng : [
        {
            logo : ilharuLogo,
            logoSizeLarge : { width: '239px', height: '71px' },
            logoSizeSmall : { width: '146px', height: '31px' },
            title : `Explore Job Preferences`,
            company : `Seongnam-si Ilharu`,
            project : `Explore Job Preferences`,
            period : `23.08.10. ~ 23.11.30.`,
            user : [
                {
                    title : `Over view`,
                    subCopy : `The SMTi Job Preference Test is designed to identify preferred jobs based on customer personality types, exploring various aspects like preferences, tastes, and personality traits in a way that aligns with career characteristics.
                            <br/>
                            <br/>
                            By examining different job aspects through questions, it reflects customers' personalities and includes factors that significantly influence job selection. This process considers the individual's type to recommend suitable jobs based on their unique characteristics. 
                            <br/>
                            <br/>
                            Through this, customers receive more accurate job suggestions that fit their personal tendencies and style. Ultimately, the SMTi type test enables a more personalized job selection experience.`,
                    img : {
                        pc : ilharuModalUser1,
                        m : ilharuModalUser1Small
                    } 
                },
                {
                    title : `Q&A`,
                    subCopy : `SMTi - The Job Personality Test presents 16 carefully crafted questions to help individuals discover their job preferences and personality traits.
                            <br/>
                            <br/>
                            The questions are designed with meticulous attention to detail, offering statements that capture the participant's unique characteristics and traits regarding their approach to work.
                            <br/>
                            <br/>
                            By exploring both internal and external dimensions, the questions aim to uncover insights that allow participants to naturally connect with jobs they can resonate with.
                            <br/>
                            <br/>
                            Based on participants' responses, job personality types are categorized into 256 distinct profiles.
                            <br/>
                            This allows individuals to receive tailored job recommendations that align with their personality, experiences, and values.`,
                    img : {
                        pc : ilharuModalUser1,
                        m : ilharuModalUser1Small
                    },
                    imgWidth : {
                        pc : '',
                        m : '100%'
                    }
                },
                {
                    title : `Results Page`,
                    subCopy : `Suitable Job Types are recommended in a total of four options for each customer type, allowing customers to select the best match effectively.
                            <br/>
                            <br/>
                            This model enables customers to understand their type, receive job recommendations, and explore new fields.
                            <br/>
                            <br/>
                            Ultimately providing a positive effect by helping them achieve self-actualization and find desired jobs.`,
                    img : {
                        pc : ilharuModalUser3,
                        m : ilharuModalUser3
                    },
                    bgColor : '#E3EEFF',
                    flexDirection: 'row',
                    alignItems: 'flex-start',
                    textAlign: 'left',
                    paddingTop : {
                        pc : '85px',
                        m : '37px'
                    },
                    margin: {
                        pc : '0 8%',
                        m : ''
                    }
                }
            ],
            admin : [
                { 
                    title : `User Management - Students/Teachers`,
                    subCopy : `The main users of the SMTi Job Project’s Admin Page are teachers and students. The page includes essential human resource management functions that allow teachers to register students, assign them to mentor groups, and provide scores. Students are assured anonymity and security through unique IDs, nicknames, registration dates, and recent login records. 
                            <br/>
                            <br/>
                            Teachers (administrators) collect behavioral data on students’ activity levels, completion dates, and type tests, gaining in-depth information about the students they manage. This data enables teachers to use it both for communication and for management purposes.`,
                    img : {
                        pc : ilharuModalAdmin1,
                        m : ilharuModalAdmin1
                    },
                    width : {
                        pc : '85%',
                        m : ''
                    },
                    alignItems : 'flex-start',
                    textAlign : 'left'
                },
                { 
                    title : `Schedule Management - Calendar/Logs`,
                    subCopy : `The Calendar/Log function in Schedule Management is intended to help teachers manage the schedules and logs of student groups.
                            <br/>
                            <br/>
                            Using the calendar function, teachers can add, edit, or delete student schedules, which include information such as date, time, title, and description. The schedules are displayed separately for teachers and students, and teachers can view schedules in a monthly format.
                            <br/>
                            <br/>
                            The log function records and manages students’ activity histories. Teachers can assign titles to activities based on categories and track their status and completion dates. This allows teachers to collect and analyze data on student participation. Detailed student information and activity logs can be used for continuous management.`,
                    img : {
                        pc : ilharuModalAdmin2,
                        m : ilharuModalAdmin2
                    },
                    width : {
                        pc : '85%',
                        m : ''
                    },
                    alignItems : 'flex-start',
                    textAlign : 'left'
                },
                { 
                    title : `Financial freedom plan/ Activity log management`,
                    subCopy : `The Independence Plan is a document in which students manage their goals and plans for their activities and track their progress. This allows teachers to record and manage students' set goals and specific actions taken for independence.
                            <br/>
                            <br/>
                            Teachers reviewing independence plans can identify services students wish to pursue, set comments based on the plan, and use it as a continuous communication tool.
                            <br/>
                            <br/>
                            The Activity Log includes titles for each activity, along with the date and time of occurrence, ensuring a clear record of when activities took place. Students are encouraged to document each activity in detail, summarizing the main content or tasks performed. 
                            <br/>
                            <br/>
                            Administrators or teachers can provide feedback or comments on user activities, allowing communication with students and facilitating evaluations, advice, and improvement suggestions for their activities. This helps in managing schedules, assessing performance, identifying areas for improvement, and enhancing growth through teacher feedback.`,
                    img : {
                        pc : ilharuModalAdmin3,
                        m : ilharuModalAdmin3Small
                    },
                    reverse: true,
                    flexDirection: 'row',
                    alignItems: 'flex-start',
                    textAlign: 'left',
                    imgWidth: {
                        pc : '45%',
                        m : '85%'
                    },
                    paddingTop : {
                        pc : '25px',
                        m : ''
                    },
                    margin: {
                        pc : '0 80px 0 0',
                        m : ''
                    }
                },
                { 
                    title : `Virtual Space (Metaverse) Content Management`,
                    subCopy : `The Calendar/Log Management function is designed to allow the teacher group to manage the schedules and logs of the student group.
                            <br/>
                            <br/>
                            Through the calendar function, teachers can add, edit, or delete student schedules, which include date, time, title, and description information. Schedules are displayed separately for teachers and students, with a monthly view available.
                            <br/>
                            <br/>
                            The log function records and manages students' activity histories. Teachers assign titles based on categories and track progress and completion times. This enables teachers to gather and analyze data on students' participation. Detailed information on students and their activity histories can be used for ongoing management.`,
                    img : {
                        pc : ilharuModalAdmin4,
                        m : ilharuModalAdmin4
                    },
                    flexDirection: 'row',
                    alignItems: 'flex-start',
                    textAlign: 'left',
                    imgWidth: {
                        pc : '80%',
                        m : ''
                    },
                    paddingTop : {
                        pc : '57px',
                        m : ''
                    },
                    margin: {
                        pc : '0 20px 0 80px',
                        m : ''
                    }
                },
                { 
                    title : `Financial freedom plan/ Activity log management`,
                    subCopy : `The Independence Plan is a document in which students manage their goals and plans for their activities and track their progress. This allows teachers to record and manage students' set goals and specific actions taken for independence.
                            <br/>
                            <br/>
                            Teachers reviewing independence plans can identify services students wish to pursue, set comments based on the plan, and use it as a continuous communication tool.
                            <br/>
                            <br/>
                            The Activity Log includes titles for each activity, along with the date and time of occurrence, ensuring a clear record of when activities took place. Students are encouraged to document each activity in detail, summarizing the main content or tasks performed. 
                            <br/>
                            <br/>
                            Administrators or teachers can provide feedback or comments on user activities, allowing communication with students and facilitating evaluations, advice, and improvement suggestions for their activities. This helps in managing schedules, assessing performance, identifying areas for improvement, and enhancing growth through teacher feedback.`,
                    img : {
                        pc : ilharuModalAdmin5,
                        m : ilharuModalAdmin5
                    },
                    alignItems: 'flex-start',
                    textAlign: 'left'
                },
                { 
                    title : `User Influx Statistics`,
                    subCopy : `Our admin page focuses on accurately understanding data status. User influx statistics help in understanding customer behavior and verifying data by tracking visit frequency, the number of first-time visitors, time spent on pages, and page visits.
                            <br/>
                            <br/>
                            Specifically, distribution rates of existing customers are analyzed using 16 survey questions, identifying customer type distribution related to job preferences and providing data for intuitively understanding target customer types.
                            <br/>
                            <br/>
                            Through this, the dashboard analyzes customer type distribution and provides effective analysis results on target customers by tracking type trends. Visual representation of comprehensive statistics allows real-time monitoring of user data and service performance.`,
                    img : {
                        pc : ilharuModalAdmin6,
                        m : ilharuModalAdmin6Small
                    },
                    alignItems: 'flex-start',
                    textAlign: 'left'
                }
            ]
            
        },
        {
            logo: homeTravelLogo,
            logoSizeLarge : { width: '115px', height: '93px' },
            logoSizeSmall : { width: '46px', height: '38px' },
            title : `Explore Stress Relief Preferences`,
            company : `Seongnam-si Ilharu`,
            project : `Explore Stress Relief Preferences`,
            period : `23.08.10. ~ 23.11.30.`,
            user : [
                {
                    title : `Over view`,
                    subCopy : `The SMTi Stress Relief Preference Test is designed to identify effective stress relief methods based on customer personality types. It explores various aspects like preferences, tastes, and personality traits, tailoring recommendations to each type for effective stress management.
                            <br/>
                            <br/>
                            The type identified through questions reflects customer personality, including factors influencing stress relief. The test considers individual types when recommending suitable stress relief methods and products. 
                            <br/>
                            <br/>
                            This approach allows customers to receive more accurate product recommendations that align with their personality and style, creating a personalized stress relief experience. Ultimately, the SMTi type test provides a more individualized stress relief experience.`,
                    img : {
                        pc : homeTravelModalUser1,
                        m : homeTravelModalUser1Small
                    } 
                },
                {
                    title : `Q&A`,
                    subCopy : `SMTi - The Stress Relief Personality Test presents 16 thoughtfully designed questions to help individuals discover their stress coping preferences and tendencies.
                            <br/>
                            <br/>
                            The questions are carefully crafted to capture the unique characteristics and traits of participants regarding how they handle stress, considering detailed and refined elements.
                            <br/>
                            <br/>
                            By exploring both internal and external dimensions, the questions aim to help participants identify natural and relatable ways to address and manage stress.
                            <br/>
                            <br/>
                            Based on participants' responses, stress relief personality types are categorized into 256 distinct profiles.
                            <br/>
                            This allows individuals to receive tailored recommendations for stress relief methods that align with their personality, experiences, and values.`,
                    img : {
                        pc : homeTravelModalUser2,
                        m : homeTravelModalUser2Small
                    },
                    imgWidth : {
                        pc : '',
                        m : '100%'
                    }
                },
                {
                    title : `Result Page`,
                    subCopy : `Through the results page, customers gain a deeper understanding of their stress relief preferences and receive tailored product recommendations from the company.
                            <br/>
                            <br/>
                            For highly active users, targeted marketing is provided based on cumulative data frequency and engagement, offering opportunities for SNS sharing events and rewards.
                            <br/>
                            <br/>
                            The results page offers personalized in-house products that increase customer satisfaction, enhance retention, and positively impact conversion rates by boosting product sales.`,
                    img : {
                        pc : homeTravelModalUser3,
                        m : homeTravelModalUser3
                    },
                    bgColor : '#F5D4FF',
                    flexDirection: 'row',
                    alignItems: 'flex-start',
                    textAlign: 'left',
                    paddingTop : {
                        pc : '85px',
                        m : '37px'
                    },
                    margin: {
                        pc : '0 8%',
                        m : ''
                    }
                }
            ],
            admin : [
                { 
                    title : `User Influx Analysis`,
                    subCopy : `The Content Analysis Category focuses on providing content to identify Small Data. The User Influx Analysis area primarily analyzes the types of existing customers, offering various insights.
                            <br/>
                            <br/>
                            Through C/A type distribution, the types of customers participating in the questionnaire are shown in ratios, allowing detailed and intuitive understanding of each type’s characteristics. This enables a more accurate comprehension of customers’ stress relief preferences and patterns.
                            <br/>
                            <br/>
                            Additionally, the product influx ranking identifies target keywords for customers using the service, which helps in the development of essential content and marketing strategy formulation.`,
                    img : {
                        pc : homeTravelModalAdmin1,
                        m : homeTravelModalAdmin1
                    },
                    width : {
                        pc : '85%',
                        m : ''
                    },
                    alignItems: 'flex-start',
                    textAlign: 'left'
                },
                { 
                    title : `Dashboard`,
                    subCopy : `Our admin page focuses on accurately understanding data status. To this end, the dashboard helps by analyzing the daily fluctuation in total participant numbers, new participants, and shared participants. This analysis identifies user engagement rates and significant user ratios.
                            <br/>
                            <br/>
                            Specifically, based on the distribution of existing customers, data collected through 16 survey questions analyze the type distribution related to customers’ views on stress, providing insights into target customer types.
                            <br/>
                            <br/>
                            Through this, the dashboard analyzes customer type distribution and effectively tracks type trends for target customer insights. Visual representation of comprehensive statistics allows real-time monitoring of user data, supporting service performance tracking.`,
                    img : {
                        pc : homeTravelModalAdmin2,
                        m : homeTravelModalAdmin2Small
                    },
                    width : {
                        pc : '85%',
                        m : ''
                    },
                    alignItems: 'flex-start',
                    textAlign: 'left'
                },
                { 
                    title : `User Data Analysis`,
                    subCopy : `The collected data analyzes user engagement, including total participants, daily participants, new and shared participants, and key data fluctuation rates. This enables precise identification and response to big data management issues, with behavioral data on active customers gathered through participation rates, user fluctuation rates, and week-over-week changes. 
                            <br/>
                            <br/>
                            The Small Data generated through this analysis improves the relationship between the company and its customers, providing effective information to offer improved services. SALTMiNE aims to provide progressively refined metrics for continuous growth through deeper customer communication.`,
                    img : {
                        pc : homeTravelModalAdmin3,
                        m : homeTravelModalAdmin3Small
                    },
                    width : {
                        pc : '85%',
                        m : ''
                    },
                    bgColor : '##8FDF9',
                    alignItems: 'flex-start',
                    textAlign: 'left'
                }
            ]
        },
        {
            logo : flasierLogo,
            logoSizeLarge : { width: '162px', height: '58px' },
            logoSizeSmall : { width: '83px', height: '30px' },
            title : `Discover Your Furniture Purchase Preferences`,
            company : `Obigshare Ltd.`,
            project : `Discover Your Furniture Purchase Preferences`,
            period : `23.05. ~ 23.07.`,
            user : [
                {
                    title : `Over view`,
                    subCopy : `The SMTi - Furniture Preference Test is developed based on Carl Jung's cognitive psychology.<br/>
                            In the furniture purchase preference test, customers can receive recommendations for furniture that suits their preferences and style.<br/>
                            By using data modeling and psychological research,<br/>
                            customer tastes are identified, and suitable furniture data is provided based on customer needs.`,
                    img : {
                        pc : falsierModalUser1,
                        m : falsierModalUser1Samll
                    } 
                },
                {
                    title : `Q&A`,
                    subCopy : `The questionnaire, which consists of 16 detailed questions, carefully captures users' furniture personality and characteristics.<br/>
                            Each question explores different aspects, helping users discover preferences they naturally relate to.<br/>
                            SALTMiNE values diversity and uniqueness among customers rather than limiting them to specific types.<br/>
                            The goal of our type-based questionnaire is “to help customers discover new possibilities through self-reflective questions.”<br/>
                            Consequently, user types are expressed in 256 unique ways,<br/>
                            helping each individual gain a deeper understanding of their personality by reflecting personal experiences and values.`,
                    img : {
                        pc : falsierModalUser2,
                        m : falsierModalUser2Small
                    },
                    imgWidth : {
                        pc : '',
                        m : '100%'
                    }
                },
                {
                    title : `Result Page`,
                    subCopy : `Through the results page, customers gain a deeper understanding of their furniture purchase preferences and receive tailored product recommendations from the company.<br/>
                            For highly active users, targeted marketing is provided based on cumulative data frequency and engagement, offering opportunities for SNS sharing events and rewards.<br/>
                            The results page offers personalized in-house products that increase customer satisfaction, enhance retention, and positively impact conversion rates by boosting product sales.`,
                    img : {
                        pc : falsierModalUser3,
                        m : falsierModalUser3Small
                    },
                    bgColor : '#E7F4F2',
                    flexDirection: 'row',
                    alignItems: 'flex-start',
                    textAlign: 'left',
                    imgWidth : {
                        pc : '',
                        m : '100%'
                    },
                    paddingTop : {
                        pc : '85px',
                        m : '37px'
                    },
                    margin: {
                        pc : '0 8%',
                        m : ''
                    }
                }
            ],
            admin: [
                {
                    title : `Dashboard`,
                    subCopy : `The SALTMiNE admin page focuses on precise customer analysis. By utilizing data collected through 16 survey questions, it effectively identifies customer preferences and behavior patterns.<br/>
                            The dashboard analyzes the distribution of customer types, providing effective analysis on target customers by monitoring fluctuations in each type. To accurately understand data status, the dashboard helps by analyzing daily participation, new participant count, and fluctuations in shared participant data. This allows the assessment of engagement rates and significant user ratios.
                            <br/>
                            <br/>
                            Specifically, based on the distribution of existing customers, data collected through 16 survey questions analyze type distribution related to furniture preferences and provide intuitive insights into target customer types.
                            <br/>
                            <br/>
                            Through this, the dashboard analyzes customer type distribution and tracks type trends to deliver insights on target customers. The comprehensive statistical data is visually presented, allowing real-time monitoring of user data, thus supporting service performance evaluation.`,
                    img : {
                        pc : falsierModalAdmin1,
                        m : falsierModalAdmin1
                    },
                    width : {
                        pc : '85%',
                        m : ''
                    },
                    alignItems: 'flex-start',
                    textAlign: 'left'
                },
                {
                    title : `User Data Analysis`,
                    subCopy : `The collected data provides an analysis of user engagement status, identifying fluctuations in key metrics like total participants, daily participants, new users, and shared users. This enables accurate identification and response to big data management issues, tracking behavior patterns through participation rates, weekly user changes, and user increase rates.
                            <br/>
                            <br/>
                            The Small Data obtained from this analysis strengthens the relationship between the company and its customers. It provides efficient information to enhance service offerings and aims to offer metrics that promote continuous growth through deeper customer engagement.`,
                    img : {
                        pc : falsierModalAdmin2,
                        m : falsierModalAdmin2
                    },
                    width : {
                        pc : '85%',
                        m : ''
                    },
                    alignItems: 'flex-start',
                    textAlign: 'left'
                },
                {
                    title : `Content Data Analysis`,
                    subCopy : `The Content Analysis Category focuses on providing content to capture Small Data. This page primarily analyzes the types of existing customers, offering various insights.
                            <br/>
                            <br/>
                            Initially, it presents the type distribution of customers participating in the survey, capturing each type’s characteristics in detail and intuitively. This enables an accurate understanding of customers’ furniture preferences and behavior patterns.
                            <br/>
                            <br/>
                            Additionally, through the keyword map, target keywords for customers using the service are identified, aiding in the development of essential content and marketing strategies.`,
                    img : {
                        pc : falsierModalAdmin3,
                        m : falsierModalAdmin3Small
                    },
                    width : {
                        pc : '85%',
                        m : ''
                    },
                    alignItems: 'flex-start',
                    textAlign: 'left'
                },
            ]
        },
        {
            logo : wooAhYeahLogo,
            logoSizeLarge : { width: '214px', height: '72px' },
            logoSizeSmall : { width: '126px', height: '42px' },
            title : `Discover Your Child’s Play Preferences`,
            company : `Sinsegye Ltd. - WooAhYeah`,
            project : `Discover Your Child’s Play Preferences`,
            period : `23.03. ~ 23.06.`,
            user : [
                {
                    title : `Over view`,
                    subCopy : `The SMTi-Child’s Play Preference Test is designed to identify preferred play styles based on children’s personality types. It explores various aspects like preferences, tastes, and personality traits, tailoring play recommendations to each type.
                            <br/>
                            <br/>
                            The type derived from the questions reflects the child’s personality and includes factors influencing play. The process considers individual types when recommending suitable play activities.
                            <br/>
                            <br/>
                            This enables children to receive accurate play suggestions that fit their personal tendencies and style, allowing a personalized play experience. Ultimately, the SMTi type test offers a more customized play experience for children.`,
                    img : {
                        pc : wooAhYeahModalUser1,
                        m : wooAhYeahModalUser1Small
                    },
                    flexDirection: 'row',
                    alignItems: 'flex-start',
                    textAlign: 'left',
                    margin: {
                        pc : '0 4%',
                        m : ''
                    }
                },
                {
                    title : `Q&A`,
                    subCopy : `The SMTi-Stress Relief Tendency Test presents 16 questions to customers, helping them identify their stress relief type.
                            <br/>
                            <br/>
                            The questions are carefully crafted to capture the unique characteristics and traits related to stress relief, considering refined elements for precise assessment. They explore both internal and external aspects of the customer, helping them naturally discover relatable approaches to stress relief.
                            <br/>
                            <br/>
                            Based on customer responses, stress relief types are categorized into 256 unique profiles, allowing each individual to receive recommendations that align with their specific type, experiences, and values.`,

                },
                {
                    title : `Result Page`,
                    subCopy : `Through the results page, children gain a deeper understanding of their play preferences, and the company offers tailored play recommendations to match their interests.
                            <br/>
                            <br/>
                            For highly active users, targeted play recommendations are provided based on cumulative frequency and engagement data.
                            The results page offers personalized play options, enhancing customer satisfaction, improving retention, and having a positive effect by increasing conversion to offline sessions.`,
                    img : {
                        pc : wooAhYeahModalUser3,
                        m : wooAhYeahModalUser3Small
                    },
                    bgColor : '#E8FDF9',
                    imgWidth : {
                        pc : '',
                        m : '100%'
                    },
                    paddingTop : {
                        pc : '72px',
                        m : '25px'
                    }
                }
            ],
            admin : [
                { 
                    title : `Dashboard`,
                    subCopy : `The SALTMiNE admin page focuses on accurately understanding data status. To achieve this, the dashboard analyzes daily participant fluctuation, new participant counts, and shared participant changes based on total user engagement. This helps in identifying user engagement rates and meaningful child ratios.
                            <br/>
                            <br/>
                            Specifically, using data collected from 16 survey questions, the distribution ratio of children’s types is analyzed based on their preferences for play. This provides intuitive data on types with high market distribution and helps in understanding the preferred play activities among children.
                            <br/>
                            <br/>
                            Through this, the dashboard analyzes the distribution of children’s types and provides effective insights on target children by tracking changes in each type’s trend. Comprehensive statistics are visually represented, allowing real-time monitoring of user data and supporting the evaluation of service performance.`,
                    img : {
                        pc : wooAhYeahModalAdmin1,
                        m : wooAhYeahModalAdmin1
                    },
                    width : {
                        pc : '85%',
                        m : ''
                    },
                    alignItems: 'flex-start',
                    textAlign: 'left'
                },
                { 
                    title : `User Data Analysis`,
                    subCopy : `The collected data analyzes user engagement status, including total participants, daily participants, new users, and shared users, by identifying fluctuation rates in key metrics. This enables precise detection and response to gaps in big data management. Behavior data on children can be tracked through participation rates, user fluctuation rates, and weekly changes.
                            <br/>
                            <br/>
                            The Small Data derived from this analysis improves the relationship between teachers or parents and children, providing efficient information to enhance services. SALTMiNE aims to provide gradually refined metrics through deeper engagement with children, promoting continuous growth.`,
                    img : {
                        pc : wooAhYeahModalAdmin2,
                        m : wooAhYeahModalAdmin2Small
                    },
                    width : {
                        pc : '85%',
                        m : ''
                    },
                    alignItems: 'flex-start',
                    textAlign: 'left'
                },
                { 
                    title : `Content Data Analysis`,
                    subCopy : `The Content Analysis Category focuses on providing content to identify Small Data. This page primarily analyzes the types of children, offering various insights.
                            <br/>
                            <br/>
                            Initially, it presents the type distribution of children participating in the survey, capturing each type’s characteristics in detail and intuitively. This allows for an accurate understanding of children’s play preferences and behavior patterns.
                            <br/>
                            <br/>
                            Additionally, through ranking distribution, target keywords for children using the service are identified, aiding in the development of essential content and marketing strategy formulation.`,
                    img : {
                        pc : wooAhYeahModalAdmin3,
                        m : wooAhYeahModalAdmin3Small
                    },
                    width : {
                        pc : '85%',
                        m : ''
                    },
                    alignItems: 'flex-start',
                    textAlign: 'left'
                }
            ]
        }
    ]

};