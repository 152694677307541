import styled from 'styled-components';
import saltmine from '../../img/saltmine.svg';
import menu from '../../img/icon/menu.svg';
import close from '../../img/icon/close.png';
import chevronDown from '../../img/icon/chevron_bottom.svg';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import useScroll from '../../hooks/useScroll.js';
import React, { useEffect, useState } from 'react';
import useScreenSize from '../../hooks/useScreenSize.js';
import usePreventScroll from '../../hooks/usePreventScroll.js';
import LanguageSwitcher from '../common/LanguageSwitcher.js';
import { useLanguage } from '../../context/LanguageContext.js';
import { headerText } from '../../datas/data.js';


const Header = React.forwardRef((props, ref) => {

    const { language } = useLanguage();
    const header = headerText;

    const { isSmallScreen, isMediumScreen } = useScreenSize();

    const isScrolled = useScroll();
    const [ isHovered, setIsHovered ] = useState(false);
    const [ isMenuClicked, setIsMenuClicked ] = useState(false);
    const [ mobileNavHeight, setMobileNavHeight ] = useState('');
    const [ isDropdownOpen, setIsDropdownOpen ] = useState(false);

    const handleMouseEnter = () => {
        setIsHovered(true);
    };

    const handleMouseLeave = () => {
        setIsHovered(false);
    };

    const handleMenuClick = () => {
        setIsMenuClicked(!isMenuClicked);
    };

    const handleMenuClose = () => {
        setIsMenuClicked(false);
        setIsDropdownOpen(false);
    };

    const handleDropdownToggle = (e) => {
        e.preventDefault();
        setIsDropdownOpen(!isDropdownOpen);
    };

    const navigate = useNavigate();

    const handleCustomerCaseClick = (e) => {
        e.preventDefault();
        handleMenuClose();
        navigate('/solution', { state: { scrollToCustomerCase: true } });
    };

    useEffect(() => {
        setMobileNavHeight(`${ref.current.offsetHeight}px`);
    },[]);

    usePreventScroll(isMenuClicked);


    return (
        <>
            <HeaderContainer 
                isScrolled={isScrolled} 
                isHovered={isHovered} 
                ref={ref}
                isSmallScreen={isSmallScreen}
                >
                <Navbar isSmallScreen={isSmallScreen} isMediumScreen={isMediumScreen}>
                    <Logo className="inline-flex justify-center items-center w-82 h-40">
                        <Link to="/"><img src={saltmine} alt="소금광산" className="block w-full h-full"/></Link>
                    </Logo>
                    {
                        isSmallScreen || isMediumScreen ? 
                        <div>
                            <button onClick={handleMenuClick}>
                                <img src={menu} className="block w-full h-full" />
                            </button>
                        </div>
                        : 
                        <NavWrapper>
                            <NavList>
                                <NavItemLi
                                    onMouseEnter={handleMouseEnter} 
                                    onMouseLeave={handleMouseLeave}
                                    >
                                    <NavItem to="/solution">
                                        { header.solution[language] }
                                    </NavItem>
                                    <SubNavList isHovered={isHovered}>
                                        <li>
                                            <SubNavItem to="/#" onClick={handleCustomerCaseClick}>
                                                { header.case[language] }
                                            </SubNavItem>
                                        </li>
                                    </SubNavList>
                                </NavItemLi>
                                <li>
                                    <NavItem to="/info">
                                        { header.info[language] }
                                    </NavItem>
                                </li>
                                <li>
                                    <NavItem to="/inquiry">
                                        { header.inquiry[language] }
                                    </NavItem>
                                </li>
                            </NavList>
                        </NavWrapper>
                    }   
                    {   (isSmallScreen || isMediumScreen) ||
                        <LanguageSwitcher
                            className={`absolute right-0`}
                            isSmallScreen={isSmallScreen}
                        />
                    }
                </Navbar>
            </HeaderContainer>
            {
                ((isSmallScreen || isMediumScreen) && isMenuClicked) && 
                <Overlay onClick={handleMenuClose}>
                    <MobileNav onClick={(e) => e.stopPropagation()}>
                        <CloseBox height={mobileNavHeight}>
                            <button onClick={handleMenuClose} className="w-22 h-22">
                                <img src={close} className="block w-full h-full" />
                            </button>
                        </CloseBox>
                        <MobileNavList>
                            <MobileNavItem>
                                <NavLink 
                                    to="/solution" 
                                    className="flex gap-x-25"
                                    >
                                        <span onClick={handleMenuClose}>{ header.solution[language] }</span>
                                        <ChevronToggle
                                            isOpen={isDropdownOpen} 
                                            onClick={(e) => handleDropdownToggle(e)}
                                            >
                                                <img src={chevronDown} className="block w-full h-full"/>
                                        </ChevronToggle>
                                </NavLink>
                            </MobileNavItem>
                            <DropdownContainer isOpen={isDropdownOpen}>
                                <li className="bg-gray1 py-14 pr-25">
                                    <a href="#" onClick={handleCustomerCaseClick}>{ header.case[language] }</a>
                                </li>
                            </DropdownContainer>
                            <MobileNavItem>
                                <NavLink to="/info">
                                    <span onClick={handleMenuClose}>{ header.info[language] }</span>
                                </NavLink>
                            </MobileNavItem>
                            <MobileNavItem>
                                <NavLink to="/inquiry">
                                    <span onClick={handleMenuClose}>{ header.inquiry[language] }</span>
                                </NavLink>
                            </MobileNavItem>
                            <MobileNavItem>
                            <LanguageSwitcher
                                className={`absolute right-8`}
                                isSmallScreen={isSmallScreen}
                                handleMenuClose={handleMenuClose}
                            />
                            </MobileNavItem>
                        </MobileNavList>
                    </MobileNav>
                </Overlay>
                
            }
        </>
    );

});

export default Header;


const Logo = styled.div`
    width: ${({ isSmallScreen }) => isSmallScreen ? '73px' : '82px' };
    height: 40px;

    display: inline-flex;
    justify-content: center;
    align-items: center;
`;

const HeaderContainer = styled.header`
    width: 100%;
    background-color: #FFFFFF;
    padding: ${({ isSmallScreen }) => isSmallScreen ? '10px 0' : '10px 40px'};
    height: ${({ isHovered, isSmallScreen }) => isHovered ? '96px' : isSmallScreen? '56px' : '60px'};
    border-bottom: 1px solid transparent;
    border-bottom-color: ${({ isScrolled, isHovered }) => isScrolled || isHovered ? 'rgba(0, 27, 55, 0.1)' : 'transparent' };

    position: fixed;
    top: 0;
    left: 0;
    z-index: 50;
    overflow: hidden;

    transition: height 0.3s ease, border-bottom-color 0.3s ease;
`;

const Navbar = styled.nav`
    width: ${({ isSmallScreen, isMediumScreen }) => 
        isSmallScreen ? 'calc(100% - 40px)' : 
        isMediumScreen ? '100%' : '880px'};
    margin: 0 auto;
    white-space: nowrap;

    display: flex;
    justify-content: ${({ isSmallScreen, isMediumScreen }) => isSmallScreen || isMediumScreen ? 'space-between' : 'flex-start' };
    align-items: center;
    gap: 60px;
    
    position: relative;
`;

const NavWrapper = styled.div`
    display: flex;
    justify-content: space-between;
`;

const NavList = styled.ul`
    color: #5C5C5C;
    font-size: 16px;
    line-height: 20px;

    display: flex;
    align-items: center;
    gap: 48px;
`;

const NavItemLi = styled.li`
    height: 40px;

    position: relative;
    display: inline-flex;
    justify-content: center;
    align-items: center;
`;

const NavItem = styled(NavLink)`
    &:hover {
        color: #000000;
    }

    &.active {
        font-weight: 600;
        color: #000000;
    }
`;

const SubNavList = styled.ul`
    position: absolute;
    top: 39px;
    left: -21px;
    background-color: white;
    list-style: none;
    padding: 10px;
    margin: 0;
    opacity: ${({ isHovered }) => isHovered ? 1 : 0 };
    height: ${({ isHovered }) => isHovered ? 'auto' : '0' };
    overflow: hidden;
    transition: opacity 0.3s ease, height 0.3s ease;

    li {
        width: 109px;
        padding: 8px;
        background: #5C5C5C;

        font-size: 16px;
        font-weight: 600;
        color: #FFFFFF;
        line-height: 20px;

        border-radius: 8px;

        display: flex;
        justify-content: center;
        align-items: center;
    }
`;

const SubNavItem = styled(NavLink)`
    color: #FFFFFF;
    &:hover {
        color: #FFFFFF;
    }

    &.active {
        font-weight: 600;
        color: #FFFFFF;
    }
`;

const Overlay = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 100;
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
`;

const MobileNav = styled.nav`
    width: 180px;
    height: auto;
    background-color: ${ ({theme}) => theme.colors.white};
    padding-bottom: 16px;
`;

const CloseBox = styled.div`
    height: ${ ({height}) => height};
    margin-left: 143px;

    display: inline-flex;
    justify-content: center;
    align-items: center;
`;

const MobileNavList = styled.ul`
    width: 100%;
    color: ${ ({theme}) => theme.colors.gray2 };
    position: relative;
`;

const MobileNavItem = styled.li`
    width: 100%;
    height: 48px;
    padding: 14px 0 14px 36px;
`;

const ChevronToggle = styled.button`
    transform: rotate(${({ isOpen }) => (isOpen ? '180deg' : '0deg')});
    transition: transform 0.1s ease;
`;

const DropdownContainer = styled.ul`
    max-height: ${({ isOpen }) => (isOpen ? '100px' : '0')};
    text-align: right;
    overflow: hidden;
    transition: max-height 0.3s ease, opacity 0.3s ease;
    opacity: ${({ isOpen }) => (isOpen ? 1 : 0)};
`;