import styled from 'styled-components';
import useScreenSize from '../../../hooks/useScreenSize';
import ModalTitleSection from './ModalTitleSection';
import ModalButtonSection from './ModalButtonSection';
import ModalContents from './ModalContents';
import { useEffect, useRef, useState } from 'react';


const CustomerCaseModal = ({ selectedCase, resetToUser }) => {

    const { isSmallScreen, isMediumScreen } = useScreenSize();
    const [ activeButton, setActiveButton ] = useState('user');
    const [ isSticky, setIsSticky ] = useState(false);
    const [ isScrollingDown, setIsScrollingDown ] = useState(false);
    const modalRef = useRef(null);
    const buttonSectionRef = useRef(null);
    let lastScrollTop = 0;
    
    useEffect(() => {
        if (resetToUser) {
            setTimeout(() => {
                setActiveButton('user');
                if (modalRef.current) {
                    modalRef.current.scrollTop = 0;
                }
            }, 350);
        }
    }, [resetToUser]);

    // useEffect(() => {
    //     if (modalRef.current) {
    //         modalRef.current.scrollTop = 0;
    //     }
    // }, [activeButton]);

    // useEffect(() => {
    //     const observer = new IntersectionObserver(
    //         ([entry]) => {
    //             if (entry.isIntersecting && isSticky) {
    //                 setIsSticky(false);
    //             } else if (!entry.isIntersecting && !isSticky) {
    //                 setIsSticky(true);
    //             }
    //         },
    //         { root: modalRef.current, threshold: 1 }
    //     );

    //     if (buttonSectionRef.current) {
    //         observer.observe(buttonSectionRef.current);
    //     }

    //     return () => {
    //         if (buttonSectionRef.current) {
    //             observer.unobserve(buttonSectionRef.current);
    //         }
    //     };
    // }, []);

    // useEffect(() => {
    //     const handleScroll = () => {
    //         const currentScrollTop = modalRef.current.scrollTop;
    //         const threshold = isSmallScreen ? 180 : 320;
    //         if (currentScrollTop > lastScrollTop) {
    //             setIsScrollingDown(true);
    //         } else {
    //             setIsScrollingDown(false);
    //         }

    //         if (currentScrollTop <= threshold) {
    //             setIsScrollingDown(false);
    //             setIsSticky(false);
    //         }

    //         lastScrollTop = currentScrollTop <= 0 ? 0 : currentScrollTop;
    //     };

    //     const modalElement = modalRef.current;
    //     modalElement.addEventListener('scroll', handleScroll);

    //     return () => {
    //         modalElement.removeEventListener('scroll', handleScroll);
    //     };
    // }, []);


    return (
        <Section isSmallScreen={isSmallScreen} ref={modalRef}>
            <ModalTitleSection isSmallScreen={isSmallScreen} selectedCase={selectedCase} />
            <ModalButtonSection 
                isSmallScreen={isSmallScreen} 
                activeButton={activeButton} 
                setActiveButton={setActiveButton}
                isSticky={isSticky}
                isHidden={isSticky && isScrollingDown}
                buttonSectionRef={buttonSectionRef}
            />
            <ModalContents 
                isSmallScreen={isSmallScreen} 
                isMediumScreen={isMediumScreen}
                selectedCase={selectedCase} 
                activeButton={activeButton} 
            />
        </Section>
    );
    
}

export default CustomerCaseModal;


const Section = styled.section`
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    height: 100%;

    margin-top: ${({ isSmallScreen }) => isSmallScreen && '56px'};
`;