import styled from 'styled-components';
import bgImage from '../../img/small_data_value_image.png';
import { Link } from 'react-router-dom';
import { Section } from '../../styled/common.js';
import { mainPageText } from '../../datas/data.js';
import useScreenSize from '../../hooks/useScreenSize.js';
import { scrollToSection } from '../../utils/functions.js';
import ScrollYButton from '../common/ScrollYButton.js';
import { useLanguage } from '../../context/LanguageContext.js';


const SmallDataValueBg = ({ height, nextSectionRef, headerHeight }) => {

    const { isSmallScreen, isXLargeScreen } = useScreenSize();
    
    const { language } = useLanguage();
    const currentText = mainPageText[language];
    const title = isSmallScreen ? currentText.smDataBg.title.m : currentText.smDataBg.title.pc;
    const subCopy = isSmallScreen ? currentText.smDataBg.subCopy.m : currentText.smDataBg.subCopy.pc;
    const salti = isSmallScreen ? currentText.smDataBg.salti.m : currentText.smDataBg.salti.pc;

    const handleScroll = () => { scrollToSection(nextSectionRef, headerHeight); };
    
    const bgWidth = '100%';
    const bgHeight = isSmallScreen ? height : isXLargeScreen ? '100%' : '600px';

    
    return (
        <Section>
            <BgCrop height={bgHeight}>
                <img src={bgImage} className="object-cover"/>
                <Background 
                    width={bgWidth}
                    height={bgHeight}
                />
                <TextBox>
                    <H1 
                        isSmallScreen={isSmallScreen}
                        dangerouslySetInnerHTML={{ __html: title }} 
                    />
                    <P 
                        isSmallScreen={isSmallScreen}
                        dangerouslySetInnerHTML={{ __html: subCopy }} 
                    />
                <div>
                    <Link 
                        to="/solution" 
                        className={`${isSmallScreen ? 'text-16' : 'text-18'} font-semibold text-gray3 bg-white px-20 py-12 rounded-48`}
                        >
                            { salti }
                    </Link>
                </div>
                </TextBox>
                { isSmallScreen && <ScrollYButton onClick={handleScroll} /> }
            </BgCrop>
            
        </Section>
    );

}

export default SmallDataValueBg;


const BgCrop = styled.div`
    width: 100%;
    height: ${({ height }) => height };

    display: flex;
    justify-content: center;
    
    position: relative;
    overflow: hidden;
`;

const Background = styled.section`
    width: ${ ({width}) => width };
    height: ${ ({height}) => height };
    opacity: 0.7;
    background: linear-gradient(107deg, rgba(27,47,163,1), rgba(0,129,218,1), rgba(0,180,168,1));
    background-size: cover;
    box-sizing: border-box;

    position: absolute;
    top: 0;
    left: 0;
`;

const TextBox = styled.div`
    height: 199px;

    color: #FFFFFF;
    text-align: center;
    white-space: nowrap;

    align-self: center;
    
    position: absolute;
`;

const H1 = styled.h1`
    font-size: ${ ({isSmallScreen}) => isSmallScreen ? '26px' : '40px' };
    font-weight: 700;
    line-height: 140%;

    margin-bottom: ${ ({isSmallScreen}) => isSmallScreen ? '14px' : '18px' };
`;

const P = styled.p`
    font-size: ${ ({isSmallScreen}) => isSmallScreen ? '16px' : '18px' };
    line-height: 140%;
    letter-spacing: -0.24px;

    margin-bottom: ${ ({isSmallScreen}) => isSmallScreen ? '32px' : '40px' };
`;