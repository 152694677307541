import { useEffect } from "react";
import { customerCasesData, modalCasesData } from "../datas/customerCaseData";

const preloadImage = (src) => {
    const img = new Image();
    img.src = src;
};

const usePreloadImages = () => {
    useEffect(() => {
        const preloadImages = () => {
            // 모든 언어의 데이터를 순회
            Object.values(customerCasesData).forEach((casesArray) => {
                casesArray.forEach((customerCase) => {
                    preloadImage(customerCase.img);
                });
            });

            // modalCasesData의 언어별 데이터를 순회
            Object.values(modalCasesData).forEach((casesArray) => {
                casesArray.forEach((modalCase) => {
                    preloadImage(modalCase.logo);

                    modalCase.user.forEach((userData) => {
                        if (userData.img) {
                            preloadImage(userData.img.pc);
                            if (userData.img.m) {
                                preloadImage(userData.img.m);
                            }
                        }
                    });

                    modalCase.admin.forEach((adminData) => {
                        if (adminData.img) {
                            preloadImage(adminData.img.pc);
                            if (adminData.img.m) {
                                preloadImage(adminData.img.m);
                            }
                        }
                    });
                });
            });
        };

        preloadImages();
    }, []);
};

export default usePreloadImages;
