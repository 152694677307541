import React, { createContext, useReducer, useContext, useEffect } from 'react';

// 초기 언어 상태 (URL에서 가져오고, 없으면 'kor'로 초기화)
const getInitialLanguage = () => {
    const params = new URLSearchParams(window.location.search);
    if (params.get('language') === 'en') return 'eng';
    if (params.get('language') === 'kr') return 'kor';
    return localStorage.getItem('language') || 'kor';
};

const initialState = getInitialLanguage();

// Reducer 함수 정의
const languageReducer = (state, action) => {
    switch (action.type) {
        case 'SET_LANGUAGE':
            localStorage.setItem('language', action.payload); // 변경 시 localStorage에 저장
            return action.payload;
        default:
            return state;
    }
};

// Context 생성
const LanguageContext = createContext();

// Provider 컴포넌트 생성
export const LanguageProvider = ({ children }) => {
    const [language, dispatch] = useReducer(languageReducer, initialState);

    useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        const urlLanguage = params.get('language');

        // URL에 언어 파라미터가 있으면 로컬스토리지와 상태를 덮어씁니다
        if (urlLanguage === 'en' && language !== 'eng') {
            dispatch({ type: 'SET_LANGUAGE', payload: 'eng' });
        } else if (urlLanguage === 'kr' && language !== 'kor') {
            dispatch({ type: 'SET_LANGUAGE', payload: 'kor' });
        }
    }, []);

    useEffect(() => {
        // URL에 언어 파라미터를 추가하거나 업데이트
        const params = new URLSearchParams(window.location.search);
        const languageParam = language === 'eng' ? 'en' : 'kr';

        if (params.get('language') !== languageParam) {
            params.set('language', languageParam);
            const newUrl = `${window.location.pathname}?${params.toString()}`;
            window.history.replaceState(null, '', newUrl);
        }
    }, [language]);

    return (
        <LanguageContext.Provider value={{ language, dispatch }}>
            {children}
        </LanguageContext.Provider>
    );
};

// useLanguage Hook 생성 (Context 사용 편리하게 하기 위함)
export const useLanguage = () => useContext(LanguageContext);
