import styled from 'styled-components';
import clip from '../../img/icon/clip.svg';
import x from '../../img/icon/x.svg';
import { useState } from 'react';
import Modal from './Modal';
import { useLanguage } from '../../context/LanguageContext';

const FileInput = ({ handleAddFile, handleDeleteFile, isSmallScreen, resetFilesRef }) => {

    const { language } = useLanguage();
    const text = language==='kor' ? '파일 첨부' : 'File';
    
    const [ fileInfo, setFileInfo ] = useState([]);
    const [ isModalOpen, setIsModalOpen ] = useState(false);
    const allowedExtensions = ['ppt', 'pptx', 'xls', 'xlsx', 'pdf', 'doc', 'docx', 'hwp', 'jpeg', 'jpg', 'png', 'webp', 'gif', 'zip'];

    // 파일 초기화 함수
    const resetFiles = () => {
        setFileInfo([]);
    };

    // resetFiles 함수를 ref로 전달
    if (resetFilesRef) {
        resetFilesRef.current = resetFiles;
    }

    const onAddFile = (e) => {
        const files = Array.from(e.target.files);
        const validFiles = files.filter(file => {
            const fileExtension = file.name.split('.').pop().toLowerCase();
            return allowedExtensions.includes(fileExtension);
        });

        if (validFiles.length < files.length) {
            setIsModalOpen(true);
        }

        const remainingSlots = 3 - fileInfo.length;
        const filesToAdd = validFiles.slice(0, remainingSlots).map((file) => ({
            name: file.name,
            size: (file.size / (1024 * 1024)).toFixed(2),
            url: URL.createObjectURL(file),
            file
        }));

        setFileInfo((prevFiles) => [...prevFiles, ...filesToAdd]);

        // handleAddFile에 추가된 파일 정보 전달
        handleAddFile(validFiles.slice(0, remainingSlots));
    };

    const deleteFile = (e, index) => {
        e.preventDefault();
        const updatedFiles = fileInfo.filter((_, i) => i !== index);
        setFileInfo(updatedFiles);

        // handleDeleteFile을 호출하여 formData에서도 해당 파일 삭제
        handleDeleteFile(index);
    };

    const onClose = (e) => {
        e.preventDefault();
        setIsModalOpen(false);
    }

    
    return (
        <>
            <FileSection isSmallScreen={isSmallScreen} fileInfo={fileInfo}>
                {fileInfo.length > 0 && (
                    <FileList isSmallScreen={isSmallScreen}>
                    {
                        Array(3).fill().map((_, index) => (
                            <FileInfo key={index} isSmallScreen={isSmallScreen}>
                            {
                                fileInfo[index] && (
                                    <>
                                        <a
                                            href={fileInfo[index].url}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className={`flex ${isSmallScreen ? 'gap-x-4' : 'gap-x-8'} items-center`}
                                        >
                                            <FileName isSmallScreen={isSmallScreen}>
                                                {fileInfo[index].name}
                                            </FileName>
                                            <span className={`${isSmallScreen ? 'text-7' : 'text-11'}`}>
                                                ({fileInfo[index].size} MB)
                                            </span>
                                        </a>
                                        <button
                                            className={`${isSmallScreen ? 'w-13 h-13' : 'w-17 h-17'}`}
                                            onClick={(e) => deleteFile(e, index)}
                                        >
                                            <img src={x} className="block w-full h-full" />
                                        </button>
                                    </>
                                )
                            }
                            </FileInfo>
                        ))
                    }
                    </FileList>
                )}
                <Label isSmallScreen={isSmallScreen}>
                    <img src={clip} />
                    <span>{ text }</span>
                    <input type="file" className="hidden" onChange={onAddFile} multiple />
                </Label>
            </FileSection>
            
            <Modal 
                isSmallScreen={isSmallScreen} 
                isModalOpen={isModalOpen} 
                onClose={onClose}
                message='not allowed'
            />
        </>
    );
};

export default FileInput;


const FileSection = styled.div`
    width: ${({ isSmallScreen }) => isSmallScreen ? '320px' : '580px'};
    height: ${({ isSmallScreen }) => isSmallScreen ? '48px' : '68px'};
    margin-top: ${({ isSmallScreen }) => isSmallScreen ? '12px' : '16px'};

    display: flex;
    justify-content: ${({ fileInfo }) => fileInfo.length === 0 ? 'flex-end' : 'space-between'};
    align-self: flex-end;
`;

const Label = styled.label`
    width: ${({ isSmallScreen }) => isSmallScreen ? '120px' : '160px'};
    height: ${({ isSmallScreen }) => isSmallScreen ? '40px' : '44px'};
    background-color: ${({ theme }) => theme.colors.white};
    border: 1.5px solid ${({ theme }) => theme.colors.main1};
    border-radius: ${({ isSmallScreen }) => isSmallScreen ? '10px' : '8px'};

    font-size: 16px;
    font-weight: 500;
    color: ${({ theme }) => theme.colors.gray3};

    display: flex;
    justify-content: center;
    align-items: center;
    align-self: flex-start;
    gap: ${({ isSmallScreen }) => isSmallScreen ? '6px' : '16px'};

    cursor: pointer;

    &:hover {
        background-color: rgba(254, 254, 254, 0.75);
    }
`;

const FileList = styled.div`
    display: grid;
    grid-template-rows: repeat(3, 1fr);
    width: ${({ isSmallScreen }) => (isSmallScreen ? '180px' : '350px')};
    border: 1px solid ${({ theme }) => theme.colors.gray7};
`;

const FileInfo = styled.div`
    font-size: ${({ isSmallScreen }) => isSmallScreen ? '10px' : '12px'};
    color: ${({ theme }) => theme.colors.gray3};
    padding: ${({ isSmallScreen }) => (isSmallScreen ? '0 2.5px' : '0 5px')};
    border-bottom: 1px solid ${({ theme }) => theme.colors.gray7};

    &:last-child {
        border-bottom: none;
    }

    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: ${({ isSmallScreen }) => isSmallScreen ? '10px' : '20px'};
   

    a {
        color: ${({ theme }) => theme.colors.gray3};

        &:hover {
            color: ${({ theme }) => theme.colors.accent3};
        }
    }
`;

const FileName = styled.span`
    display: inline-block;
    max-width: ${({ isSmallScreen }) => isSmallScreen ? '110px' : '280px'};
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; 
`;
