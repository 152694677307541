import { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { history } from "../../datas/history";
import { useMediaQuery } from "react-responsive";
import chevronBottom from '../../img/icon/chevron_bottom_info.svg';
import chevronTop from '../../img/icon/chevron_top_info.svg';


const HistoryTable = ({ isSmallScreen }) => {

    const isOverSize = useMediaQuery({ minWidth: 2080 })

    const [ historyDatas ] = useState(history);
    const [isExpanded, setIsExpanded] = useState(() => {
        return history.map(data => data.content.length > 3 ? false : true);
    });

    const toggleExpand = (index) => {
        setIsExpanded(prevState => {
            const newState = [...prevState];
            newState[index] = !newState[index];
            return newState;
        });
    }


    return (
        <Section isSmallScreen={isSmallScreen} isOverSize={isOverSize}>
        {
            historyDatas.map((historyData, index) => 
                <div className={`flex flex-col ${isSmallScreen ? 'w-228 gap-y-12' : 'w- gap-y-25'}`} key={index}>
                    <Year isSmallScreen={isSmallScreen}>
                        { historyData.year }
                    </Year>
                    {
                        historyData.content.slice(0, isExpanded[index] ? historyData.content.length : 3).map((contentData, idx) => (
                            <div className={`flex ${isSmallScreen ? 'gap-x-15 h-30' : 'gap-x-32 h-50'}`} key={idx}>
                                <Month isSmallScreen={isSmallScreen}>
                                    {contentData.month}
                                </Month>
                                <Done isSmallScreen={isSmallScreen}>
                                    {contentData.content}
                                </Done>
                            </div>
                        ))
                    }
                    <div className="inline-flex justify-center">
                    {
                        historyData.content.length > 3 && (
                            <ToggleButton onClick={() => toggleExpand(index)}>
                                <div>
                                    <img src={isExpanded[index] ? chevronTop : chevronBottom} isExpanded={isExpanded[index]} />
                                </div>
                                {
                                    isExpanded[index] ? 
                                    <span>닫기</span> : <span>전체 보기</span>
                                }
                            </ToggleButton>
                        )
                    }
                    </div>
                </div>
            )
        }
        </Section>
    );

}

export default HistoryTable;


const Section = styled.section`
    width: ${({ isOverSize}) => isOverSize ? '1980px' : 'auto'};
    padding-left: ${({ isSmallScreen, isOverSize }) => isOverSize ? '0' : isSmallScreen ? '20px' : '50px'};
    padding-right: ${({ isSmallScreen, isOverSize }) => isOverSize ? '0' : isSmallScreen ? '20px' : '50px'};
    margin: ${({ isOverSize }) => isOverSize ? '0 auto' : ''};

    display: flex;
    gap: ${({ isSmallScreen }) => isSmallScreen ? '10px' : '20px'};

    overflow-x: auto;

    &::-webkit-scrollbar {
        display: ${({ isSmallScreen }) => isSmallScreen && 'none'};
    }
`;

const Year = styled.div`
    font-size: ${({ isSmallScreen }) => isSmallScreen ? '14px' : '24px'};
    font-weight: 700;
    color: ${({ theme }) => theme.colors.gray3};
    line-height: ${({ isSmallScreen }) => isSmallScreen ? '20px' : '42px'};
`;

const Month = styled.div`
    width: ${({ isSmallScreen }) => isSmallScreen ? '20px' : '40px'};
    height: ${({ isSmallScreen }) => isSmallScreen ? '20px' : '40px'};
    background-color: ${({ theme }) => theme.colors.gray6};
    padding: ${({ isSmallScreen }) => isSmallScreen ? '6px' : '10px'};
    border-radius: 20px;

    font-size: ${({ isSmallScreen }) => isSmallScreen ? '12px' : '15px'};
    font-weight: 600;
    color: ${({ theme }) => theme.colors.gray3};

    display: inline-flex;
    justify-content: center;
    align-items: center;
    justify-self: center;
    align-self: center;
`;

const Done = styled.div`
    width: ${({ isSmallScreen }) => isSmallScreen ? '185px' : '308px'};
    font-size: ${({ isSmallScreen }) => isSmallScreen ? '12px' : '18px'};
    font-weight: 600;
    color: ${({ theme }) => theme.colors.gray3};
    line-height: 140%;
`;

const ToggleButton = styled.button`
    color: ${({ theme }) => theme.colors.gray2};
    margin-top: ${({ isSmallScreen }) => isSmallScreen ? '5px' : '10px'};

    display: inline-flex;
    justify-content: center;
    align-items: center;
    gap: ${({ isSmallScreen }) => isSmallScreen ? '4px' : '8px'};
`;

const ChevronIcon = styled.img`
    transition: transform 0.3s ease;
    transform: ${({ isExpanded }) => isExpanded ? 'rotate(180deg)' : 'rotate(0deg)'};
`;

const ContentWrapper = styled.div`
    max-height: ${({ isExpanded }) => isExpanded ? '1000px' : '90px'};
    overflow: hidden;
    transition: max-height 0.5s ease;
`;