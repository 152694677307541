import styled from "styled-components";
import Content from "./Content";
import ExtractSmallData from "./ExtractSmallData";
import SmallDataAdmin from "./SmallDataAdmin";
import React from "react";
import useScreenSize from "../../hooks/useScreenSize";
import { useLanguage } from "../../context/LanguageContext";


const SaltiProcess = React.forwardRef((props, ref) => {

    const { isSmallScreen, isMediumScreen } = useScreenSize();
    const { language } = useLanguage();

    const saltiProcess = language==='kor' ? 'SALTi 프로세스' : 'SALTi Process';
    const firstTitle = language==='kor' ? 
                    `기업의 고객을 대상으로 스몰데이터 추출` : 
                    `Small Data Extraction for Corporate Customers`;
    const firstSubCopy = language==='kor' ? 
                    `Web / App Add-on 방식` : 
                    `Web / App Add-on Method`;
    const secondTitle = language==='kor' ? 
                    `고객의 스몰데이터를 관리할 수 있는 Admin` : 
                    `Admin for Managing Customer Small Data`;
    const secondSubCopy = language==='kor' ? 
                    `대시보드 및 분석 등의 기능을 통해 인사이트 확인 가능` : 
                    `Access insights with dashboard and analysis tools.`;

    
    return (
        <Section isSmallScreen={isSmallScreen} isMediumScreen={isMediumScreen} ref={ref}>
            <Title isSmallScreen={isSmallScreen}>
                { saltiProcess }
            </Title>
            <Content 
                title={firstTitle}
                subCopy={firstSubCopy}
                isSmallScreen={isSmallScreen}
            />
            <ExtractSmallData isSmallScreen={isSmallScreen} />
            <Content 
                title={secondTitle}
                subCopy={secondSubCopy}
                isSmallScreen={isSmallScreen}
            />
            <SmallDataAdmin isSmallScreen={isSmallScreen} />
        </Section>
    );

});

export default SaltiProcess;


const Section = styled.section`
    width: ${({ isSmallScreen, isMediumScreen }) => isSmallScreen ? '320px' : isMediumScreen ? '745px' : '780px' };
    margin: 0 auto;
    padding: ${({ isSmallScreen }) => isSmallScreen ? '80px 0' : '160px 0'};

    display: flex;
    flex-direction: column;
`;

const Title = styled.h1`
    font-size: ${({ isSmallScreen }) => isSmallScreen ? '20px' : '28px' };
    font-weight: ${({ isSmallScreen }) => isSmallScreen ? '600' : '700' };
    color: ${({ theme }) => theme.colors.gray4 };

    margin-bottom: ${({ isSmallScreen }) => isSmallScreen ? '60px' : '100px' };

    align-self: center;
`;