import React, { useState } from "react";
import { Section } from "../../styled/common";
import useScreenSize from "../../hooks/useScreenSize";
import { infoPageText, missionsData } from "../../datas/data";
import Content from "../common/Content";
import { theme } from "../../utils/palette";
import styled from "styled-components";
import { useLanguage } from "../../context/LanguageContext";


const OurMission = React.forwardRef(({ height }, ref) => {

    const { isSmallScreen, isMediumScreen } = useScreenSize();

    const { language } = useLanguage();
    const currentText = infoPageText[language];
    const title = isSmallScreen ? currentText.mission.title.m : currentText.mission.title.pc;
    const subCopy = isSmallScreen ? currentText.mission.subCopy.m : currentText.mission.subCopy.pc;

    const [ missions ] = useState(missionsData);
    
    const color = theme.colors.gray4;
    const sectionHeight = isSmallScreen ? height : '960px';


    return (
        <Section className="bg-white" height={sectionHeight} ref={ref}>
            <section className={`${isSmallScreen ? 'w-320' : isMediumScreen ? 'w-745' : 'w-1180'} mx-auto`}>
                <Content
                    title={title}
                    subCopy={subCopy}
                    color={color}
                    isSmallScreen={isSmallScreen}
                />
                <div 
                    className={`
                        flex justify-center items-center
                        ${isSmallScreen ? 'flex-col gap-y-10' : 
                        isMediumScreen ? 'flex-col gap-y-20' : 'flex-row gap-x-20'}`}
                >
                {
                    missions.map((mission, i) => (
                        <Container isSmallScreen={isSmallScreen} isMediumScreen={isMediumScreen} key={i}>
                            <Subject isSmallScreen={isSmallScreen} isMediumScreen={isMediumScreen}>
                                <div className={`flex justify-center items-center ${isSmallScreen ? 'w-35 h-35' : 'w-70 h-70'}`}>
                                    <img src={mission.img} />
                                </div>
                                <Mission isSmallScreen={isSmallScreen}>
                                    { mission.subject }
                                </Mission>
                            </Subject>
                            <P isSmallScreen={isSmallScreen} isMediumScreen={isMediumScreen}>
                                { mission.content[language] }
                            </P>
                        </Container>
                    ))
                }
                </div>
            </section>
        </Section>
    );

})

export default OurMission;


const Container = styled.div`
    width: ${({ isSmallScreen, isMediumScreen }) => 
        isSmallScreen ? '320px' : 
        isMediumScreen ? '515px' : '320px'};
    height: ${({ isSmallScreen, isMediumScreen }) => 
        isSmallScreen ? '100px' : 
        isMediumScreen ? '180px' : '250px'};
    background-color: ${({ theme }) => theme.colors.white};
    padding: ${({ isSmallScreen, isMediumScreen }) => 
        isSmallScreen ? '21px 12px 22px 12px' : 
        isMediumScreen ? '20px 30px' : '32px 30px 40px 30px' };
    box-shadow: 2px 4px 10px 0px rgba(0, 0, 0, 0.10);

    border-radius: 20px;

    display: flex;
    flex-direction: ${({ isSmallScreen, isMediumScreen }) => isSmallScreen || isMediumScreen ? 'row' : 'column'};
    justify-content: center;
    align-items: center;
    gap: ${({ isSmallScreen, isMediumScreen }) => isSmallScreen ? '15px' : isMediumScreen ? '40px' : '8px'};
`;

const Subject = styled.div`
    min-width: ${({ isSmallScreen, isMediumScreen }) => isSmallScreen ? '61px' : isMediumScreen && '120px'};
    height: ${({ isSmallScreen }) => isSmallScreen && '57px'};

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: ${({ isSmallScreen, isMediumScreen }) => isSmallScreen || isMediumScreen ? '9px' : '18px'};
`;

const Mission = styled.h1`
    font-size: ${({ isSmallScreen }) => isSmallScreen ? '12px' : '24px'};
    font-weight: 600;
`;

const P = styled.p`
    width: ${({ isSmallScreen }) => isSmallScreen ? '220px' : '280px'};
    font-size: ${({ isSmallScreen }) => isSmallScreen ? '14px' : '16px'};
    text-align: ${({ isSmallScreen, isMediumScreen }) => isSmallScreen || isMediumScreen ? 'left' : 'center'};
    line-height: 160%;
    letter-spacing: ${({ isSmallScreen }) => isSmallScreen ? '-0.28px' : '-0.24px'};
    white-space: pre-line;
`;