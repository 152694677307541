import styled, { keyframes, css } from "styled-components";


export const Section = styled.section`
    display: flex;
    justify-content: center;
    align-items: center;

    position: relative;
    height: ${({ height }) => height};
`;


export const refloat = keyframes`
    0% {
        transform: translate3d(0px, 50px, 0px);
    }
    100% {
        transform: translate3d(0px, 0px, 0px);
    }
`;


export const visibilityStyles = css`
    opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
    // visibility: ${({ isVisible }) => (isVisible ? 'visible' : 'hidden')};
    transition: all 0.7s ease;
`;

export const RefloatAnimation = styled.div`
    ${visibilityStyles};
    animation: ${({ isVisible }) => (isVisible ? refloat : 'none')} 0.7s ease-in-out forwards;
`;

export const OpacityAnimation = styled.div`
    ${visibilityStyles};
`;

export const refloatAnimation = css`
    ${visibilityStyles};
    animation: ${({ isVisible }) => (isVisible ? refloat : 'none')} 0.7s ease-in-out forwards;
`;

export const opacityAnimation = css`
    ${visibilityStyles};
`;