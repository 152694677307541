import { useRef } from "react";
import { History, InfoMainBg, Members, OurMission, PressRelease } from "../components/infoPage";
import Culture from "../components/infoPage/Culture";
import { useLanguage } from "../context/LanguageContext";


const InfoPage = ({ height, headerHeight }) => {

    const ourMissionRef = useRef(null);
    const { language } = useLanguage();

    return (
        <div style={{marginTop : headerHeight}}>
            <InfoMainBg />
            <OurMission height={height} ref={ourMissionRef} />
            <PressRelease />
            { language === 'kor' && <History /> }
            <Members />
            <Culture />
        </div>
    );

}

export default InfoPage;


