import { useState } from 'react';
import styled from 'styled-components';


const Chips = ({ chipsData, width, bgColor, color, fontSize, top, isSmallScreen, rowGap, columnGap, padding }) => {

    const chips = chipsData;

    
    return (
        <ChipsContainer width={width} top={top} isSmallScreen={isSmallScreen} rowGap={rowGap} columnGap={columnGap}>
            {
                chips.map((chip, i) => (
                    <Chip 
                        bgColor={bgColor} 
                        color={color} 
                        fontSize={fontSize} 
                        isSmallScreen={isSmallScreen} 
                        padding={padding} 
                        key={i}
                    >
                        { chip }
                    </Chip>
                ))
            }
        </ChipsContainer>
    );

}

export default Chips;


const ChipsContainer = styled.div`
    width: ${({ width }) => width };
    margin: 0 auto;

    display: flex;
    justify-content: center;
    align-items: center;
    gap: ${({ isSmallScreen, gap }) => gap ? gap : isSmallScreen ? '4px' : '14px'};
    row-gap: ${({ rowGap }) => rowGap};
    column-gap: ${({ columnGap }) => columnGap};
    flex-wrap: wrap;
    
    position: relative;
    top: ${({ top }) => top };
`

const Chip = styled.span`
    background-color: ${( {bgColor }) => bgColor};
    padding: ${({ isSmallScreen, padding }) => padding ? padding : isSmallScreen ? '3.59px 5.38px' : '8px 12px'};

    font-size: ${({ fontSize }) => fontSize};
    color: ${({ color }) => color};

    border-radius: ${({ isSmallScreen }) => isSmallScreen ? '9px' : '20px'};
`;