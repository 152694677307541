import styled from "styled-components";
import subjectImg from '../../img/solution_subject.svg';
import qnaImg1 from '../../img/solution_qna_1.svg';
import qnaImg2 from '../../img/solution_qna_2.svg';
import resultImg1 from '../../img/solution_result_1.svg';
import resultImg2 from '../../img/solution_result_2.svg';
import serviceImg from '../../img/solution_service.svg';
import eventImg from '../../img/solution_event.svg';
import infoImg from '../../img/solution_info.svg';
import { solutionLandingText } from "../../datas/data";
import { useLanguage } from "../../context/LanguageContext";


const ExtractSmallData = ({ isSmallScreen }) => {

    const { language } = useLanguage();
    const currentText = solutionLandingText[language];


    return (
        <Section isSmallScreen={isSmallScreen}>

            <Container isSmallScreen={isSmallScreen}>
                <div>
                    <img 
                        src={subjectImg} 
                        className={`absolute block ${isSmallScreen ? 'w-83' : 'w-205'}`} 
                        style={{
                            top : isSmallScreen ? '18px' : '44px',
                            left : isSmallScreen ? '28px' : '89px'
                        }}
                    />
                </div>
                <Content 
                    title={isSmallScreen ? currentText.subject.title.m : currentText.subject.title.pc}
                    subCopy={isSmallScreen ? currentText.subject.subCopy.m : currentText.subject.subCopy.pc}
                    top={`${isSmallScreen ? '36.5px' : '138px'}`}
                    isSmallScreen={isSmallScreen}
                />
            </Container>

            <Container isSmallScreen={isSmallScreen}>
                <div>
                    <img 
                        src={qnaImg1} 
                        className={`absolute block ${isSmallScreen ? 'w-74 h-89' : 'w-180 h-215'}`}
                        style={{
                            top : isSmallScreen ? '14.5px' : '35px', 
                            left : isSmallScreen ? '11px' : '27px'
                        }}
                    />
                    <img 
                        src={qnaImg2} 
                        className={`absolute block ${isSmallScreen ? 'w-74 h-33' : 'w-180 h-80'}`}
                        style={{
                            top : isSmallScreen ? '79px' : '192px', 
                            left : isSmallScreen ? '51px' : '154px'
                        }}
                    />
                </div>
                <Content 
                    title={isSmallScreen ? currentText.qna.title.m : currentText.qna.title.pc}
                    subCopy={isSmallScreen ? currentText.qna.subCopy.m : currentText.qna.subCopy.pc}
                    top={`${isSmallScreen ? '37px' : '135px'}`}
                    isSmallScreen={isSmallScreen}
                />
            </Container>

            <Container isSmallScreen={isSmallScreen}>
                <div>
                    <img 
                        src={resultImg1} 
                        className={`absolute block ${isSmallScreen ? 'w-64 h-87' : 'w-180 h-210'}`}
                        style={{
                            top : isSmallScreen ? '16px' : '35px', 
                            left : isSmallScreen ? '9px' : '27px',
                            zIndex : '5'
                        }}
                    />
                    <img 
                        src={resultImg2} 
                        className={`absolute block ${isSmallScreen ? 'w-64 h-87' : 'w-160 h-220'}`}
                        style={{
                            top : isSmallScreen ? '26px' : '68px', 
                            left : isSmallScreen ? '60px' : '166px'
                        }} 
                    />
                </div>
                <Content 
                    title={isSmallScreen ? currentText.result.title.m : currentText.result.title.pc}
                    subCopy={isSmallScreen ? currentText.result.subCopy.m : currentText.result.subCopy.pc}
                    top={`${isSmallScreen ? '37px' : '135px'}`}
                    isSmallScreen={isSmallScreen}
                />
            </Container>

            <Container isSmallScreen={isSmallScreen}>
                <div>
                    <img 
                        src={serviceImg} 
                        className={`absolute block ${isSmallScreen ? 'w-92' : 'w-202 h-277'}`}
                        style={{
                            top : isSmallScreen ? '17px' : '41px', 
                            left : isSmallScreen ? '23px' : '89px'
                        }}
                    />
                </div>
                <Content 
                    title={isSmallScreen ? currentText.service.title.m : currentText.service.title.pc}
                    subCopy={isSmallScreen ? currentText.service.subCopy.m : currentText.service.subCopy.pc}
                    top={`${isSmallScreen ? '37px' : '119px'}`}
                    isSmallScreen={isSmallScreen}
                />
            </Container>

            <Container isSmallScreen={isSmallScreen}>
                <div>
                    <img 
                        src={eventImg} 
                        className={`absolute block ${isSmallScreen ? 'w-90 h-116' : 'w-202 h-284'}`}
                        style={{
                            top : isSmallScreen ? '14px' : '34px', 
                            left : isSmallScreen ? '25.5px' : '89px'
                        }}
                    />
                </div>
                <Content 
                    title={isSmallScreen ? currentText.event.title.m : currentText.event.title.pc}
                    subCopy={isSmallScreen ? currentText.event.subCopy.m : currentText.event.subCopy.pc}
                    top={`${isSmallScreen ? '29px' : '119px'}`}
                    isSmallScreen={isSmallScreen}
                />
            </Container>
            
            <Container isSmallScreen={isSmallScreen}>
                <div>
                    <img 
                        src={infoImg} 
                        className={`absolute block ${isSmallScreen ? 'w-74 h-129' : 'w-180 h-299'}`}
                        style={{
                            top : isSmallScreen ? '1px' : '9px', 
                            left : isSmallScreen ? '33px' : '100px'
                        }}
                    />
                </div>
                <Content 
                    title={isSmallScreen ? currentText.info.title.m : currentText.info.title.pc}
                    subCopy={isSmallScreen ? currentText.info.subCopy.m : currentText.info.subCopy.pc}
                    top={`${isSmallScreen ? '44.5px' : '119px'}`}
                    isSmallScreen={isSmallScreen}
                />
            </Container>
            
        </Section>
    );

}

export default ExtractSmallData;


const Section = styled.section`
    width: 100%;
    margin-bottom: ${({ isSmallScreen }) => isSmallScreen ? '50px' : '100px' };

    display: flex;
    flex-direction: column;
    gap: ${({ isSmallScreen }) => isSmallScreen ? '12px' : '32px' };
`;

const Container = styled.div`
    width: 100%;
    height: ${({ isSmallScreen }) => isSmallScreen ? '130px' : '318px' };
    background-color: #E6F0FF;
    
    border-radius: ${({ isSmallScreen }) => isSmallScreen ? '16px' : '40px' };
    overflow: hidden;

    position: relative;
`;

const Content = ({ title, subCopy, top, isVisible, isSmallScreen }) => {

    return (
        <ContentContainer top={top} isVisible={isVisible} isSmallScreen={isSmallScreen}>
            <Title 
                dangerouslySetInnerHTML={{ __html: title }} 
                isSmallScreen={isSmallScreen}
            />
            <SubCopy 
                dangerouslySetInnerHTML={{ __html: subCopy }} 
                isSmallScreen={isSmallScreen}
            />
        </ContentContainer>
    );

}

const ContentContainer = styled.div`
    width: ${({ isSmallScreen }) => isSmallScreen ? '165px' : '380px' };

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: ${({ isSmallScreen }) => isSmallScreen ? '4px' : '12px' };

    position: absolute;
    top: ${ ({top}) => top };
    left: ${({ isSmallScreen }) => isSmallScreen ? '139px' : '380px' };
`;

const Title = styled.h1`
    font-size: ${({ isSmallScreen }) => isSmallScreen ? '13px' : '24px' };
    font-weight: 600;
    color: #2F2F2F;
    lineHeight: 140%;
`;

const SubCopy = styled.p`
    font-size: ${({ isSmallScreen }) => isSmallScreen ? '10px' : '18px' };
    color: #2F2F2F;
    text-align: left;
    letter-spacing: -0.27px;
`;