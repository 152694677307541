import { useLanguage } from "../../context/LanguageContext";
import useScreenSize from "../../hooks/useScreenSize";
import { theme } from "../../utils/palette";
import Content from "../common/Content";
import InquiryForm from "./InquiryForm";


const Inquiry = () => {

    const { isSmallScreen, isMediumScreen } = useScreenSize();
    const { language } = useLanguage();

    const title = {
        pc : language==='kor' ? 
            `아래 정보로 문의해주시면 24시간 내로 연락드립니다.` : 
            `We'll respond within 24 hours after receiving your information.`,
        m : language==='kor' ? 
            `아래 정보로 문의해주시면<br/>
            24시간 내로 연락드립니다.` :
            `We'll respond within 24 hours<br/>
            after receiving your information.`
    }


    return (
        <section className={`${isSmallScreen ? 'pt-48' : 'pt-80'}`}>
            <Content 
                title={isSmallScreen ? title.m : title.pc}
                color={theme.colors.gray4}
                isSmallScreen={isSmallScreen}
            />
            <InquiryForm isSmallScreen={isSmallScreen} isMediumScreen={isMediumScreen} />
        </section>
    );

}

export default Inquiry;