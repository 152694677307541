import styled from 'styled-components';
import { Section } from '../../styled/common.js';
import { mainPageText } from '../../datas/data.js';
import React from 'react';
import useScreenSize from '../../hooks/useScreenSize.js';
import { useLanguage } from '../../context/LanguageContext.js';


const SmallData = React.forwardRef(({ height }, ref) => {

    const { isSmallScreen, isMediumScreen } = useScreenSize();

    const { language } = useLanguage();
    const currentText = mainPageText[language];
    const title = isSmallScreen ? currentText.smData.title.m : currentText.smData.title.pc;
    const subCopy = isSmallScreen? currentText.smData.subCopy.m : currentText.smData.subCopy.pc;
    const contents = currentText.smData.contents;

    const sectionHeight = isSmallScreen ? height : '600px';

    
    return (
        <Section className="bg-white" height={sectionHeight} ref={ref}>
            <section 
                className={`flex flex-col mx-auto 
                    ${isSmallScreen ? 'w-320 gap-y-24' : 
                    isMediumScreen ? 'w-745 gap-y-48' : 
                    'w-780 gap-y-80'}`}
            >
                <div 
                    className={`flex ${isSmallScreen ? 'flex-col' : 'flex-row'} gap-x-20 gap-y-8`} 
                >
                    <H1 
                        isSmallScreen={isSmallScreen}
                        dangerouslySetInnerHTML={{ __html: title }} 
                    />
                    <P 
                        isSmallScreen={isSmallScreen}
                        dangerouslySetInnerHTML={{ __html: subCopy }}
                    />
                </div>
                <div className={`flex flex-wrap ${isSmallScreen ? 'gap-8' : 'justify-between'}`}>
                    {
                        contents.map((content, i) => (
                            <Container isSmallScreen={isSmallScreen} key={i}>
                                <ImageBox>
                                    <img src={content.img}/>
                                </ImageBox>
                                <TextBox className="text-center" isSmallScreen={isSmallScreen}>
                                    <span>{content.text1}</span><br/>
                                    <span className="font-semibold">{content.text2}</span>
                                </TextBox>
                            </Container>
                        ))
                    }
                </div>
            </section>
        </Section>
    );

})

export default SmallData;


const H1 = styled.h1`
    width: ${ ({isSmallScreen}) => isSmallScreen ? '320px' : '180px' };

    font-size: ${ ({isSmallScreen}) => isSmallScreen ? '20px' : '24px' };
    font-weight: 600;
    color: ${ ({theme}) => theme.colors.gray3};
`;

const P = styled.p`
    width: ${ ({isSmallScreen}) => isSmallScreen ? '320px' : '580px' };

    font-size: ${ ({isSmallScreen}) => isSmallScreen ? '14px' : '18px' };
    color: ${ ({theme}) => theme.colors.gray3};
    letter-spacing: ${ ({isSmallScreen}) => isSmallScreen ? '-0.27px' : '-0.21px' };
`;

const Container = styled.div`
    width: ${ ({isSmallScreen}) => isSmallScreen ? '156px' : '180px' };
    height: ${ ({isSmallScreen}) => isSmallScreen ? '156px' : '170px' };
    background-color: #E6F0FF;
    padding: 20px 12px;

    border-radius: 20px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

const ImageBox = styled.div`
    width: 72px;
    height: 72px;
    margin-bottom: 16px;

    display: flex;
    justify-content: center;
    align-items: center;
`;

const TextBox = styled.p`
    width: ${ ({isSmallScreen}) => isSmallScreen ? '135px' : '156px' };

    font-size: ${ ({isSmallScreen}) => isSmallScreen ? '14px' : '16px' };
    color: #2F2F2F;
    text-align: center;
    line-height: 160%;
    letter-spacing: -0.24px;
`;