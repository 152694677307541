import { useState } from "react";
import Required from "./Required";
import TextInput from "./TextInput";
import { useLanguage } from "../../context/LanguageContext";


const TextInputSection = ({ formData, handleChange, isSmallScreen, formErrors }) => {

    const { language } = useLanguage();
    const information = language==='kor' ? '정보' : 'Information';
    const organizationName = language==='kor' ? '기업/기관명' : 'Organization';
    const name = language==='kor' ? '성명' : 'Name';
    const email = language==='kor' ? '이메일' : 'Email';
    const phoneNumber = language==='kor' ? '연락처' : 'Phone';
    const placeholder = language==='kor' ? "- 없이 입력해주세요. (e.g., 01012345678)" : "Enter without '-' (e.g., 01012345678)";


    return (
        <div className={`flex ${isSmallScreen ? 'flex-col gap-y-8 mt-24' : 'flex-row justify-between mt-40'}`}>
            <div>
                <span className={`${isSmallScreen ? 'text-16 font-bold' : 'text-20 font-semibold'} text-gray4`}>
                    { information }
                </span>
                <Required>*</Required>
            </div>
            <div className={`flex flex-col ${isSmallScreen ? 'gap-y-8' : 'gap-y-12'}`}>
                <TextInput
                    label={organizationName}
                    type="text"
                    name="organizationName"
                    value={formData.organizationName}
                    handleChange={handleChange}
                    isSmallScreen={isSmallScreen}
                    hasError={formErrors.organizationName}
                />
                <TextInput
                    label={name}
                    type="text"
                    name="name"
                    value={formData.name}
                    handleChange={handleChange}
                    isSmallScreen={isSmallScreen}
                    hasError={formErrors.name}
                />
                <TextInput
                    label={email}
                    type="email"
                    name="email"
                    value={formData.email}
                    handleChange={handleChange}
                    isSmallScreen={isSmallScreen}
                    hasError={formErrors.email}
                />
                <TextInput
                    label={phoneNumber}
                    type="text"
                    name="phoneNumber"
                    value={formData.phoneNumber}
                    handleChange={handleChange}
                    isSmallScreen={isSmallScreen}
                    hasError={formErrors.phoneNumber}
                    placeholder={placeholder}
                />
            </div>
        </div>
    );
    
}

export default TextInputSection;