import styled from "styled-components";
import { useLanguage } from "../../../context/LanguageContext";


const ModalTitleSection = ({ isSmallScreen, selectedCase }) => {

    const { language } = useLanguage();
    const organizationName = language==='kor' ? '기업명: ' : 'Organization: ';
    const projectName = language==='kor' ? '프로젝트: ' : 'Project: ';
    const implementationDate = language==='kor' ? '시스템 도입 일시' : 'System Implementation Date: ';
    const { logo, logoSizeLarge, logoSizeSmall, title, company, project, period } = selectedCase;
    const logoWidth = isSmallScreen ? logoSizeSmall.width : logoSizeLarge.width;
    const logoHeight = isSmallScreen ? logoSizeSmall.height : logoSizeLarge.height;


    return (
        <Section isSmallScreen={isSmallScreen}>
            <Logo width={logoWidth} height={logoHeight}>
                <img src={logo} className="block w-full h-full" />
            </Logo>
            <Title isSmallScreen={isSmallScreen}>
                {title}
            </Title>
            <Info isSmallScreen={isSmallScreen}>
                <div>{ organizationName }{ company }</div>
                <div>{ projectName }{project}</div>
                <div>{ implementationDate }{period}</div>
            </Info>
        </Section>
    );

}

export default ModalTitleSection;


const Section = styled.section`
    margin: ${({ isSmallScreen }) => isSmallScreen ? '11px 20px 0 20px' : '108px 8% 0 8%'};
    margin: ${({ isSmallScreen }) => isSmallScreen ? '19px 20px 0 20px' : '108px 8% 0 8%'};
`;

const Logo = styled.div`
    width: ${({ width }) => width };
    height: ${({ height }) => height };

    margin-bottom: ${({ isSmallScreen }) => isSmallScreen ? '11px' : '0'};
`;

const Title = styled.h1`
    font-size: ${({ isSmallScreen }) => isSmallScreen ? '20px' : '40px'};
    font-weight: ${({ isSmallScreen }) => isSmallScreen ? '500' : '700'};
    color: ${({ theme }) => theme.colors.gray3};

    margin-bottom: ${({ isSmallScreen }) => isSmallScreen ? '15px' : '20px'};
`;

const Info = styled.div`
    font-size: ${({ isSmallScreen }) => isSmallScreen ? '13px' : '15px'};
    color: ${({ theme }) => theme.colors.gray5};
    margin-bottom: ${({ isSmallScreen }) => isSmallScreen ? '32px' : '20px'};
`;