import { useState } from "react";
import useScreenSize from "../../hooks/useScreenSize";
import { theme } from "../../utils/palette";
import Content from "../common/Content";
import { membersData } from "../../datas/membersData";
import MemberCard from "./MemberCard";
import styled from "styled-components";
import { useLanguage } from "../../context/LanguageContext";


const Members = () => {

    const { isSmallScreen } = useScreenSize();
    const { language } = useLanguage();
    const memebers = membersData[language];
    const [ isActive, setIsActive ] = useState(false);

    const handleCardActive = (active) => {
        setIsActive(active);
    };


    return (
        <section className={`${isSmallScreen ? 'pt-80' : 'pt-200'}`}>
            <Content 
                title={'OUR TEAM'}
                color={theme.colors.gray4}
                isSmallScreen={isSmallScreen}
            />
            <ScrollableSection className="w-screen overflow-auto" isSmallScreen={isSmallScreen}>
                <MemberList isSmallScreen={isSmallScreen}>
                {
                    memebers.map((member, index) => 
                        <MemberCard 
                            member={member}
                            isSmallScreen={isSmallScreen}
                            onActive={handleCardActive}
                            language={language}
                            key={index}
                        />
                    )
                }
                </MemberList>
            </ScrollableSection>
        </section>
    );

}

export default Members;


const ScrollableSection = styled.div`
    &::-webkit-scrollbar {
        display: ${({ isSmallScreen }) => isSmallScreen && 'none'};
    }
`;

const MemberList = styled.section`
    width: ${({ isSmallScreen }) => isSmallScreen ? '1072px' : '3355px'};
    padding: ${({ isSmallScreen }) => isSmallScreen ? '0 20px' : '30px 50px'};

    display: flex;
    flex-wrap: wrap;
    column-gap: ${({ isSmallScreen }) => isSmallScreen ? '8px' : '28px'};
    row-gap: ${({ isSmallScreen }) => isSmallScreen ? '24px' : ''};
    overflow-y: hidden;
    overflow-x: auto;
`;