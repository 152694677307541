import { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import RadioInput from "./RadioInput";
import FileInput from "./FileInput";
import SubmitButton from "./SubmitButton";
import TextInputSection from "./TextInputSection";
import TextArea from "./TextArea";
import Modal from "./Modal";
import ToastMessage from "./ToastMessage";
import axios from "axios";
import { useLanguage } from "../../context/LanguageContext";

const InquiryForm = ({ isSmallScreen, isMediumScreen }) => {
  const { language } = useLanguage();

  // 전송할 폼 데이터 초기 설정
  const [formData, setFormData] = useState({
    sender: "support@saltmine.io",
    type: "inquiry",
    category: "서비스 도입",
    organizationName: "",
    name: "",
    email: "",
    phoneNumber: "",
    subject: "서비스 문의 드립니다.",
    content: "",
    attachments: [],
  });

  const [formErrors, setFormErrors] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [showToast, setShowToast] = useState(false);

  // resetFiles 함수를 저장할 ref
  const resetFilesRef = useRef(null);

  // 정규식
  const textRegex = /^[가-힣a-zA-Z0-9\s&-]+$/;
  const emailRegex =
    /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,3}$/i;
  const contactRegex = /^01([0|1|6|7|8|9])([0-9]{3,4})([0-9]{4})$/;

  // 토스트 메시지 3초 동안 보여졌다 사라짐
  useEffect(() => {
    if (showToast) {
      const timer = setTimeout(() => {
        setShowToast(false);
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [showToast]);

  // Input 입력값 바뀌는 것 감지하는 함수
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });

    if (isSubmitted) {
      const errors = { ...formErrors };

      if (name === "organizationName") {
        if (value && !textRegex.test(value)) {
          errors.organizationName = true;
        } else {
          delete errors.organizationName;
        }
      } else if (name === "name") {
        if (value && !textRegex.test(value)) {
          errors.name = true;
        } else {
          delete errors.name;
        }
      } else if (name === "email") {
        if (!emailRegex.test(value)) {
          errors.email = true;
        } else {
          delete errors.email;
        }
      } else if (name === "phoneNumber") {
        if (!contactRegex.test(value)) {
          errors.phoneNumber = true;
        } else {
          delete errors.phoneNumber;
        }
      } else if (name === "content") {
        if (!value) {
          errors.content = true;
        } else {
          delete errors.content;
        }
      }

      setFormErrors(errors);
    }
  };

  // 파일 추가하는 함수 (이전 파일 유지)
  const handleAddFile = (files) => {
    setFormData((prevData) => ({
      ...prevData,
      attachments: [...prevData.attachments, ...files],
    }));
  };

  // 파일 삭제하는 함수 (해당하는 파일만)
  const handleDeleteFile = (index) => {
    setFormData((prevData) => ({
      ...prevData,
      attachments: prevData.attachments.filter((_, i) => i !== index),
    }));
  };

  // 객체를 FormData로 변환하는 함수
  const objectToFormData = (object) => {
    const formData = new FormData();

    for (let key in object) {
      // attachments 필드에 배열이 포함된 경우 배열의 각 파일을 append
      if (Array.isArray(object[key]) && key === "attachments") {
        object[key].forEach((file) => {
          formData.append(key, file); // 파일을 attachments[] 형태로 추가
        });
      } else {
        formData.append(key, object[key]);
      }
    }

    return formData;
  };

  // questToken이 있을 경우에만 메일을 form-data 형식으로 post하는 함수
  const handlePost = async (formData) => {
    const guestToken = localStorage.getItem("guestToken");
    if (!guestToken) return;

    const baseUrl = process.env.REACT_APP_BASE_URL;
    const url = `${baseUrl}/service/support/emails/create`;
    const body = objectToFormData(formData);
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        "x-app-id": process.env.REACT_APP_ID,
        "x-app-api-key": process.env.REACT_APP_API_KEY,
      },
    };

    try {
      await axios.post(url, body, config);
      console.log("Form data sent successfully");
    } catch (error) {
      console.error("Error sending form data:", error);
    }
  };

  // 폼데이터 제출 함수
  const handleSubmit = (e) => {
    e.preventDefault();
    setIsSubmitted(true);

    const errors = {};
    let toastMessage = "";

    if (
      !formData.organizationName ||
      !textRegex.test(formData.organizationName)
    ) {
      errors.organizationName = true;
      toastMessage = !formData.organizationName
        ? language === "kor"
          ? "기업/기관명을 입력해주세요."
          : "Please enter the organization name."
        : language === "kor"
        ? "기업/기관명 형식이 잘못되었습니다."
        : "The organization name format is incorrect.";
    } else if (!formData.name || !textRegex.test(formData.name)) {
      errors.name = true;
      toastMessage = !formData.name
        ? language === "kor"
          ? "성명을 입력해주세요."
          : "Please enter your name."
        : language === "kor"
        ? "성명 형식이 잘못되었습니다."
        : "The name format is incorrect.";
    } else if (!formData.email || !emailRegex.test(formData.email)) {
      errors.email = true;
      toastMessage = !formData.email
        ? language === "kor"
          ? "이메일을 입력해주세요."
          : "Please enter your email."
        : language === "kor"
        ? "이메일 형식이 잘못되었습니다."
        : "The email format is incorrect.";
    } else if (
      !formData.phoneNumber ||
      !contactRegex.test(formData.phoneNumber)
    ) {
      errors.phoneNumber = true;
      toastMessage = !formData.phoneNumber
        ? language === "kor"
          ? "연락처를 입력해주세요."
          : "Please enter your contact number."
        : language === "kor"
        ? "연락처 형식이 잘못되었습니다."
        : "The contact number format is incorrect.";
    } else if (!formData.content) {
      errors.content = true;
      toastMessage =
        language === "kor"
          ? "문의 내용을 입력해주세요."
          : "Please enter the inquiry content.";
    }

    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
      setToastMessage(toastMessage);
      setShowToast(true);
    } else {
      setFormErrors({});
      setIsModalOpen(true);
      handlePost(formData);
    }
  };

  const handleModalClose = (e) => {
    e.preventDefault();

    setIsModalOpen(false);
    setFormData({
      sender: "support@saltmine.io",
      type: "inquiry",
      category: "서비스 도입",
      organizationName: "",
      name: "",
      email: "",
      phoneNumber: "",
      subject: "서비스 문의 드립니다.",
      content: "",
      attachments: [],
    });

    // 파일 초기화 호출
    if (resetFilesRef.current) {
      resetFilesRef.current();
    }
    setIsSubmitted(false);
  };

  return (
    <Form isSmallScreen={isSmallScreen} isMediumScreen={isMediumScreen}>
      <Container isSmallScreen={isSmallScreen} isMediumScreen={isMediumScreen}>
        <RadioInput
          formData={formData}
          handleChange={handleChange}
          isSmallScreen={isSmallScreen}
        />
        <TextInputSection
          formData={formData}
          handleChange={handleChange}
          isSmallScreen={isSmallScreen}
          formErrors={formErrors}
          isSubmitted={isSubmitted}
        />
        <TextArea
          label={language === "kor" ? "문의 내용" : "Details"}
          type="textarea"
          name="content"
          placeholder={
            language === "kor"
              ? "문의 내용을 입력해주세요."
              : "Please enter your inquiry."
          }
          value={formData.content}
          handleChange={handleChange}
          isSmallScreen={isSmallScreen}
          hasError={isSubmitted && formErrors.content}
        />
        <FileInput
          handleAddFile={handleAddFile}
          handleDeleteFile={handleDeleteFile}
          isSmallScreen={isSmallScreen}
          resetFilesRef={resetFilesRef}
        />
        <SubmitButton
          handleSubmit={handleSubmit}
          isSmallScreen={isSmallScreen}
        />
        {showToast && (
          <ToastMessage message={toastMessage} isSmallScreen={isSmallScreen} />
        )}
      </Container>

      <Modal
        onClose={handleModalClose}
        isSmallScreen={isSmallScreen}
        isModalOpen={isModalOpen}
        message="submit"
      />
    </Form>
  );
};

export default InquiryForm;

const Form = styled.form`
  width: ${({ isSmallScreen, isMediumScreen }) =>
    isSmallScreen || isMediumScreen ? "100%" : "976px"};
  height: 1100px;
  background-color: ${({ theme }) => theme.colors.gray1};
  padding: 56px 0;
  margin: 0 auto;
  border-radius: ${({ isSmallScreen }) => (isSmallScreen ? "" : "20px")};
`;

const Container = styled.div`
  width: ${({ isSmallScreen, isMediumScreen }) =>
    isSmallScreen ? "320px" : isMediumScreen ? "92%" : "auto"};
  margin: ${({ isSmallScreen, isMediumScreen }) =>
    isSmallScreen ? "0 auto" : isMediumScreen ? "0 auto" : "0 98px"};

  display: flex;
  flex-direction: column;
`;
