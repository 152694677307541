import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useLanguage } from '../../context/LanguageContext';
import useScreenSize from '../../hooks/useScreenSize';


const LanguageSwitcher = ({ className, handleMenuClose }) => {

    const { dispatch } = useLanguage();
    const { isSmallScreen, isMediumScreen } = useScreenSize();
    const [ isActive, setIsActive ] = useState(
        localStorage.getItem('language')
    );

    const handleSetKorean = () => {
        dispatch({ type: 'SET_LANGUAGE', payload: 'kor' });

        const url = new URL(window.location.href);
        url.searchParams.set('language', 'kr');
        window.history.pushState(null, '', url.toString());

        setIsActive('kor');
        
        if (isSmallScreen || isMediumScreen) handleMenuClose();
    };

    const handleSetEnglish = () => {
        dispatch({ type: 'SET_LANGUAGE', payload: 'eng' });

        const url = new URL(window.location.href);
        url.searchParams.set('language', 'en');
        window.history.pushState(null, '', url.toString());

        setIsActive('eng');
        
        if (isSmallScreen || isMediumScreen) handleMenuClose();
    };

    
    return (
        <LanguageContainer className={className} isSmallScreen={isSmallScreen}>
            <Kor 
                isSmallScreen={isSmallScreen}
                isMediumScreen={isMediumScreen} 
                onClick={handleSetKorean}
                isActive={isActive}
            >
                KOR
            </Kor>
            <Eng 
                isSmallScreen={isSmallScreen} 
                isMediumScreen={isMediumScreen} 
                onClick={handleSetEnglish}
                isActive={isActive}
            >
                ENG
            </Eng>
        </LanguageContainer>
    );
};

export default LanguageSwitcher;


const LanguageContainer = styled.div`
    display: flex;
    gap: ${({ isSmallScreen }) => isSmallScreen ? '6px' : '8px'};
`;

const Kor = styled.button`
    width: ${({ isSmallScreen, isMediumScreen }) => isSmallScreen || isMediumScreen ? '56px' : '80px'};
    height: ${({ isSmallScreen, isMediumScreen }) => isSmallScreen || isMediumScreen ? '28px' : '36px'};
    background-color: ${({ theme }) => theme.colors.white};
    border: ${({ theme, isActive }) => isActive==='eng' ? `1px solid ${theme.colors.gray9}` : `1px solid ${theme.colors.main1}`};
    border-radius: 8px;
    font-size: ${({ isSmallScreen, isMediumScreen }) => isSmallScreen || isMediumScreen ? '14px' : '16px'};
    line-height: 20px;
    color: ${({ theme }) => theme.colors.gray3};
    display: inline-flex;
    justify-content: center;
    align-items: center;
`;

const Eng = styled.button`
    width: ${({ isSmallScreen, isMediumScreen }) => isSmallScreen || isMediumScreen ? '56px' : '80px'};
    height: ${({ isSmallScreen, isMediumScreen }) => isSmallScreen || isMediumScreen ? '28px' : '36px'};
    background-color: ${({ theme }) => theme.colors.white};
    border: ${({ theme, isActive }) => isActive==='eng' ? `1px solid ${theme.colors.main1}` : `1px solid ${theme.colors.gray9}`};
    border-radius: 8px;
    font-size: ${({ isSmallScreen, isMediumScreen }) => isSmallScreen || isMediumScreen ? '14px' : '16px'};
    line-height: 20px;
    color: ${({ theme }) => theme.colors.gray3};
    display: inline-flex;
    justify-content: center;
    align-items: center;
`;
