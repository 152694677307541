import styled from "styled-components";
import Required from "./Required";
import { useLanguage } from "../../context/LanguageContext";


const RadioInput = ({ formData, handleChange, isSmallScreen }) => {

    const { language } = useLanguage();
    const inquiryType = language==='kor' ? '문의 유형' : 'Inquiry Type';
    const serviceDeployment = language==='kor' ? '서비스 도입' : 'Service Deployment';
    const proposals = language==='kor' ? '제휴 및 제안' : 'Partnerships & Proposals';
    const other = language==='kor' ? '기타' : 'Other';


    return (
        <div className={`flex ${isSmallScreen ? 'flex-col gap-y-8' : 'flex-row justify-between'}`}>
            <div>
                <span className={`${isSmallScreen ? 'text-16 font-bold' : 'text-20 font-semibold'} text-gray3`}>
                    { inquiryType }
                </span>
                <Required>*</Required>
            </div>
            <div 
                className={`
                    flex 
                    ${isSmallScreen && language==='eng' ? 'flex-col' : 'flex-row'}
                    ${isSmallScreen ? 'gap-x-16' : 'gap-x-28 w-580'}`}
                >
                <Label isSmallScreen={isSmallScreen}>
                    <CustomRadio
                        type="radio"
                        name="category"
                        value="서비스 도입"
                        checked={formData.category === "서비스 도입"}
                        onChange={handleChange}
                        isSmallScreen={isSmallScreen}
                    />
                    { serviceDeployment }
                </Label>
                <Label isSmallScreen={isSmallScreen}>
                    <CustomRadio
                        type="radio"
                        name="category"
                        value="제휴 및 제안"
                        checked={formData.category === "제휴 및 제안"}
                        onChange={handleChange}
                        isSmallScreen={isSmallScreen}
                    />
                    { proposals }
                </Label>
                <Label isSmallScreen={isSmallScreen}>
                    <CustomRadio
                        type="radio"
                        name="category"
                        value="기타"
                        checked={formData.category === "기타"}
                        onChange={handleChange}
                        isSmallScreen={isSmallScreen}
                    />
                    { other }
                </Label>
            </div>
        </div>
    );

}

export default RadioInput;


const Label = styled.label`
    font-size: 16px;
    font-weight: 600;
    color: ${({ theme }) => theme.colors.gray3};
    line-height: 30px;

    display: inline-flex;
    align-items: center;
    gap: 8px;

    cursor: pointer;
`;

const CustomRadio = styled.input.attrs({ type: 'radio' })`
    appearance: none;
    width: ${({isSmallScreen }) => isSmallScreen ? '20px' : '22px'};
    height: ${({isSmallScreen }) => isSmallScreen ? '20px' : '22px'};
    border: 2px solid #7E7E7E;
    border-radius: 18px;
    cursor: pointer;

    &:checked {
        border: ${({ isSmallScreen, theme }) =>  isSmallScreen ? 
                `4px solid ${theme.colors.main1}` : 
                `6px solid ${theme.colors.main1}`};
`;