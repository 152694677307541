import styled from 'styled-components';
import useScreenSize from '../../hooks/useScreenSize';
import saltmine from '../../img/saltmine.svg';
import { useLanguage } from '../../context/LanguageContext';
import { footerText } from '../../datas/data';


const Footer = () => {

    const { language } = useLanguage();
    const footer = footerText[language];
    const { isSmallScreen, isMediumScreen } = useScreenSize();

    
    return (
        <FooterContainer isSmallScreen={isSmallScreen}>
            <FooterInner isSmallScreen={isSmallScreen} isMediumScreen={isMediumScreen} language={language}>
                <Info isSmallScreen={isSmallScreen} isMediumScreen={isMediumScreen}>
                    <ImgBox isSmallScreen={isSmallScreen} isMediumScreen={isMediumScreen}>
                        <img src={saltmine} alt="소금광산" className="block w-full h-full" />
                    </ImgBox>
                    <Company isSmallScreen={isSmallScreen} isMediumScreen={isMediumScreen}>
                        <Name isSmallScreen={isSmallScreen}>
                            <b>{ footer.company }</b>
                        </Name>
                        <div className={`${isSmallScreen || isMediumScreen ? 
                            'flex flex-row justify-center gap-x-9 mb-9' : 
                            'flex flex-col justify-start'}`}
                        >
                            <span>
                                <b>{ footer.ceo }</b>{ footer.name }
                            </span>
                            <span>
                                <b>{ footer.regNo.subject }</b>{ footer.regNo.number }
                            </span>
                        </div>
                    </Company>
                    <Address isSmallScreen={isSmallScreen} isMediumScreen={isMediumScreen}>
                        {isSmallScreen || isMediumScreen ?
                            (<span>
                                <b>{ footer.headquarters.subject }</b>
                                { footer.headquarters.address[0] }<br/>
                                { footer.headquarters.address[1] }
                            </span>) :
                            (<span>
                                <b>{ footer.headquarters.subject }</b>
                                { footer.headquarters.address }
                            </span>)
                        }
                        {isSmallScreen || isMediumScreen ?
                            (<span>
                                <b>{ footer.lab.subject }</b>
                                { footer.lab.address[0] }<br/>
                                { footer.lab.address[1] }
                            </span>) :
                            (<span>
                                <b>{ footer.lab.subject }</b>{ footer.lab.address }
                            </span>)
                        }
                        <span>
                            <b>{ footer.contact.subject }</b>{ footer.contact.number }
                        </span>
                    </Address>
                </Info>
                <Copyright isSmallScreen={isSmallScreen} isMediumScreen={isMediumScreen} language={language}>
                    <span>Ⓒ 2023. SALTMiNE, Inc. all rights reserved.</span>
                </Copyright>
            </FooterInner>
        </FooterContainer>
    );

}

export default Footer;


const FooterContainer = styled.footer`
    width: 100%;
    margin-top: ${({ isSmallScreen }) => isSmallScreen ? '120px' : '200px'};
    padding: ${({ isSmallScreen }) => isSmallScreen ? '89px 0' : '97px 0'};

    border-top-width: 1px;
`;

const FooterInner = styled.section`
    width: ${({ isSmallScreen, isMediumScreen, language }) => 
        isSmallScreen || isMediumScreen ? 'auto' :
        language==='kor' ? '845px' : '980px'};
    margin: 0 auto;

    font-size: ${({ isSmallScreen }) => isSmallScreen ? '10.5px' : '14px'};
    text-align: ${({ isSmallScreen, isMediumScreen }) => isSmallScreen || isMediumScreen ? 'center' : 'left'};
    white-space: ${({ isSmallScreen }) => isSmallScreen ? 'wrap' : 'nowrap'};
`;

const Info = styled.div`
    display: flex;
    flex-direction: ${({ isSmallScreen, isMediumScreen }) => isSmallScreen || isMediumScreen  ? 'column' : 'row'};
    column-gap: 40px;
`;

const ImgBox = styled.div`
    width: ${({ isSmallScreen }) => isSmallScreen ? '97px' : '108px'};
    height: ${({ isSmallScreen }) => isSmallScreen ? '24px' : '35px'};
    
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: ${ ({isSmallScreen, isMediumScreen }) => isSmallScreen || isMediumScreen ? 'center' : 'flex-start'};
`;

const Company = styled.div`
    width: ${({ isSmallScreen, isMediumScreen }) => isSmallScreen || isMediumScreen ? 'auto' : '190px'};
    display: flex;
    flex-direction: column;

    margin-top: ${({ isSmallScreen, isMediumScreen }) => isSmallScreen || isMediumScreen ? '30px' : '0'};
    gap: ${({ isSmallScreen, isMediumScreen }) => isSmallScreen || isMediumScreen ? '15px' : '0'};
`;

const Name = styled.span`
    font-size: ${({ isSmallScreen }) => isSmallScreen ? '12px' : '14px'};
`;

const Address = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${({ isSmallScreen, isMediumScreen }) => isSmallScreen || isMediumScreen ? '8px' : '0'};
`;

const Copyright = styled.div`
    margin-top: 15px;
    margin-left: ${({ isSmallScreen, isMediumScreen, language }) => 
        isSmallScreen || isMediumScreen ? '0' : 
        language==='kor' ? '148px' : '130px'};
`;