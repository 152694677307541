import styled from "styled-components";
// import bgImage from '../../img/info_main_bg.webp';
import bgImage from '../../img/info_main_bg_copy.png';
import useScreenSize from "../../hooks/useScreenSize";
import { Section } from "../../styled/common";
import { infoPageText } from "../../datas/data";
import { useLanguage } from "../../context/LanguageContext";


const InfoMainBg = () => {

    const { isSmallScreen, isXLargeScreen } = useScreenSize();

    const { language } = useLanguage();
    const currentText = infoPageText[language];
    const title = isSmallScreen ? currentText.main.title.m : currentText.main.title.pc;
    const subCopy = isSmallScreen ? currentText.main.subCopy.m : currentText.main.subCopy.pc;

    const height = isSmallScreen ? '420px' : isXLargeScreen ? '750px' : '600px';


    return (
        <Section>
            <BgCrop height={height} isSmallScreen={isSmallScreen}>
                <img src={bgImage} className="block w-full h-full object-cover"/>
                <TextBox>
                    <H1 
                        isSmallScreen={isSmallScreen}
                        dangerouslySetInnerHTML={{ __html: title }} 
                    />
                    <P 
                        isSmallScreen={isSmallScreen}
                        dangerouslySetInnerHTML={{ __html: subCopy }} 
                    />
                </TextBox>
            </BgCrop>
        </Section>
    );

}

export default InfoMainBg;


const BgCrop = styled.div`
    width: 100%;
    height: ${({ height }) => height };

    display: flex;
    justify-content: center;
    
    position: relative;
    overflow: hidden;
`;

const TextBox = styled.div`
    color: #FFFFFF;
    text-align: center;
    white-space: nowrap;

    align-self: center;
    
    position: absolute;
`;

const H1 = styled.h1`
    font-size: ${ ({isSmallScreen}) => isSmallScreen ? '26px' : '40px' };
    font-weight: 700;
    line-height: 140%;

    margin-bottom: ${ ({isSmallScreen}) => isSmallScreen ? '8px' : '18px' };
`;

const P = styled.p`
    font-size: ${ ({isSmallScreen}) => isSmallScreen ? '16px' : '18px' };
    line-height: 140%;
    letter-spacing: -0.24px;
`;