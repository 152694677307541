import Content from "../common/Content.js";
import CustomerInsightTable from "./CustomerInsightTable.js";
import Chips from "../common/Chips.js";
import { Section } from "../../styled/common.js";
import { theme } from "../../utils/palette.js";
import { chipDataMain, mainPageText } from "../../datas/data.js";
import useScreenSize from "../../hooks/useScreenSize.js";
import { useLanguage } from "../../context/LanguageContext.js";


const CustomerInsight = ({ height }) => {

    const { isSmallScreen, isMediumScreen } = useScreenSize();

    const { language } = useLanguage();
    const currentText = mainPageText[language];
    const title = isSmallScreen ? currentText.insight.title.m :  currentText.insight.title.pc;
    const subCopy = isSmallScreen ? currentText.insight.subCopy.m : currentText.insight.subCopy.pc;

    const color = theme.colors.gray3;
    const sectionHeight = isSmallScreen ? height : '870px';

    const chipsWidth = isSmallScreen || isMediumScreen ? '100vw' : '980px';
    const chipsBgColor = theme.colors.main1;
    const chipsColor = theme.colors.white;
    const chipsFontSize = isSmallScreen ? '12px' : '14px';
    const top = isSmallScreen ? '-130px' : '-250px';


    return (
        <Section className="flex-col" height={sectionHeight}>
            <section 
                className={`
                    mx-auto z-10
                    ${isSmallScreen ? 'w-320 h-350' : 
                    isMediumScreen ? 'w-745 h-562' : 'w-780 h-562'}`}
            >
                <Content 
                    title={title}
                    subCopy={subCopy}
                    color={color}
                    isSmallScreen={isSmallScreen}
                />
                <CustomerInsightTable isSmallScreen={isSmallScreen} />
            </section>
            <Chips
                chipsData={chipDataMain}
                width={chipsWidth}
                bgColor={chipsBgColor}
                color={chipsColor}
                fontSize={chipsFontSize}
                top={top}
                isSmallScreen={isSmallScreen}
            />
        </Section>
    );

}

export default CustomerInsight;