import styled from "styled-components";

const TextInput = ({ label, type, name, value, handleChange, isSmallScreen, placeholder, hasError }) => {

    return (
        <div>
            <div>
                <Label>
                    <div className={`${isSmallScreen ? 'mb-4' : 'mb-8'}`}>
                        { label }
                    </div>
                    <Input
                        type={type}
                        name={name}
                        value={value}
                        onChange={handleChange}
                        isSmallScreen={isSmallScreen}
                        placeholder={placeholder}
                        hasError={hasError}
                    />
                </Label>
            </div>
        </div>
    );

}

export default TextInput;


const Label = styled.label`
    font-size: 16px;
    color: ${({ theme }) => theme.colors.gray3};
    line-height: 30px;
`;

const Input = styled.input`
    width: ${({ isSmallScreen }) => isSmallScreen ? '320px' : '580px'};
    height: 48px;
    background-color: ${({ theme }) => theme.colors.white};
    padding: 5px 16px;
    border: 1px solid ${({ theme, hasError }) => hasError ? 'red' : theme.colors.gray7};
    border-radius: 10px;

    font-size: ${({ isSmallScreen }) => isSmallScreen ? '16px' : '18px'};
    color: ${({ theme }) => theme.colors.gray3};

    outline: none;

    &:focus {
        border: 1px solid ${({ theme }) => theme.colors.gray7};
    }
`;